import React from "react";
import {Cloud, CloudFill} from "react-bootstrap-icons";
import {getIconPath} from "./CanopiaUtils";

/**
 * Shows an icon depending on the CO2 exposure relative to its benchmark.
 */
export default function ESGMetricIcon(props) {
    let value = props.value;
    let status = props.status;

    const iconPath = getIconPath(value, status);
    if (iconPath) {
        return <img style={{margin: '0 20px 0 8px'}} height='20px' width='20px' src={iconPath} alt={''}/>;
    }
    return <></>;
}
