import React, {useEffect, useState} from "react";
import {Card} from "react-bootstrap";
import {useSearchParams} from "react-router-dom";
import AuthService from "../../services/auth.service";
import ajaxloader from "../../images/ajax-loader.gif";
import {useNavigate} from 'react-router-dom';

export default function OAuth2Login() {

    const [validateToken, setValidateToken] = useState(true);
    const [errorName, setErrorName] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const token = searchParams.get("token");

    const navigate = useNavigate();

    useEffect(() => {

        const redirect = () => {
            // Forward to the portfolio after a successful login
            // TODO: Back to the root, should get the from location
            // this.props.history.replace(this.state.from);
            navigate("/portfolio");
        }

        if (validateToken) {
            AuthService.loginOAuth2(token)
                .then((response) => {
                        redirect();
                        // setValidateToken(false);
                    },
                    error => {
                        processError(error);
                    }
                );
        }
    }, [validateToken, token, navigate]);

    const processError = (error) => {
        let response = error.response;

        let errorName;
        let errorMessage;
        if (response
            && response.data
            && response.data.error
            && response.data.message
        ) {
            errorName = response.data.error;
            errorMessage = response.data.message;
        } else {
            errorName = 'Service unavailable';
            errorMessage = 'We apologize for the inconvenience, our team is working on solving the issue. Please try to come back in a few minutes.\nThank you for your patience.';
            if (error.message) {
                console.log(error.message);
            }
        }

        setValidateToken(false);
        setErrorName(errorName);
        setErrorMessage(errorMessage);
    }

    return (validateToken ? <div className="main-content">
                <Card style={{width: '500px', margin: 'auto'}}>
                    <Card.Body>
                        <Card.Title className='canopia'>Validating your request, please wait</Card.Title>
                        <div>
                            <img alt="loading" src={ajaxloader}/>
                        </div>
                    </Card.Body>
                </Card>
            </div>
            : (errorMessage &&
                <div className="main-content">
                    <Card style={{width: '500px', margin: 'auto'}}>
                        <Card.Body>
                            <Card.Title className='canopia'>{errorName}</Card.Title>
                            <div className="form-group">
                                <div className={"alert alert-danger"} role="alert">
                                    {errorMessage}
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>)
    );
}
