import React, {useEffect, useState} from "react";

import CreatableSelect from "react-select/creatable";
import {useDispatch, useSelector} from "react-redux";
import {clearAllGCFilters, filterGC, loadGC, selectSetupState} from "../../reducer/setupSlice";
import THSort from "../canopia/THSort";
import THFilter from "../canopia/THFilter";
import Table from "react-bootstrap/Table";
import FilterResult from "../canopia/FilterResult";
import {loadBM, selectBenchmarkState} from "../../reducer/benchmarkSlice";
import {IconContext} from "react-icons";
import {TiDeleteOutline} from "react-icons/ti";
import Button from "react-bootstrap/Button";
import {HiChevronDoubleDown, HiChevronDown, HiChevronRight} from "react-icons/hi";
import Select from "react-select";
import AdminService from "../../services/admin.service";
import {CO2, LEGENDS} from "../canopia/CanopiaUtils";
import {ArrowRightCircleFill, Tornado, Trash} from "react-bootstrap-icons";
import LabelWithTooltip from "../canopia/LabelWithTooltip";
import Modal from "react-bootstrap/Modal";

export default function ViewSetup(props) {

    const dispatch = useDispatch();

    // const clientConfig = props.clientConfig;
    const clientOption = props.clientOption;
    const dateOption = props.dateOption;

    const {
        clientConfig, // clientConfig holds the view categories
        tgtDataOrig, // original global custody
        tgtData, // global custody shown (filtered, sorted)
    } = useSelector(selectSetupState);
    const {benchmarks, bmStatus} = useSelector(selectBenchmarkState);

    // Loading data
    // const [loadStatus, setLoadStatus] = useState('idle');

    // Options for dropdowns
    let optionsTmp = createBmOptions(benchmarks);
    const [bmOptions, setBmOptions] = useState(optionsTmp);
    const [viewOptions, setViewOptions] = useState([]);
    const propertyOptions = [
        {value: 'accountManager', label: 'Account manager'}, //
        {value: 'broadCategory', label: 'Broad category'}, //
        {value: 'subCategory', label: 'Sub category'}, //
        {value: 'detailedCategory1', label: 'Detailed category 1'}, //
        {value: 'detailedCategory2', label: 'Detailed category 2'}, //
        {value: 'fundName', label: 'Fund'}];
    const [categoryOptions, setCategoryOptions] = useState([]);

    // Form
    const [selectedLevelProps, setSelectedLevelProps] = useState([propertyOptions[1]]);
    const [selectedPrefixReportProps, setSelectedPrefixReportProps] = useState([]);
    const [selectedView, setSelectedView] = useState(null);
    const [viewName, setViewName] = useState("");
    const [viewRank, setViewRank] = useState(1);
    const [currentViewCategories, setCurrentViewCategories] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState(null);
    // Levels (does not consider level 0 (Portfolio), start with 1)
    // 1: Account or Client Asset Class
    const [prefixReportWithViewName, setPrefixReportWithViewName] = useState(false);

    const [tmpViewConfigs, setTmpViewConfigs] = useState([]);

    // Save
    const [saving, setSaving] = useState(false);
    const [saveMessages, setSaveMessages] = useState(null);

    // const bmPosIdPrefix = "Pos_Id_"
    // const bmAccountPrefix = "Acc_Id_"
    const colorClass = 'canopia2';
    const targetSort = "gc";
    const pfBgClass = 'background-light';
    const directLines = ['Lignes Directes', 'Direct Lines'];

    // Load all the clients to populate the dropdown (done only once)
    useEffect(() => {
        dispatch(loadBM());
    }, [dispatch]);

    function createBmOptions(benchmarkList) {
        let optionsTmp = [];

        if (benchmarkList) {
            benchmarkList.forEach(bm => {
                const bmKey = bm.key;
                // Skip dead BMs...
                if (!bmKey.startsWith('DEAD_')) {
                    const bmName = bm.name;
                    const option = {value: bmKey, label: bmKey + " (" + bmName + ")"};
                    optionsTmp.push(option);
                }
            });

            optionsTmp.sort((a, b) => {
                return (a.label > b.label ? 1 : -1)
            });
        }
        return optionsTmp;
    }

    useEffect(() => {
        if (bmStatus !== 'success') {
            return;
        }
        let optionsTmp = createBmOptions(benchmarks);

        // setBmOptions(optionsTmp);
        // if (clientConfig) {
        // const clientConfigForView = getClientConfigForView();
        let viewOptions = [];
        clientConfig.clientViews.forEach(view => {
            if (view.viewId > 0) { // Skip Funds view
                viewOptions.push({value: view.viewId, label: view.name});
            }
        });
        setViewOptions(viewOptions);

        const initialView = viewOptions.length > 0 ? viewOptions[0] : null;
        setSelectedView(initialView);
        let levelProps;
        let viewCategories;
        if (initialView) {
            const viewId = initialView.value;
            const clientView = findView(viewId);
            setViewName(clientView.name);
            setViewRank(clientView.rank);
            setPrefixReportWithViewName(clientView.prefixReportWithViewName);
            levelProps = getPropertyOptions(clientView.categoryConfigs);
            setSelectedPrefixReportProps(getPrefixPropertyOptions(clientView.categoryConfigs));
            let allViewCategories = clientConfig.viewCategories;
            viewCategories = allViewCategories ? allViewCategories.filter(cat => cat.viewId === viewId) : [];
        } else {
            levelProps = selectedLevelProps;
            viewCategories = [];
        }
        setSelectedLevelProps(levelProps);
        // if (tgtDataOrig) {
            initViewCategories(initialView, levelProps, viewCategories, tgtDataOrig, optionsTmp);
        // }
        // }
    }, [clientConfig, tgtDataOrig, benchmarks, bmStatus]);

    function getPropertyOptions(categoryConfigs) {
        return categoryConfigs.map(cc => propertyOptions.find(po => po.value === cc.categoryProp));
    }

    function getPrefixPropertyOptions(categoryConfigs) {
        return categoryConfigs.map(cc => propertyOptions.find(po => cc.prefixCategoryProps ? po.value === cc.prefixCategoryProps[0] : null));
    }

    function reset() {
        setSaveMessages(null);
        setCurrentViewCategories(null);
        setViewOptions(null);
        setViewRank(1);
        setViewName("");
        setSelectedLevelProps([]);
        setSelectedPrefixReportProps([]);
        setPrefixReportWithViewName(false);
    }

    function dispatchLoadGC() {
        if (clientOption && dateOption) {
            dispatch(loadGC({clientId: clientOption.value, dateStr: dateOption.value}));
        }
    }

    const handleSaveSetup = (e) => {
        e.preventDefault();

        let categoryConfigs = [];
        let i = 0;
        selectedLevelProps.forEach(prop => {
            if (prop) {
                let prefixRepProp = selectedPrefixReportProps.length > i ? selectedPrefixReportProps[i] : null;
                const prefixRepPropValues = prefixRepProp ? [prefixRepProp.value] : [];

                categoryConfigs.push({
                    categoryProp: prop.value, prefixCategoryProps: prefixRepPropValues,
                });
            }
            i++;
        });

        setSaving(true);
        setSaveMessages(null);

        AdminService.saveSetup({
            clientId: clientOption.value,
            dateStr: dateOption.value,
            viewId: selectedView.value,
            viewName,
            viewRank,
            prefixReportWithViewName: prefixReportWithViewName ? 1 : 0,
            categoryConfigs: categoryConfigs,
            viewCategories: currentViewCategories
        }).then(response => {
            setSaving(false);
            const respData = response.data;
            if (respData) {
                const messages = respData.data;
                setSaveMessages(messages);
                if (!messages.some(message => message.key === 'error')) {
                    dispatchLoadGC();
                }
            } else {
                setSaveMessages(null);
            }

        }, error => {
            setSaving(false);
            // processError(error);
        });
    }

    function findCategory(tgtViewCategories, prop, value) {
        for (let i = 0; i < tgtViewCategories.length; i++) {
            const tgtViewCategory = tgtViewCategories[i];
            if (tgtViewCategory[prop] === value) {
                return {
                    cat: tgtViewCategory,
                    index: i
                };
            }
        }
        return null;
    }

    function findCategories(tgtViewCategories, prop, value) {
        let categories = [];
        for (let i = 0; i < tgtViewCategories.length; i++) {
            const tgtViewCategory = tgtViewCategories[i];
            if (tgtViewCategory[prop] === value) {
                categories.push({
                    cat: tgtViewCategory,
                    index: i
                });
            }
        }
        return categories;
    }

    function findCategoryFromParents(tgtViewCategories, prop, value, parents) {
        if (!parents || parents.length === 0) {
            // In case the only parent is the root one
            return findCategory(tgtViewCategories, prop, value);
        }

        let parentKeys = [];
        parents.forEach(p => {
            if (p.catIdx) {
                parentKeys.push(p.catIdx.cat.key);
            }
        });
        if (parentKeys.length < parents.length) {
            return null;
        }
        for (let i = 0; i < tgtViewCategories.length; i++) {
            const tgtViewCategory = tgtViewCategories[i];
            if (parentKeys.every(pKey => tgtViewCategory.key.startsWith(pKey + '#')) && tgtViewCategory[prop] === value) {
                return {
                    cat: tgtViewCategory, index: i
                };
            }
        }
        return null;
    }

    function findCategoryFromParent(tgtViewCategories, prop, value, parent) {
        if (!parent) {
            return findCategory(tgtViewCategories, prop, value);
        }

        let parentKey = null;
        if (parent.catIdx) {
            parentKey = parent.catIdx.cat.key;
        }
        for (let i = 0; i < tgtViewCategories.length; i++) {
            const tgtViewCategory = tgtViewCategories[i];
            if (parentKey && tgtViewCategory.key.startsWith(parentKey + '#') && tgtViewCategory[prop] === value) {
                return {
                    cat: tgtViewCategory, index: i
                };
            }
        }
        return null;
    }

    function findMaxCategoryId(viewCategories) {
        let max = 0;
        if (viewCategories && viewCategories.length > 0) {
            viewCategories.forEach(viewCat => max = Math.max(max, viewCat.categoryId));
        }
        return max;
    }

    function findMaxViewProp(prop) {
        let max = 0;
        const clientViews = clientConfig.clientViews;
        if (clientViews && clientViews.length > 0) {
            clientViews.forEach(viewOpt => max = Math.max(max, viewOpt[prop]));
        }
        return max;
    }

    function findView(id) {
        return clientConfig.clientViews.find(view => view.viewId === id);
    }

    function noChange(cat) {
        return !hasStatus(cat) || cat.status === 'No Change';
    }

    function noChangeAll(categories) {
        return categories.every(cat => cat && cat.catIdx && noChange(cat.catIdx.cat));
    }

    function hasStatus(cat) {
        return cat.hasOwnProperty('status');
    }

    function statusIs(cat, status) {
        return hasStatus(cat) && cat.status === status;
    }

    function getLevel(viewCat) {
        return viewCat.key.split('#').length - 1;
    }

    function refreshCurrentCategories(viewCategories) {
        let catOptions = [];

        // Add a category to the dropdown if it has children or if 'Removed'
        viewCategories.forEach(viewCat => {
            // if (getDescendants(viewCategories, viewCat.key).length > 0 || !hasStatus(viewCat)) {
            catOptions.push({value: viewCat.key, label: viewCat.category + ' (' + viewCat.key + ')'});
            // }
        });
        setCurrentViewCategories(viewCategories);
        setCategoryOptions(catOptions);
    }

    function removeCat(cat) {
        let tmpViewCategories = [...currentViewCategories];

        tmpViewCategories.splice(tmpViewCategories.findIndex((tmpCat) => tmpCat.key === cat.key), 1);

        refreshCurrentCategories(tmpViewCategories);
    }

    function initViewCategories(view, levelProps, viewCategories, gcData, bmOptions) {

        if (!view) {
            setBmOptions(bmOptions);
            return;
        }

        const viewId = view.value;

        // viewSetupDate = viewCategories[0].dateFmt;
        // Create the mutable object to be changed by the user
        let tmpViewCategories = [];
        if (viewCategories.length > 0) {
            // Existing view
            // Set the showStatus & showChildren for each category
            // Update the bm list
            let tmpBmOptions = [...bmOptions];
            viewCategories.forEach(viewCat => {
                let replCat = {...viewCat};
                // const level = viewCat.key.split('#').length;
                // replCat.showStatus = level <= 2 ? 'show' : 'hide';
                // replCat.showChildren = level <= 1 ? 'show' : 'hide';
                replCat.showStatus = 'show';
                replCat.showChildren = 'show';
                tmpViewCategories.push(replCat);

                const bmKey = replCat.bmKey;
                if (bmKey && tmpBmOptions.findIndex(bmOpt => bmOpt.value === bmKey) < 0) {
                    tmpBmOptions.push({value: bmKey, label: bmKey})
                }
            });

            setBmOptions(tmpBmOptions);

        } else {
            // New view
            tmpViewCategories = [{
                categoryId: 1,
                viewId: viewId,
                category: 'Portfolio',
                bmKey: null,
                co2TargetCurveFmt: null,
                level: 0,
                key: '1',
                status: 'No change',
                showStatus: 'show',
                showChildren: 'show'
            }];

            setBmOptions(bmOptions);
        }

        let nextCategoryId = findMaxCategoryId(tmpViewCategories) + 1;

        const gcNames = [];

        gcData.forEach(gcRow => {

            let gcName = gcRow.fundName;

            /**
             * parents:
             * [
             *    {
             *        name: Manager 1
             *        catIdx: cat 11
             *    },
             *    {
             *        name: Actions suisses
             *        catIdx: cat 12
             *    }
             * ]
             */
            const categoryWrappers = [];
            const catNames = levelProps.map(prop => gcRow[prop.value]);
            // Top-down parent look-up
            for (let i = 0; i < levelProps.length; i++) {
                let catName = catNames[i];
                const prop = levelProps[i];

                const parent = categoryWrappers.length > 0 ? categoryWrappers[categoryWrappers.length - 1] : null;
                let catAndIdx;
                if (prop.value === 'fundName') {
                    if (gcRow.fund) {
                        catAndIdx = findCategoryFromParents(tmpViewCategories, 'category', gcName, categoryWrappers);

                        // Count the occurrences of this name
                        if (gcNames.hasOwnProperty(gcName)) {
                            gcNames[gcName] = gcNames[gcName] + 1;
                        } else {
                            gcNames[gcName] = 1;
                        }
                    } else {
                        // const parentCategoryName = gcRow.clientAssetClass;
                        let dlLabel = clientConfig.clientLanguage ? directLines[clientConfig.clientLanguage - 1] : directLines[0];
                        // directLines.forEach(dlLabel => {
                        let dlCategory = (catNames.length >= 2 ? catNames[catNames.length - 2] + ' ' : '') + dlLabel;
                        // const dlCategory = parentCategoryName + ' ' + dlLabel;
                        const dlCatAndIdx = findCategoryFromParents(tmpViewCategories, 'category', dlCategory, categoryWrappers);
                        if (dlCatAndIdx) {
                            catAndIdx = dlCatAndIdx;
                        }
                        // });

                        gcName = dlCategory;
                        catName = dlCategory;
                    }
                } else {
                    catAndIdx = findCategoryFromParent(tmpViewCategories, 'category', catName, parent);
                }

                categoryWrappers.push({
                    name: catName, catIdx: catAndIdx, prop: prop.value
                });
            }

            // Check if the parents are correct in case of duplicate category
            // if (catAndIdx && gcNames[gcName] > 1) {
            //     let invalid = parents.some(p => {
            //         return !p.catIdx || !catAndIdx.cat.key.startsWith(p.catIdx.cat.key);
            //     });
            //     if (invalid) {
            //         catAndIdx = null;
            //     }
            // }

            // Same fund, same category
            if (noChangeAll(categoryWrappers)) {
                categoryWrappers.forEach(catWrapper => {
                    const catIdx = catWrapper.catIdx;
                    if (!hasStatus(catIdx.cat)) {
                        const replCat = {
                            ...catIdx.cat,
                            status: 'No Change',
                            showStatus: 'show', // showChildren: i === categoryWrappers.length - 1 ? 'hide' : 'show'
                            showChildren: 'show',
                            dup: catWrapper.prop === 'fundName' ? gcNames[gcName] : null
                        };
                        tmpViewCategories.splice(catIdx.index, 1, replCat);
                    }
                })
                return;
            }

            let cat;
            let index;
            let parentCat;
            // Looping top-down
            categoryWrappers.forEach(catWrapper => {
                // const showChildren = i === categoryWrappers.length - 1 ? 'hide' : 'show';
                const showChildren = 'show';
                if (!catWrapper.catIdx) {
                    // The category does not exist, bind it to the root
                    let key;
                    if (cat) {
                        key = cat.key + '#' + nextCategoryId;
                    } else {
                        const rootCatAndIdx = findCategory(tmpViewCategories, 'level', 0);
                        key = rootCatAndIdx.cat.key + '#' + nextCategoryId;
                    }

                    cat = {
                        categoryId: nextCategoryId,
                        viewId: viewId, // category: catWrapper.name,
                        category: catWrapper.name === 'Not a fund!' ? (parentCat ? parentCat.category + ' ' : '') + directLines[0] : catWrapper.name, // FR by default,
                        bmKey: null,
                        co2TargetCurveFmt: null,
                        key: key,
                        status: 'New',
                        showStatus: 'show',
                        showChildren: showChildren,
                        dup: catWrapper.prop === 'fundName' ? gcNames[gcName] : null
                    };

                    nextCategoryId++;

                    index = tmpViewCategories.length;
                    if (index >= tmpViewCategories.length) {
                        tmpViewCategories.push(cat);
                    } else {
                        tmpViewCategories.splice(index, 0, cat);
                    }
                } else {
                    cat = catWrapper.catIdx.cat;
                    cat.showStatus = 'show';
                    cat.showChildren = showChildren;
                    cat.dup = catWrapper.prop === 'fundName' ? gcNames[gcName] : null;
                    index = catWrapper.catIdx.index;
                }
                parentCat = cat;
            });
        });

        // Set the status for categories at level 0 -> n-2
        tmpViewCategories.forEach(function (viewCat, index) {
            if (!viewCat.status) {
                const hasChildren = tmpViewCategories.some(cat => //
                    viewCat.key !== cat.key && //
                    cat.key.startsWith(viewCat.key) && //
                    hasStatus(cat) //
                );
                if (hasChildren) {
                    tmpViewCategories[index] = {...viewCat, status: 'No Change'};
                }
            }
        });

        sort(tmpViewCategories);

        refreshCurrentCategories(tmpViewCategories);
    }

    function handleViewChange(option, action) {
        // Backup current view
        let newTmpViewConfigs = [...tmpViewConfigs];
        if (selectedView) {
            let curViewConfig = {
                viewId: selectedView.value,
                viewName: viewName,
                viewRank: viewRank,
                prefixReportWithViewName: prefixReportWithViewName,
                levelProps: selectedLevelProps,
                prefixReportProps: selectedPrefixReportProps,
                viewCategories: currentViewCategories
            }
            const index = tmpViewConfigs.findIndex(conf => conf.viewId === curViewConfig.viewId);
            if (index < 0) {
                newTmpViewConfigs.push(curViewConfig);
            } else {
                newTmpViewConfigs.splice(index, 1, curViewConfig);
            }
        }

        let newViewName;
        let newViewRank;
        let newPrefixReportWithViewName;
        let newViewId;
        let newLevelProps;
        let newPrefixReportProps;
        let newViewCategories;
        switch (action.action) {
            case 'create-option':
                newViewName = option.value;
                newViewId = findMaxViewProp('viewId') + 1;
                newViewRank = findMaxViewProp('rank') + 1;
                newPrefixReportWithViewName = false;
                newLevelProps = [propertyOptions[1]];
                newPrefixReportProps = [];
                let tmpViewOptions = [...viewOptions];
                tmpViewOptions.push({value: newViewId, label: newViewName});
                setViewOptions(tmpViewOptions);
                newViewCategories = []; // Set below in initViewCategories
                break;
            case 'clear':
                break;
            default:
                newViewId = option.value;

                // Get view config from the store (contains the potential user unsaved changes)
                const viewConfigBak = newTmpViewConfigs.find(conf => conf.viewId === newViewId);

                if (viewConfigBak) {
                    newViewName = viewConfigBak.viewName;
                    newViewRank = viewConfigBak.viewRank;
                    newPrefixReportWithViewName = viewConfigBak.prefixReportWithViewName;
                    newLevelProps = viewConfigBak.levelProps;
                    newPrefixReportProps = viewConfigBak.prefixReportProps;
                    newViewCategories = viewConfigBak.viewCategories;
                } else {
                    const clientView = findView(newViewId);
                    if (clientView) {
                        newViewName = clientView.name;
                        newViewRank = clientView.rank;
                        newPrefixReportWithViewName = clientView.prefixReportWithViewName;
                        newLevelProps = getPropertyOptions(clientView.categoryConfigs);
                        newPrefixReportProps = getPrefixPropertyOptions(clientView.categoryConfigs);
                        let allViewCategories = clientConfig.viewCategories;
                        newViewCategories = allViewCategories ? allViewCategories.filter(cat => cat.viewId === newViewId) : [];
                    } else {
                        // trouble here
                    }
                }
        }

        const newView = {value: newViewId, label: newViewName};
        setSelectedView(newView);
        setViewName(newViewName);
        setViewRank(newViewRank);
        setPrefixReportWithViewName(newPrefixReportWithViewName);
        setSelectedLevelProps(newLevelProps);
        setSelectedPrefixReportProps(newPrefixReportProps);
        setTmpViewConfigs(newTmpViewConfigs);
        initViewCategories(newView, newLevelProps, newViewCategories, tgtDataOrig, bmOptions);
    }

    const handleBMChange = (bm, action) => {
        const pfKey = action.name; // The name of this select component, the pos id actually
        const tgtCategoryAndIdx = findCategory(currentViewCategories, 'key', pfKey);
        if (tgtCategoryAndIdx && tgtCategoryAndIdx.cat) {
            const viewCat = {...tgtCategoryAndIdx.cat};
            let newBmKey;
            switch (action.action) {
                case 'create-option':
                    newBmKey = bm ? bm.value.trim() : null;
                    if (newBmKey) {
                        let tmpBmOptions = [...bmOptions];
                        tmpBmOptions.push({value: newBmKey, label: newBmKey});
                        setBmOptions(tmpBmOptions);
                    }
                    break;
                case 'clear':
                    newBmKey = null;
                    break;
                default:
                    newBmKey = bm ? bm.value : null;
            }
            viewCat.bmKey = newBmKey;
            let tmpViewCategories = [...currentViewCategories];
            tmpViewCategories.splice(tgtCategoryAndIdx.index, 1, viewCat);
            setCurrentViewCategories(tmpViewCategories);
        }
    }

    const clearAllBMs = () => {
        let tmpViewCategories = [...currentViewCategories];
        tmpViewCategories.forEach(viewCat => {
            viewCat.bmKey = null;
        });
        setCurrentViewCategories(tmpViewCategories);
    }

    const addBMFromCategory = (pfKey) => {
        const tgtCategoryAndIdx = findCategory(currentViewCategories, 'key', pfKey);
        if (tgtCategoryAndIdx && tgtCategoryAndIdx.cat) {
            const viewCat = {...tgtCategoryAndIdx.cat};
            const newBmKey = "BM " + viewCat.category;

            // Set the new BM
            if (viewCat.bmKey !== newBmKey) {
                viewCat.bmKey = newBmKey;

                let tmpViewCategories = [...currentViewCategories];
                tmpViewCategories.splice(tgtCategoryAndIdx.index, 1, viewCat);
                setCurrentViewCategories(tmpViewCategories);
            }

            // Add the BM if not part of the list yet
            if (!bmOptions.some(opt => opt.value === newBmKey)) {
                let tmpBmOptions = [...bmOptions];
                tmpBmOptions.push({value: newBmKey, label: newBmKey});
                setBmOptions(tmpBmOptions);
            }
        }
    }

    const setBMToSameCategories = (pfKey) => {
        const tgtCategoryAndIdx = findCategory(currentViewCategories, 'key', pfKey);
        if (tgtCategoryAndIdx && tgtCategoryAndIdx.cat) {

            const viewCat = tgtCategoryAndIdx.cat;

            const sameCategories = findCategories(currentViewCategories, "category", viewCat.category);

            const bmKey = viewCat.bmKey;
            let tmpViewCategories = [...currentViewCategories];
            sameCategories.forEach(sameCatAndIdx => {
                // Set the new BM
                let cat = {...sameCatAndIdx.cat};
                cat.bmKey = bmKey;
                tmpViewCategories.splice(sameCatAndIdx.index, 1, cat);
            });
            setCurrentViewCategories(tmpViewCategories);
            setShowModal(true);
            const message = sameCategories.length + ' ' + (sameCategories.length <= 1 ? 'category' : 'categories') + ' found';
            setModalMessage(message);
        }
    }

    function getRoot(viewCategories) {
        return viewCategories.filter(cat => //
            !cat.key.includes('#'));
    }

    function getDescendants(viewCategories, catKey) {
        return viewCategories.filter(cat => //
            catKey !== cat.key && //
            cat.key.startsWith(catKey + '#'));
    }

    function getChildren(viewCategories, catKey) {
        return viewCategories.filter(cat => //
            catKey !== cat.key && //
            cat.key.startsWith(catKey + '#') && //
            cat.key.split('#').length - 1 === catKey.split('#').length);
    }

    const handleParentChange = (parent, action) => {
        if (action.action === 'clear') {
            return;
        }
        const catKey = action.name;
        const parentKey = parent.value;

        const categoryAndIdx = findCategory(currentViewCategories, 'key', catKey);

        if (categoryAndIdx && categoryAndIdx.cat) {
            const viewCat = {...categoryAndIdx.cat};

            let parentCategoryAndIdx;
            let addParent = false;
            if (action.action === 'create-option') {
                const keys = viewCat.key.split('#');
                // Remove last
                keys.pop();
                let nextCategoryId = findMaxCategoryId(currentViewCategories) + 1;
                parentCategoryAndIdx = {
                    cat: {
                        categoryId: nextCategoryId,
                        viewId: selectedView.value,
                        category: parentKey,
                        bmKey: null,
                        key: keys.join('#') + '#' + nextCategoryId,
                        status: 'New category',
                        showStatus: 'show',
                        showChildren: 'show',
                    }, index: categoryAndIdx.index
                }
                addParent = true;
            } else {
                parentCategoryAndIdx = findCategory(currentViewCategories, 'key', parentKey);
            }

            if (parentCategoryAndIdx && parentCategoryAndIdx.cat) {
                const parentCat = parentCategoryAndIdx.cat;
                const oldViewCatKey = viewCat.key; // keep track for the children
                viewCat.key = parentCat.key + '#' + viewCat.categoryId;

                // Move the category next to the new parent
                let tmpViewCategories = [...currentViewCategories];
                tmpViewCategories.splice(categoryAndIdx.index, 1, viewCat);
                if (addParent) {
                    tmpViewCategories.push(parentCat);
                }

                // Move the children next to this category
                const children = getDescendants(tmpViewCategories, oldViewCatKey);
                children.forEach(child => {
                    const childCategoryAndIdx = findCategory(tmpViewCategories, 'key', child.key);
                    const childCat = {...childCategoryAndIdx.cat};
                    childCat.key = viewCat.key + '#' + childCat.key.split(viewCat.categoryId + '#')[1];

                    tmpViewCategories.splice(childCategoryAndIdx.index, 1);
                    tmpViewCategories.splice(parentCategoryAndIdx.index + 2, 0, childCat);
                });

                sort(tmpViewCategories);

                refreshCurrentCategories(tmpViewCategories);
            }
        }
    }

    function sort(tmpViewCategories) {
        tmpViewCategories.sort((a, b) => {
            const keysA = a.key.split('#');
            const keysB = b.key.split('#');
            const length = Math.max(keysA.length, keysB.length);
            for (let i = 0; i < length; i++) {
                const aInt = parseInt(keysA[i]);
                const bInt = parseInt(keysB[i]);
                if (aInt === bInt) {
                    continue;
                }
                return (isNaN(aInt) ? 0 : aInt) - (isNaN(bInt) ? 0 : bInt);
            }
        })
    }

    const handleReplace = (destCat, action) => {
        if (action.action === 'clear') {
            return;
        }
        const srcCatKey = action.name;
        const destCatKey = destCat.value;

        const srcCategoryAndIdx = findCategory(currentViewCategories, 'key', srcCatKey);
        const destCategoryAndIdx = findCategory(currentViewCategories, 'key', destCatKey);
        if (srcCategoryAndIdx && srcCategoryAndIdx.cat && destCategoryAndIdx && destCategoryAndIdx.cat) {
            const srcCat = srcCategoryAndIdx.cat;
            const destCat = {...destCategoryAndIdx.cat};

            // Move the category next to the new parent
            let tmpViewCategories = [...currentViewCategories];
            tmpViewCategories.splice(srcCategoryAndIdx.index, 1);

            // Bind the children to the dest category
            const children = getDescendants(tmpViewCategories, srcCat.key);
            children.forEach(child => {
                const childCategoryAndIdx = findCategory(tmpViewCategories, 'key', child.key);
                const childCat = {...childCategoryAndIdx.cat};
                childCat.key = destCat.key + '#' + childCat.key.split(srcCat.categoryId + '#')[1];

                tmpViewCategories.splice(childCategoryAndIdx.index, 1);
                tmpViewCategories.splice(destCategoryAndIdx.index + 1, 0, childCat);
            });

            sort(tmpViewCategories);

            refreshCurrentCategories(tmpViewCategories);
        }
    }

    const handleMove = (value, action) => {
        if (action.action === 'clear') {
            return;
        }
        const srcCatKey = action.name;
        const destCatKey = value.value;

        const srcCategoryAndIdx = findCategory(currentViewCategories, 'key', srcCatKey);
        const destCategoryAndIdx = findCategory(currentViewCategories, 'key', destCatKey);
        if (srcCategoryAndIdx && srcCategoryAndIdx.cat && destCategoryAndIdx && destCategoryAndIdx.cat) {
            const srcCat = {...srcCategoryAndIdx.cat};

            const srcIndex = srcCategoryAndIdx.index;
            const destIndex = destCategoryAndIdx.index;

            let tmpViewCategories = [...currentViewCategories];
            if (srcIndex > destIndex) {
                // Move upwards in the list
                // Remove src elements
                const srcChildren = getDescendants(tmpViewCategories, srcCatKey);
                tmpViewCategories.splice(srcIndex, 1 + srcChildren.length);
                // Insert them at the right position
                tmpViewCategories.splice(destIndex, 0, srcCat, ...srcChildren);

                // Compute the category Ids
                const root = getRoot(tmpViewCategories)[0];
                let catIdIter = root.categoryId + 1;
                let tmpViewCategories2 = [root];
                const children = getChildren(tmpViewCategories, root.key);
                refreshKeys(tmpViewCategories2, children, catIdIter, root.key, tmpViewCategories);
                refreshCurrentCategories(tmpViewCategories2);
            }
        }
    }

    function refreshKeys(acc, children, catId, newParentKey, origList) {

        for (let i = 0; i < children.length; i++) {

            const child = {...children[i]};
            const currKey = child.key;

            const grandChildren = getChildren(origList, currKey);

            // child.key = currKey.substring(0, currKey.lastIndexOf('#') + 1) + catId;
            child.key = newParentKey + '#' + catId;
            child.categoryId = catId;

            acc.push(child);

            catId++;
            if (grandChildren.length > 0) {
                catId = refreshKeys(acc, grandChildren, catId, child.key, origList);
            }
        }
        return catId;
    }

    function handleParamValueChange(e) {
        const key = e.target.name;
        const value = e.target.value;
        const catAndIdx = findCategory(currentViewCategories, 'key', key);
        if (catAndIdx) {
            let catTmp = {...catAndIdx.cat};
            catTmp.co2TargetCurveFmt = value;
            let tmpViewCategories = [...currentViewCategories];
            tmpViewCategories.splice(catAndIdx.index, 1, catTmp);
            setCurrentViewCategories(tmpViewCategories);
        }
    }

    function handlePropChange(prop, action) {
        const id = parseInt(action.name.replace("select-category-", ""));
        const props = [...selectedLevelProps];

        if (action.action === 'clear') {
            props.splice(id, 1);
        } else {
            props.splice(id, 1, prop);
        }
        setSelectedLevelProps(props);
        // Changing a property requires to start the categories from scratch
        initViewCategories(selectedView, props, [], tgtDataOrig, bmOptions);
    }

    function handlePrefixReportPropChange(prop, action) {
        const id = parseInt(action.name.replace("select-prefix-report-category-", ""));
        const props = [...selectedPrefixReportProps];

        while (props.length <= id) {
            props.push(null);
        }

        if (action.action === 'clear') {
            props.splice(id, 1);
        } else {
            props.splice(id, 1, prop);
        }
        setSelectedPrefixReportProps(props);
    }

    function clearAllFilters() {
        dispatch(clearAllGCFilters());
    }

    function toClassName(label) {
        return label ? label.toLowerCase().replaceAll(" ", "-") : "";
    }

    function filterViewCategories(status) {
        // Keep the categories with given status
        return currentViewCategories.filter(cat => status === 'Removed' ? !hasStatus(cat) : cat.status === status);
    }

    function expandAll(viewCategory) {
        showHideSubCategories(viewCategory, 'show', 'show');
    }

    function showHideChildren(viewCategory) {
        let newShowChildren = viewCategory.showChildren === 'show' ? 'hide' : 'show';

        showHideSubCategories(viewCategory, newShowChildren, 'hide');
    }

    function showHideSubCategories(viewCategory, newShowChildren, showSubChildren) {
        let tmpViewCategories = [...currentViewCategories];

        const catIdx = findCategory(tmpViewCategories, 'key', viewCategory.key);
        let tmpCat = {...catIdx.cat};
        tmpCat.showChildren = newShowChildren;
        tmpViewCategories.splice(catIdx.index, 1, tmpCat);

        showHideSubCategoriesRec(tmpViewCategories, viewCategory, newShowChildren, showSubChildren);

        refreshCurrentCategories(tmpViewCategories);
    }

    function showHideSubCategoriesRec(tmpViewCategories, viewCategory, showChildren, showSubChildren) {
        const children = getChildren(tmpViewCategories, viewCategory.key);
        children.forEach(child => {
            const catIdx = findCategory(tmpViewCategories, 'key', child.key);
            let tmpChild = {...catIdx.cat};
            tmpChild.showStatus = showChildren;
            tmpChild.showChildren = showSubChildren;
            tmpViewCategories.splice(catIdx.index, 1, tmpChild);
            showHideSubCategoriesRec(tmpViewCategories, child, showSubChildren, showSubChildren);
        });
    }

    function hideModal() {
        setModalMessage(null);
        setShowModal(false);
    }

    let viewSetupDate;

    if (currentViewCategories && currentViewCategories.length > 0) {
        viewSetupDate = currentViewCategories[0].dateFmt;
    }

    // GC (last level)
    let rows;
    let displayed = 0;
    if (tgtData && tgtData.length > 0) {
        displayed = tgtData.length;

        rows = tgtData.map(pos => {

            return <tr key={pos.posId}>
                {/*<td style={{width: "5%"}}>{pos.accountNbr}</td>*/}
                <td style={{width: "7%"}}>{pos.isin}</td>
                <td style={{width: "65px"}}>{pos.currency}</td>
                <td style={{width: "120px", textAlign: "right"}}>{pos.valuationFmt}</td>
                <td style={{width: "10%"}}>{pos.gcClassification}</td>
                <td style={{width: "7%"}}>{pos.accountManager}</td>
                <td style={{width: "10%"}}>{pos.broadCategory}</td>
                <td style={{width: "10%"}}>{pos.subCategory}</td>
                <td style={{width: "10%"}}>{pos.detailedCategory1}</td>
                <td style={{width: "10%"}}>{pos.detailedCategory2}</td>
                <td style={{width: "10%"}}>{pos.name}</td>
                <td style={{width: "10%"}}>{pos.displayName}</td>
                <td>{pos.fundName}</td>
            </tr>;
        });
    }

    const total = tgtDataOrig ? tgtDataOrig.length : 0;
    const showClearAllFilters = displayed !== total;
    const catWidth = "400px";

    let categorySelectors = [];
    for (let i = 0; i < propertyOptions.length; i++) {
        categorySelectors.push(<div key={propertyOptions[i].value}
                                    style={{
                                        display: "flex", flexWrap: "nowrap", margin: "5px 0 5px 5px"
                                    }}>
            <div style={{padding: "0.75em 0.5em 0 0", width: "120px"}}>
                <label>Level {i + 1}</label>
            </div>
            <div style={{padding: "0.75em 0.5em 0 0", width: "220px"}}>
                <Select options={propertyOptions}
                        name={'select-category-' + i}
                        value={selectedLevelProps.length > i ? selectedLevelProps[i] : null}
                        isClearable
                        isSearchable={true}
                        closeMenuOnSelect={true}
                        onChange={handlePropChange}/>
            </div>
            <div style={{padding: "0.75em 0.5em 0 0", width: "120px", marginLeft: "20px"}}>
                <label>Prefix report with</label>
            </div>
            <div style={{padding: "0.75em 0.5em 0 0", width: "220px"}}>
                <Select options={propertyOptions}
                        name={'select-prefix-report-category-' + i}
                        value={selectedPrefixReportProps[i]}
                        isClearable
                        isSearchable={true}
                        closeMenuOnSelect={true}
                        onChange={handlePrefixReportPropChange}/>
            </div>
        </div>);
    }

    const newCats = currentViewCategories ? (filterViewCategories('New').length) : 0;
    const removedCats = currentViewCategories ? (filterViewCategories('Removed').length) : 0;

    let lastViewSetupData = [];
    clientConfig.clientViews.forEach(view => {
        if (view.viewId > 0) { // Skip Funds view
            let allViewCategories = clientConfig.viewCategories;
            const viewCategories = allViewCategories ? allViewCategories.filter(cat => cat.viewId === view.viewId) : [];
            lastViewSetupData.push({
                viewId: view.viewId,
                viewName: view.name,
                date: (viewCategories && viewCategories.length > 0 ? viewCategories[0].dateFmt : null),
                lastUpdate: view.lastUpdate
            });
        }
    });
    let lastDate;
    lastViewSetupData.forEach(viewData => {
        if (!lastDate || lastDate < viewData.date) {
            lastDate = viewData.date;
        }
    });
    const padding = '.5rem';
    const lastViewSetupComp = lastViewSetupData.map(viewData => {
        return <div key={viewData.viewId} style={{
            display: "flex", flexWrap: "nowrap", flexGrow: "1", margin: "0", padding: padding, borderTop: "1px solid #bbc6d3",
            backgroundColor: !viewData.date || viewData.date < lastDate || viewData.date < dateOption.value ? "#ffd300" : "#fff"
        }}>
            <div style={{width: "150px", padding: padding}}>{viewData.viewName}</div>
            <div style={{width: "90px", padding: padding}}>{viewData.date ? viewData.date : '-'}</div>
            <div style={{width: "150px", padding: padding}}>{viewData.lastUpdate ? viewData.lastUpdate : '-'}</div>
        </div>;
    });

    return <>
        <div className={'detail-title'}>
            <p className={'canopia2 detail-title-font'}>1. View setup</p>
        </div>
        {tgtDataOrig && <form onSubmit={handleSaveSetup}>
            <div style={{display: "flex", flexWrap: "nowrap"}}>
                <div>
                    <div style={{display: "flex", flexWrap: "nowrap", margin: "20px 0 5px 5px"}}>
                        <div style={{width: "220px"}}>
                            <CreatableSelect options={viewOptions}
                                             placeholder={'Create or select a view...'}
                                             name={'view-select'} //
                                             value={selectedView}
                                             closeMenuOnSelect={true}
                                             onChange={handleViewChange}/>
                        </div>
                    </div>
                    {selectedView && <>
                        <div style={{display: "flex", flexWrap: "nowrap", margin: "5px 0 5px 5px"}}>
                            <div style={{padding: "0.75em 0.5em 0 0", width: "120px"}}>
                                <label>View name</label>
                            </div>
                            <div style={{padding: "0.75em 0.5em 0 0", width: "220px"}}>
                                <input value={viewName}
                                       placeholder="By Asset Class"
                                       className={'form-control'}
                                       style={{width: "180px"}}
                                       aria-label="View Name"
                                       onChange={(event) => setViewName(event.target.value)}
                                       aria-describedby="basic-addon2"/>
                            </div>
                            <div style={{padding: "0.75em 0.5em 0 0", width: "320px"}}>
                                <label>Prefix reports with view name (mainly when views share the
                                    same
                                    categories with different benchmarks)</label>
                            </div>
                            <div className="form-group"
                                 style={{padding: "0.75em 0.5em 0 0", width: "20px"}}>
                                <input type="checkbox" name={"prwvn-cb"}
                                       onChange={() => setPrefixReportWithViewName(!prefixReportWithViewName)}
                                       checked={prefixReportWithViewName}/>
                            </div>
                        </div>
                        <div style={{display: "flex", flexWrap: "nowrap", margin: "5px 0 5px 5px"}}>
                            <div style={{padding: "0.75em 0.5em 0 0", width: "120px"}}>
                                <label>View rank</label>
                            </div>
                            <div className="form-group"
                                 style={{padding: "0.75em 0.5em 0 0", width: "80%"}}>
                                <input value={viewRank}
                                       placeholder="1, 2, 3, ..."
                                       className={'form-control'}
                                       style={{width: "180px"}}
                                       aria-label="View Id"
                                       onChange={(event) => setViewRank(event.target.value)}
                                       aria-describedby="basic-addon2"/>
                            </div>
                        </div>
                        {categorySelectors}
                    </>}
                </div>
                {lastViewSetupData.length > 0 &&
                    <div style={{
                        // border: "1px solid #bbc6d3",
                        // borderRadius: "1rem",
                        margin: "2rem 0 0 4rem"
                    }}>
                        <div key={-1} className={'canopia2 background-light'} style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            flexGrow: "1",
                            // margin: "0px 10px 0 10px",
                            padding: padding,
                            borderTop: "1px solid #bbc6d3"
                        }}>
                            <div style={{padding: padding}}>
                                <LabelWithTooltip label={'Current category setup'}
                                                  text={LEGENDS['setupViewCategoryStatus']}/>
                            </div>
                        </div>
                        <div key={-2} className={'canopia2 background-light'} style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            flexGrow: "1",
                            padding: padding,
                            borderTop: "1px solid #bbc6d3"
                        }}>
                            <div style={{width: "150px", padding: padding}}>
                                View name
                            </div>
                            <div style={{width: "90px", padding: padding}}>
                                Date
                            </div>
                            <div style={{width: "150px", padding: padding}}>
                                Last update
                            </div>
                        </div>
                        {lastViewSetupComp}
                    </div>}
            </div>

            <div style={{padding: "0.75em 0.5em 0 0", width: "100%"}}>
                <abbr title={'Click on this button to save both the view and its categories'}>
                    <button className="btn btn-primary btn-block" disabled={saving}>
                        {saving && (<span className="spinner-border spinner-border-sm"
                                          style={{marginRight: '5px'}}/>)}
                        <span>Save</span>
                    </button>
                </abbr>
            </div>
            {saveMessages && (<div className="form-group">
                <br/>
                <h5>Summary:</h5>
                <div className={"alert import_log"}>
                    {saveMessages.map(message => {
                        return <div
                            className={"import_log_" + message.key}>{message.value}</div>
                    })}
                </div>
            </div>)}

            {currentViewCategories && <>
                <div style={{marginTop: '20px'}} className={'detail-title'}>
                    <p className={'canopia2 detail-title-font'}>2. Category
                        setup {viewSetupDate && <>(current: {viewSetupDate})</>}</p>
                </div>
                <p style={{marginTop: '20px'}}>
                    Define the parameters of the view categories
                </p>
                Benchmark
                <ul>
                    <li>search by key (MSWD...) or name (World DC...)</li>
                    <li>defining a direct benchmark e.g. "ETF_MSWD" to a category at level n
                        requires to
                        set a global benchmark e.g. "BM Equities", "BM Global" to the parent
                        categories
                        at level 0 to n-1
                    </li>
                    <li>self-benchmarking must be set with "Self-Benchmarking" part of the name</li>
                    <li>ghost-benchmarking ... TODO</li>
                    <li>
                        <ArrowRightCircleFill size={'1.2em'} style={{marginRight: '10px'}}/>Set BM + category
                    </li>
                    <li>
                        <Tornado size={'1.2em'} style={{marginRight: '10px'}}/>Set BM to same categories
                    </li>
                    <li>
                        <Trash size={'1.2em'} style={{marginRight: '10px'}}/>Clear all benchmarks
                    </li>
                </ul>
                {CO2} tgt curve
                <ul>
                    <li>format: 2020-12-31:-0.15,2030-12-31:NaN</li>
                </ul>

                <div style={{
                    marginTop: "10px",
                    padding: "0.75em 0.5em",
                    borderTop: "1px solid var(--canopia-border-color)"
                }}>
                    Category changes vs. latest config
                    <span className={'gc-status ' + (newCats > 0 ? 'new' : 'no-change')}
                          style={{margin: '0px 20px'}}>
                                        New: <b>{newCats}</b>
                                    </span>
                    <span className={'gc-status ' + (removedCats > 0 ? 'removed' : 'no-change')}>
                                        Removed: <b>{removedCats}</b>
                                    </span>
                </div>

                <div style={{
                    borderTop: "1px solid var(--canopia-border-color)",
                    backgroundColor: "var(--canopia-bg-color-1)"
                }}>
                    <div style={{display: "flex", flexWrap: "nowrap"}}>
                        <div className={"setup-th"} style={{
                            flexBasis: catWidth
                        }}>
                            <b>Category
                                ({currentViewCategories && currentViewCategories.length})</b>
                        </div>
                        <div className={"setup-th"} style={{flexBasis: "45px"}}>
                            <b>Dup</b>
                        </div>
                        <div className={"setup-th"} style={{flexBasis: "120px"}}>
                            <b>Status</b>
                        </div>
                        <div className={"setup-th"} style={{flexBasis: "30px"}}>
                        </div>
                        <div className={"setup-th"} style={{flexBasis: "300px"}}>
                            <b>Benchmark (opt.)</b>
                            <span className={'label-hover'} style={{marginLeft: "20px", opacity: 0}}
                                  onClick={() => clearAllBMs()}>
                                                <Trash size={'1.2em'}/>
                                       </span>
                        </div>
                        <div className={"setup-th"} style={{flexBasis: "35px"}}>
                        </div>
                        <div className={"setup-th"} style={{flexBasis: "150px"}}>
                            <b>{CO2} tgt curve (opt.)</b>
                        </div>
                        <div className={"setup-th"} style={{flexBasis: "120px"}}>
                            <b>Key</b>
                        </div>
                    </div>
                </div>
                {currentViewCategories
                    .filter(viewCategory => viewCategory.showStatus === 'show')
                    .map(viewCategory => {

                        const children = getDescendants(currentViewCategories, viewCategory.key);
                        const hasChildren = children.length > 0;
                        let catClass = hasChildren ? 'label-hover' : '';
                        const hasGrandChildren = children.some(child => {
                            const gChildren = getDescendants(currentViewCategories, child.key);
                            return gChildren && gChildren.length > 0;
                        })

                        let expandAllIcon = hasGrandChildren &&
                            <span className={catClass} style={{opacity: 0}}
                                  onClick={() => expandAll(viewCategory)}>
                                            <IconContext.Provider value={{
                                                className: "react-icons canopia2", size: "1.2em"
                                            }}>
                                                <HiChevronDoubleDown/>
                                            </IconContext.Provider>
                                       </span>;
                        let categoryCtrl = hasChildren && <span className={catClass}
                                                                onClick={() => showHideChildren(viewCategory)}>
                                            <IconContext.Provider
                                                value={{className: "react-icons canopia2", size: "1.4em"}}>
                                                {viewCategory.showChildren === 'show' ? <HiChevronDown/> :
                                                    <HiChevronRight/>}
                                            </IconContext.Provider>
                                        </span>;
                        let ctrl = <span className={'nowrap'}>
                                            {expandAllIcon}
                            {categoryCtrl}
                                        </span>
                        let missingIcons = hasChildren ? hasGrandChildren ? 0 : 1 : hasGrandChildren ? 1 : 2;
                        let addBMIcon = <span className={'label-hover'} style={{opacity: 0}}
                                              onClick={() => addBMFromCategory(viewCategory.key)}>
                                                <ArrowRightCircleFill size={'1.2em'}/>
                                       </span>;
                        let setBMToSameCatIcon = <span className={'label-hover'} style={{opacity: 0}}
                                                       onClick={() => setBMToSameCategories(viewCategory.key)}>
                                                <Tornado size={'1.2em'}/>
                                       </span>;
                        return <React.Fragment key={viewCategory.key}>
                            <div className={'setup-row'}>
                                <div
                                    className={"setup-col td-depth-" + (getLevel(viewCategory) + 1 + missingIcons)}
                                    style={{
                                        flexBasis: catWidth,
                                        paddingTop: "0.75em",
                                        paddingBottom: "0.75em"
                                    }}>
                                    {ctrl}
                                    {viewCategory.category}
                                </div>
                                <div className={"setup-col"}
                                     style={{flexBasis: "45px", padding: "0.55em 0.5em"}}>
                                    {viewCategory.dup && viewCategory.dup > 1 &&
                                        <div className={'gc-duplicates'}>
                                            {viewCategory.dup}°
                                        </div>}
                                </div>
                                <div className={"setup-col"}
                                     style={{flexBasis: "120px", padding: "0.55em 0.5em"}}>
                                    {viewCategory.status ?
                                        <div
                                            className={'gc-status ' + toClassName(viewCategory.status)}>
                                            {viewCategory.status}
                                        </div> : <div className={'gc-status removed'}>
                                            Removed
                                        </div>}
                                </div>
                                <div className={"setup-col"}
                                     style={{flexBasis: "30px", padding: "0.75em 0"}}>
                                    {addBMIcon}
                                </div>
                                <div className={"setup-col"}
                                     style={{flexBasis: "300px", padding: "0.25em 0.5em"}}>
                                    <CreatableSelect
                                        createOptionPosition={'first'}
                                        name={viewCategory.key}
                                        isClearable={true}
                                        isSearchable={true}
                                        options={bmOptions}
                                        isMulti={false}
                                        onChange={handleBMChange}
                                        value={bmOptions.filter(opt => opt.value === viewCategory.bmKey)}
                                    />
                                </div>
                                <div className={"setup-col"}
                                     style={{flexBasis: "35px", padding: "0.75em"}}>
                                    {setBMToSameCatIcon}
                                </div>
                                <div className={"setup-col"}
                                     style={{padding: "0.5em 0.5em", flexBasis: "150px"}}>
                                    <input type={"text"}
                                           name={viewCategory.key}
                                           value={viewCategory.co2TargetCurveFmt}
                                           onChange={handleParamValueChange}
                                           placeholder=""
                                           className={'form-control'}
                                           style={{width: "135px"}}
                                           aria-label="CO2"
                                           aria-describedby="basic-addon2"/>
                                </div>
                                <div className={"setup-col"}
                                     style={{padding: "0.75em 0.5em", flexBasis: "120px"}}>
                                    {viewCategory.key}
                                </div>
                                {!hasStatus(viewCategory) && <div className={"setup-col"}
                                                                  style={{
                                                                      flexBasis: "50px",
                                                                      padding: "0.25em 0.5em"
                                                                  }}>
                                    <Button variant="light" size="sm"
                                            style={{
                                                marginLeft: 0,
                                                padding: 4,
                                                borderWidth: '1px 1px 1px 0',
                                                borderColor: '#ced4da',
                                                borderRadius: '0.25rem .25rem .25rem 0.25rem',
                                            }}
                                            onClick={() => removeCat(viewCategory)}>
                                        <IconContext.Provider
                                            value={{
                                                className: "react-icons canopia2",
                                                size: "1.4em"
                                            }}>
                                            <TiDeleteOutline/>
                                        </IconContext.Provider>
                                    </Button>
                                </div>}
                                {viewCategory.key.includes('#') && <div className={"setup-col"}
                                                                        style={{
                                                                            flexBasis: "200px",
                                                                            padding: "0.25em 0.5em"
                                                                        }}>
                                    <Select
                                        createOptionPosition={'first'}
                                        name={viewCategory.key}
                                        isClearable={true}
                                        isSearchable={true}
                                        options={categoryOptions}
                                        isMulti={false}
                                        onChange={handleMove}
                                        placeholder={'Move above...'}
                                        // value={bmOptions.filter(opt => opt.value === viewCategory.bmKey)}
                                    />
                                </div>}
                                {statusIs(viewCategory, 'New') && <>
                                    <div className={"setup-col"}
                                         style={{flexBasis: "200px", padding: "0.25em 0.5em"}}>
                                        <CreatableSelect
                                            createOptionPosition={'first'}
                                            name={viewCategory.key}
                                            isClearable={true}
                                            isSearchable={true}
                                            options={categoryOptions}
                                            isMulti={false}
                                            onChange={handleParentChange}
                                            placeholder={'Change parent...'}
                                            // value={bmOptions.filter(opt => opt.value === viewCategory.bmKey)}
                                        />
                                    </div>
                                    <div className={"setup-col"}
                                         style={{flexBasis: "200px", padding: "0.25em 0.5em"}}>
                                        <CreatableSelect
                                            createOptionPosition={'first'}
                                            name={viewCategory.key}
                                            isClearable={true}
                                            isSearchable={true}
                                            options={categoryOptions}
                                            isMulti={false}
                                            onChange={handleReplace}
                                            placeholder={'Replace by...'}
                                            // value={bmOptions.filter(opt => opt.value === viewCategory.bmKey)}
                                        />
                                    </div>
                                </>}
                            </div>
                        </React.Fragment>
                    })}
            </>}

            <Modal
                show={showModal}
                onHide={() => hideModal()}
                backdrop="static"
                keyboard={false}
                size={"lg"}
                aria-labelledby="terms-and-conditions"
                scrollable={true}
            >
                <Modal.Body>
                    <div className="form-group">
                        <div className={"alert alert-info"} role="alert">
                            {modalMessage}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => hideModal()}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Table hover className={'portfolio sticky-thc'} style={{marginTop: '20px'}}>
                <thead>
                <tr>
                    <th className={colorClass + " " + pfBgClass} colSpan={4}>
                        <p className={'canopia2 detail-title-font'}>Global custody
                            ({dateOption.value})</p>
                    </th>
                    <th className={colorClass + " " + pfBgClass + " form-padding"} colSpan={8}>
                        <FilterResult showClearButton={showClearAllFilters}
                                      clearFunc={clearAllFilters}
                                      displayed={displayed}
                                      total={total}>
                        </FilterResult>
                    </th>
                </tr>
                <tr>
                    {/*<th className={colorClass + " " + pfBgClass}>*/}
                    {/*    <THFilter targetSort={targetSort} sortCol="accountNbr" filterCol="accountNbr"*/}
                    {/*              label="Account" selectFunc={selectSetupState}*/}
                    {/*              filterFunc={filterGC}/>*/}
                    {/*</th>*/}
                    <th className={colorClass + " " + pfBgClass}>
                        <THSort target={targetSort} colName="isin" label="ISIN"/>
                    </th>
                    <th className={colorClass + " " + pfBgClass}>
                        <THSort target={targetSort} colName="currency" label="Ccy"/>
                    </th>
                    <th className={colorClass + " " + pfBgClass}>
                        <THSort target={targetSort} colName="valuation" label="Amount"/>
                    </th>
                    <th className={colorClass + " " + pfBgClass}>
                        <THFilter targetSort={targetSort} sortCol="gcClassification"
                                  filterCol="gcClassification"
                                  label="GC Class" selectFunc={selectSetupState}
                                  filterFunc={filterGC}/>
                    </th>
                    <th className={colorClass + " " + pfBgClass}>
                        <THFilter targetSort={targetSort} sortCol="accountManager"
                                  filterCol="accountManager"
                                  label="Manager" selectFunc={selectSetupState}
                                  filterFunc={filterGC}/>
                    </th>
                    <th className={colorClass + " " + pfBgClass}>
                        <THFilter targetSort={targetSort} sortCol="broadCategory"
                                  filterCol="broadCategory"
                                  label="Broad Category" selectFunc={selectSetupState}
                                  filterFunc={filterGC}/>
                    </th>
                    <th className={colorClass + " " + pfBgClass}>
                        <THFilter targetSort={targetSort} sortCol="subCategory"
                                  filterCol="subCategory"
                                  label="Sub Category" selectFunc={selectSetupState}
                                  filterFunc={filterGC}/>
                    </th>
                    <th className={colorClass + " " + pfBgClass}>
                        <THFilter targetSort={targetSort} sortCol="detailedCategory1"
                                  filterCol="detailedCategory1"
                                  label="Det. Category 1" selectFunc={selectSetupState}
                                  filterFunc={filterGC}/>
                    </th>
                    <th className={colorClass + " " + pfBgClass}>
                        <THFilter targetSort={targetSort} sortCol="detailedCategory2"
                                  filterCol="detailedCategory2"
                                  label="Det. Category 2" selectFunc={selectSetupState}
                                  filterFunc={filterGC}/>
                    </th>
                    <th className={colorClass + " " + pfBgClass}>
                        <THSort target={targetSort} colName="name" label="Name"/>
                    </th>
                    <th className={colorClass + " " + pfBgClass}>
                        <THSort target={targetSort} colName="displayName" label="Display Name"/>
                    </th>
                    <th className={colorClass + " " + pfBgClass}>
                        <THSort target={targetSort} colName="fundName" label="Final Name"/>
                    </th>
                </tr>
                </thead>
                <tbody>
                {rows}
                </tbody>
            </Table>
        </form>}
    </>;
}