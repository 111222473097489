import React from "react";
import ChipIndicator from "./ChipIndicator";

export default function Coverage(props) {

    let esgReport = props.esgReport;

    // Coverage
    let covBar = '';
    if (esgReport && esgReport.rating && esgReport.rating.trim() !== "") {

        // 3 colors indicator
        let indColor = '';
        let label = '';
        if (esgReport.coverage >= 0.9) {
            indColor = 'coverage-high';
            label = 'High';
        } else if (esgReport.coverage >= 0.5) {
            indColor = 'coverage-medium';
            label = 'Medium';
        } else if (esgReport.coverage > 0.0) {
            indColor = 'coverage-low';
            label = 'Low';
        } else {
            // indColor = 'coverage-none';
            label = 'None';
        }
        label += ': ' + esgReport.coverageFormatted;
        covBar = <ChipIndicator className={indColor} label={label}/>
    }

    return <>{covBar}</>;
}
