import React, {useState} from "react";
import {compare} from "../canopia/CanopiaUtils";
import AdminService from "../../services/admin.service";
import {loadGC} from "../../reducer/setupSlice";
import {useDispatch} from "react-redux";
import {Accordion} from "react-bootstrap";

export default function GlobalParams(props) {

    const dispatch = useDispatch();

    const clientConfig = props.clientConfig;
    const clientId = clientConfig.clientWrapper.id;
    const dateOption = props.dateOption;

    let configAsArray = [];
    Object.entries(clientConfig).forEach(([key, value]) => {
        if (key !== 'clientCurrency' && (!value || typeof value === "boolean" || typeof value !== "object")) {
            configAsArray.push({key: key, value: value});
        }
    });
    sort(configAsArray);

    // Form
    const [globalParams, setGlobalParams] = useState(configAsArray);
    const [showRefreshButton, setShowRefreshButton] = useState(false);
    const [modifiedKeys, setModifiedKeys] = useState([]);

    // Save
    const [loading, setLoading] = useState(false);
    const [saveMessages, setSaveMessages] = useState(null);

    function sort(configAsArray) {
        configAsArray.sort(function (a, b) {
            return compare(a.key, b.key, 'asc');
        });
    }

    const handleSaveGlobalParams = (e) => {
        e.preventDefault();

        setLoading(true);
        setSaveMessages(null);
        // setErrorName(null);
        // setErrors(null);
        // setErrorMessage(null);

        AdminService.saveGloablParams({
            clientId,
            dateStr: dateOption ? dateOption.value : null,
            globalParams
        }).then(
            response => {
                setLoading(false);
                const respData = response.data;
                if (respData) {
                    const messages = respData.data;
                    setSaveMessages(messages);
                    if (!messages.some(message => message.key === 'error')) {
                        setShowRefreshButton(true);
                        setModifiedKeys([]);
                    }
                } else {
                    setSaveMessages(null);
                }
            },
            error => {
                setLoading(false);
                // processError(error);
            }
        );
    }

    function dispatchLoadGC() {
        if (clientId && dateOption) {
            // setShowRefreshButton(false);
            dispatch(loadGC({clientId: clientId, dateStr: dateOption ? dateOption.value : null}));
        }
    }

    function handleParamValueChange(e) {
        const key = e.target.name;
        const value = e.target.value;
        let globalParamsTmp = [...globalParams];
        let index = globalParamsTmp.map(e => e.key).indexOf(key);
        if (index >= 0) {
            let paramTmp = {...globalParamsTmp.at(index)};
            paramTmp.value = value;
            globalParamsTmp.splice(index, 1, paramTmp);
        } else {
            globalParamsTmp.push({key: key, value: value});
        }

        sort(globalParamsTmp);
        setGlobalParams(globalParamsTmp);
        modifiedKeys.push(key);
        setModifiedKeys(modifiedKeys);
    }

    return <>
        <div style={{margin: '20px 0'}} className={'detail-title'}>
            <p className={'canopia2 detail-title-font'}>Global parameters</p>
        </div>
        <Accordion>
            <Accordion.Item eventKey="0">
                <Accordion.Header>
                    Show / hide parameter descriptions
                </Accordion.Header>
                <Accordion.Body>
                    <p style={{marginTop: '20px'}}>
                        Define the global parameters of the client:
                    </p>
                    clientBrand (mandatory)
                    <ul>
                        <li>possible values: <b>conser / canopia</b></li>
                    </ul>
                    clientLanguage (mandatory)
                    <ul>
                        <li>possible values: <b>1(FR) / 2(EN) / 3(DE)</b></li>
                    </ul>
                    clientType (mandatory)
                    <ul>
                        <li>free text but here are the existing values: <b>AM / Foundation / Insurance / Market /
                            Pension
                            Fund</b></li>
                    </ul>
                    conserParamsCsv
                    <ul>
                        <li>list of Conser parameters in the following order:
                            BLOC_FOCUS(<b>co2 / co2int / gender / impact</b>); FIRST_PAGE_ONLY(<b>yes / ""</b>);
                            FIRST_PAGE_AND_BM_REVIEW(<b>yes / ""</b>); VIGNETTES(<b>yes / ""</b>); CO2_EMISSION(<b>yes /
                                ""</b>);
                            CO2_INTENSITY(<b>yes / ""</b>);
                            GENDER(<b>yes / ""</b>);
                            HIGH_IMPACT_FUNDS(<b>yes / ""</b>); RATING_RANGE_GICS_2(<b>yes / ""</b>);
                        </li>
                    </ul>
                    fundSourceFilterCsv (mandatory)
                    <ul>
                        <li>define the list of available sources to get the fund compositions from. The order matters.
                            The
                            "client" source type can be fine-grained with authorized sources only
                        </li>
                        <li>format: <b>client:bcv,ubs;AM;external</b></li>
                    </ul>
                    periodicityKey (mandatory)
                    <ul>
                        <li>possible values: <b>none / quarterly / semiAnnual / annual</b></li>
                    </ul>
                    strategyStatus (mandatory)
                    <ul>
                        <li>
                            live strategies show up in Canopia where stopped ones do not
                        </li>
                        <li>
                            possible values: <b>live / stopped</b>
                        </li>
                    </ul>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
        <div style={{margin: "10px"}}>
            {modifiedKeys.length > 0 && <div className={"alert alert-warning"} role="alert">There are unsaved
                modifications!</div>}
        </div>
        <form onSubmit={handleSaveGlobalParams}>
            <div style={{
                marginTop: "20px",
                borderRadius: ".25rem",
                padding: "1rem",
                border: "1px solid var(--canopia-border-color)",
                display: "flex",
                flexWrap: "wrap"
            }}>
                {globalParams.map(row => {
                    let className = modifiedKeys.includes(row.key) ? 'global-param-modified' : '';
                    return <div key={row.key} style={{width: "450px", display: "flex", flexWrap: "nowrap"}} className={className}>
                        <div style={{padding: "1em 0.5em", width: "230px"}}>
                            {row.key}
                        </div>
                        <div style={{padding: "0.5em"}}>
                            <input type={"text"}
                                   name={row.key}
                                   value={row.value}
                                   onChange={handleParamValueChange}
                                   placeholder=""
                                   className={'form-control'}
                                   style={{width: "180px"}}
                                   aria-label="Pf Date"
                                   aria-describedby="basic-addon2"/>
                        </div>
                    </div>
                })}
            </div>
            <div style={{padding: "0.75em 0.5em 0 0", width: "100%"}}>
                <button className="btn btn-primary btn-block" disabled={loading}>
                    {loading && (
                        <span className="spinner-border spinner-border-sm"
                              style={{marginRight: '5px'}}/>
                    )}
                    <span>Save</span>
                </button>
            </div>
            {saveMessages && (
                <div className="form-group">
                    <br/>
                    <h5>Summary:</h5>
                    <div className={"alert import_log"}>
                        {saveMessages.map(message => {
                            return <><span
                                className={"import_log_" + message.key}>{message.value}</span><br/></>
                        })}
                    </div>
                </div>
            )}
        </form>
        {showRefreshButton &&
            <div>
                <p>It is recommended to reload the full config after param updates to reflect the changes in the view
                    screen (especially the language)</p>
                <button className="btn btn-primary btn-block" onClick={dispatchLoadGC}>
                    <span>Refresh setup</span>
                </button>
            </div>
        }
    </>
}