import React, {useCallback, useEffect, useState} from 'react';
import {Navigate, useLocation, useNavigate} from "react-router-dom";
import Navigation from "../core/Navigation";
import AuthService from "../../services/auth.service";
import ajaxloader from "../../images/ajax-loader.gif";
import Footer from "../core/Footer";
import {useSelector} from "react-redux";
import {selectClientState} from "../../reducer/clientSlice";
import Modal from "react-bootstrap/Modal";
import Terms from "./Terms";
import {CheckSquareFill, Square} from "react-bootstrap-icons";
import Button from "react-bootstrap/Button";

const PrivateRoute = ({component: Component}) => {

    const [user, setUser] = useState(null);
    const [reqStatus, setReqStatus] = useState('idle');

    const [errorName, setErrorName] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const {clientConfig} = useSelector(selectClientState);

    const [logoClassName, setLogoClassName] = useState('logo-canopia');

    const [showDisclaimer, setShowDisclaimer] = useState(false);
    const [disclaimerButtonEnabled, setDisclaimerButtonEnabled] = useState(false);
    const [disclaimerId, setDisclaimerId] = useState(null);

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        setReqStatus('pending');
        const currentUser = AuthService.getCurrentUser();
        if (currentUser) {
            setUser(currentUser);
            if (!currentUser.disclaimerAccepted) {
                setShowDisclaimer(true);
                setDisclaimerId(currentUser.disclaimerId);
            }
            setReqStatus('success');
        } else {
            navigate("/login");
        }
    }, [setReqStatus, navigate]);

    const changeLogo = useCallback((clientBrand) => {
        let logoClassName;

        switch (clientBrand) {
            case 'conser':
                logoClassName = 'logo-conser-by-canopia';
                break;
            default:
                logoClassName = 'logo-canopia';
        }

        setLogoClassName(logoClassName);
    }, []);

    useEffect(() => {
        if (clientConfig && clientConfig.clientBrand) {
            changeLogo(clientConfig.clientBrand);
        }
    }, [clientConfig, changeLogo]);

    const processError = (error) => {
        let response = error.response;

        let errorName;
        let errorMessage;
        if (response
            && response.data
            && response.data.error
            && response.data.message
        ) {
            errorName = response.data.error;
            errorMessage = response.data.message;
        } else {
            errorName = 'Service unavailable';
            errorMessage = 'We apologize for the inconvenience, our team is working on solving the issue. Please try to come back in a few minutes.\nThank you for your patience.';
            if (error.message) {
                console.log(error.message);
            }
        }

        setReqStatus('error');
        setErrorName(errorName);
        setErrorMessage(errorMessage);
    }

    const handleDisclaimerCheckbox = () => {
        setDisclaimerButtonEnabled(!disclaimerButtonEnabled);
    }

    const decline = () => {
        AuthService.logout();
        navigate('/login');
        AuthService.resetStore();
    }

    const accept = () => {
        AuthService.accept(user.username, disclaimerId)
            .then((response) => {
                    const data = response.data;
                    if (data === 'success') {
                        setShowDisclaimer(false);
                    }
                },
                error => {
                    processError(error);
                }
            );
    }

    // handleDisclaimerScroll() {
    //     const ref = disclaimerModalRef.current;
    //     // console.log(`onScroll, modal.scrollTop: ` + ref.scrollTop + ', modal.scrollHeight: '+ref.scrollHeight + ', modal.clientHeight: ' + ref.clientHeight)
    //     const bottom = ref.scrollHeight - ref.scrollTop === ref.clientHeight;
    //     if (bottom) {
    //         setDisclaimerButtonEnabled(bottom);
    //     }
    // }

    if (reqStatus === 'pending' || reqStatus === 'idle') {
        return <div style={{textAlign: 'center', paddingTop: '10rem'}}><img alt="loading" src={ajaxloader}/></div>
    }

    if (reqStatus === 'error') {
        return <>
            <br/>
            <header className="jumbotron">
                <h3 className='canopia'>{errorName}</h3>
                <p>{errorMessage}</p>
            </header>
        </>;
    }

    // reqStatus === 'success'
    if (showDisclaimer) {
        return <Modal
            show={showDisclaimer}
            onHide={() => setShowDisclaimer(false)}
            backdrop="static"
            keyboard={false}
            size={"xl"}
            aria-labelledby="terms-and-conditions"
            scrollable={true}
        >
            <Modal.Body>
                <Terms mainClass={'canopia-modal-content'}/>
            </Modal.Body>
            <Modal.Footer>
                    <span onClick={handleDisclaimerCheckbox} style={{marginRight: '3vw'}}>
                        <span>
                            {disclaimerButtonEnabled ? <CheckSquareFill color={'#adad2f'} size={'1.4em'}/> : <Square color={'#adad2f'} size={'1.4em'}/>}
                        </span>
                        <span style={{marginLeft: '8px'}}>
                            I have read and accept the present terms and conditions of use
                        </span>
                    </span>
                <Button variant="secondary" onClick={() => decline()}>
                    Decline
                </Button>
                <Button variant="primary" onClick={() => accept()} disabled={!disclaimerButtonEnabled}>
                    Accept
                </Button>
            </Modal.Footer>
        </Modal>
    } else if (user) {
        return <>
            <Navigation user={user}/>

            <div className="frm-body">
                <div className="frm-body2">
                    <div className={logoClassName}/>
                    <Component user={user} changeLogo={changeLogo}/>
                </div>
            </div>

            <Footer/>
        </>
    } else {
        return <Navigate to={{pathname: '/login', state: {from: location}}}/>
    }
}

export default PrivateRoute