import React from "react";
import LabelWithTooltip from "./LabelWithTooltip";
import {Filter, X} from "react-bootstrap-icons";

/**
 * Renders a gauge using Bootstrap's ProgressBar
 */
export default function FilterResult(props) {
    const showClearButton = props.showClearButton;
    const clearFunc = props.clearFunc;
    const displayed = props.displayed;
    const total = props.total;

    return <span className={'float-right'}>
                {showClearButton &&
                    <LabelWithTooltip label={
                        <span onClick={clearFunc}>
                            <Filter className={"react-icons canopia"} size={'1.8em'} style={{paddingBottom: "2px"}}/>
                            <X className={"react-icons canopia6"} size={'1.6em'} style={{paddingBottom: "2px", marginLeft: "-14px", marginBottom: "-7px"}}/>
                        </span>
                    } text={'Clear all filters'} className={'canopia-icon-with-legend'}>
                    </LabelWithTooltip>
                }
        {displayed && <span style={{marginLeft: '8px', fontSize: '11px'}}>Displaying {displayed} / {total}</span>}
    </span>
}
