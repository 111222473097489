import React from "react";

/**
 * Create an SVG icon to represent the consensus grade
 *
 * Created with inkscape.
 * To export the svg code from inkscape:
 * - File -> Save as, then choose "Plain SVG (*.svg)"
 * - Convert the file to JSX format using https://svg2jsx.com/
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function GradeIcon(props) {

    let rating = props.rating;
    if (!rating || rating.trim() === "") {
        return null;
    }
    rating = rating.trim();
    // rating = rating.length === 1 ? ' ' + rating : rating;
    const size = props.size;
    const origSize = 774;
    const viewboxSize = "0 0 " + origSize + " " + origSize;
    const borderColor = props.borderColor;
    const innerColor = props.innerColor;
    const fontColor = props.fontColor;

    const ratingX = rating === 'NR' ? "410" : rating.length === 1 ? "520" : "442.246";

    const grade = rating === 'NR' || rating.length === 1 ? rating : rating.substring(0, 1);
    const adj = rating !== 'NR' && rating.length === 2 ? rating.substring(1) : null;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox={viewboxSize}
            version="1.1"
            className='consensus-grade-icon'
        >
            <g>
                <path
                    fill={borderColor}
                    d="M473.508 733.563c-76.605 1.221-119.26-35.374-174.29-88.679-55.032-53.304-92.968-94.772-94.188-171.376-1.221-76.605 35.374-119.26 88.679-174.29 53.304-55.032 94.772-92.968 171.376-94.188 76.605-1.221 119.26 35.374 174.29 88.679 55.032 53.304 92.968 94.772 94.188 171.376 1.221 76.605-35.374 119.26-88.679 174.29-53.304 55.032-94.772 92.968-171.376 94.188z"
                    transform="matrix(1.4567 0 0 1.4567 -296.623 -296.623)"
                ></path>
                <path
                    fill={innerColor}
                    d="M473.508 733.563c-76.605 1.221-119.26-35.374-174.29-88.679-55.032-53.304-92.968-94.772-94.188-171.376-1.221-76.605 35.374-119.26 88.679-174.29 53.304-55.032 94.772-92.968 171.376-94.188 76.605-1.221 119.26 35.374 174.29 88.679 55.032 53.304 92.968 94.772 94.188 171.376 1.221 76.605-35.374 119.26-88.679 174.29-53.304 55.032-94.772 92.968-171.376 94.188z"
                    transform="matrix(1.05942 0 0 1.05942 -110.18 -110.18)"
                ></path>
                <text
                    xmlSpace="preserve"
                    x={ratingX}
                    y="449.504"
                    transform="matrix(1.00809 0 0 .99929 -251.856 42.815)"
                    style={{
                        fontWeight: 'bold',
                        fontSize: 320
                    }}
                    fill={fontColor}
                >
                    {grade}
                    {adj && <tspan
                        x={adj === "+" ? "670" : "690"}
                        y={adj === "+" ? "400" : "370"}
                    >
                        {adj}
                    </tspan>}
                </text>
            </g>
        </svg>
    );
}
