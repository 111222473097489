import React, {useCallback, useEffect, useState} from "react";

import AuthService from "../../services/auth.service";
import {Card} from "react-bootstrap";
import {OPTIONAL, REQUIRED} from "../canopia/CanopiaUtils";
import CreatableSelect from "react-select/creatable";
import {loadAllClients, selectUserState} from "../../reducer/userSlice";
import {useDispatch, useSelector} from "react-redux";
import ajaxloader from "../../images/ajax-loader.gif";

// const required = value => {
//     if (!value) {
//         return (
//             <div className="alert alert-danger" role="alert">
//                 This field is required!
//             </div>
//         );
//     }
// };

export default function Register(props) {

    const dispatch = useDispatch();

    const {allClients} = useSelector(selectUserState);
    const [clientOptions, setClientOptions] = useState([]);

    const [loadStatus, setLoadStatus] = useState("idle");
    const [loadMessage, setLoadMessage] = useState("");

    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [company, setCompany] = useState('');
    const [clients, setClients] = useState([]);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [successful, setSuccessful] = useState(false);
    const [messages, setMessages] = useState([]);
    const [registerNew, setRegisterNew] = useState(false);

    const load = useCallback(() => {
        dispatch(loadAllClients());
    }, [dispatch]);

    // Load all the clients to populate the dropdown (done only once)
    useEffect(() => {
        if (loadStatus === "idle") {
            setLoadStatus("pending");
            try {
                load();
            } catch (err) {
                setLoadStatus("error");
                setLoadMessage("An error occurred while loading the page");
            } finally {
                setLoadStatus("success");
            }
        }
    }, [loadStatus, load]);

    useEffect(() => {
            let clientOptionsTmp = [];

            allClients.forEach(client => {
                const clientId = client.id;
                const clientName = client.displayName;
                // let checked = selectedUserClients.includes(clientIds);
                const option = {value: clientId, label: clientName + " (" + clientId + ")"};
                clientOptionsTmp.push(option);
                if (clientId === 243) {
                    setClients([option]);
                }
            });

            setClientOptions(clientOptionsTmp);
        }, [allClients]
    )

    const handleRegister = (e) => {
        e.preventDefault();

        setSuccessful(false);
        setMessages([]);

        let valid = true;
        [firstname, lastname, email, password].forEach(prop => {
            if (!prop || prop.trim().length === 0) {
                valid = false;
                setMessages(['Please fill in all the required fields']);
            }
        });

        if (valid) {
            let clientIds = [];
            clients.forEach(client => clientIds.push(client.value));
            AuthService.register(firstname, lastname, company, clientIds, email, password)
                .then((response) => {
                        setMessages([response.data.message]);
                        setSuccessful(true);
                        setRegisterNew(true);
                    },
                    error => {
                        processError(error);
                    }
                );
        }
    }

    const processError = (error) => {
        let messages = [];

        const errorData = error.response && error.response.data;
        if (errorData.errors) {
            messages.push('Please review the following error(s):');
            errorData.errors.forEach(valError => {
                messages.push(valError.field + ': ' + valError.defaultMessage);
            });

        } else if (errorData.message) {
            messages.push(errorData.message);
        } else {
            messages.push(error.toString());
        }
        // const resMessage =
        // (error.response &&
        //     error.response.data &&
        //     error.response.data.message) ||
        // error.message ||
        // error.toString();

        setMessages(messages);
    }

    const reset = () => {
        setFirstname("");
        setLastname("");
        setCompany("");
        setEmail("");
        setPassword("");
        setSuccessful(false);
        setMessages([]);
        setRegisterNew(false);
    }

    let content;
    if (loadStatus === "error") {
        content = <div className="main-content">
            <header className="jumbotron">
                <h3 className='canopia'>Error</h3>
                <p>{loadMessage}</p>
            </header>
        </div>
    } else if (loadStatus !== "success") {
        content = <div><br/><img alt="loading" src={ajaxloader}/></div>;
    } else {
        // let clientOptions = [];
        //
        // allClients.forEach(client => {
        //     const clientId = client.id;
        //     const clientName = client.displayName;
        //     // let checked = selectedUserClients.includes(clientIds);
        //     const option = {value: clientId, label: clientName + " (" + clientId + ")"};
        //     clientOptions.push(option);
        //     if (clientId === 243) {
        //         setClients(option);
        //     }
        // });

        const handleSelectClient = (clients, action) => {
            // let tgtClients = [];
            // clients.forEach(client => {
            //     tgtClients.push(client.value);
            // });
            setClients(clients);
        }

        content =
            <Card style={{width: '30rem', margin: 'auto'}}>
                {/*<Card.Img variant="top" src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"/>*/}
                <Card.Body>
                    <Card.Title className='canopia'>Login</Card.Title>
                    <form onSubmit={handleRegister}>
                        {!successful && (
                            <>
                                <div className="form-group">
                                    <label htmlFor="username">First name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="firstname"
                                        value={firstname}
                                        placeholder={REQUIRED}
                                        onChange={(e) => setFirstname(e.target.value)}
                                    />
                                </div>
                                <br/>

                                <div className="form-group">
                                    <label htmlFor="username">Last name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="lastname"
                                        value={lastname}
                                        placeholder={REQUIRED}
                                        onChange={(e) => setLastname(e.target.value)}
                                    />
                                </div>
                                <br/>

                                <div className="form-group">
                                    <label htmlFor="company">Company</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="company"
                                        value={company}
                                        placeholder={OPTIONAL}
                                        onChange={(e) => setCompany(e.target.value)}
                                    />
                                </div>
                                <br/>

                                <div className="form-group">
                                    <label>Client(s)</label>
                                    <CreatableSelect options={clientOptions}
                                                     value={clients}
                                                     isClearable
                                                     isSearchable={true}
                                                     closeMenuOnSelect={false}
                                                     onChange={handleSelectClient}
                                                     isMulti/>
                                </div>
                                <br/>

                                <div className="form-group">
                                    <label htmlFor="username">E-mail (username)</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="username"
                                        value={email}
                                        placeholder={REQUIRED}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                                <br/>

                                <div className="form-group">
                                    <label htmlFor="password">Password</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        name="password"
                                        value={password}
                                        placeholder={REQUIRED}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </div>
                                <br/>

                                <div className="form-group">
                                    <button className="btn btn-primary btn-block">Sign Up</button>
                                </div>
                            </>
                        )}

                        {messages && messages.length > 0 && (
                            <>
                                <br/>
                                <div className="form-group">
                                    <div className={
                                        successful
                                            ? "alert alert-success"
                                            : "alert alert-danger"
                                    } role="alert">
                                        {messages.map(mess => {
                                            return <p key={mess}>{mess}</p>;
                                        })}
                                    </div>
                                </div>
                            </>
                        )}
                    </form>
                    {registerNew &&
                        <div className="form-group">
                            <button className="btn btn-primary btn-block" onClick={reset}>Register another user</button>
                        </div>}
                </Card.Body>
            </Card>
    }

    return (
        <div className="main-content">
            {content}
        </div>
    );
}
