import axios from "axios";
import {authHeader, jsonAuthHeader} from "./auth-header";
import {CONTEXT_URL} from "../containers/canopia/CanopiaUtils";

const API_URL = CONTEXT_URL + "api/admin/";

class AdminService {

    async reload(reloadConserData) {
        return axios.post(API_URL + "reload",
            {reloadConserData},
            {headers: authHeader(), cache: 'no-store'}); // set the auth header as the register process is not public on server side
    }

    async run(clientIds, dateStr, consensusId, unpublish, runType, reviewType, reviewFileName, skipReview) {
        return axios.post(API_URL + "run",
            {clientIds, dateStr, consensusId, unpublish, runType, reviewType, reviewFileName, skipReview},
            {
                headers: authHeader(), // set the auth header as the register process is not public on server side
                cache: 'no-store'
            });
    }

    async importConsensus(consensusId, dateStr) {
        return axios.post(API_URL + "import/consensus",
            {consensusId, dateStr},
            {headers: authHeader(), cache: 'no-store'});
    }

    async importBM(dateStr) {
        return axios.post(API_URL + "import/bm",
            {dateStr},
            {headers: authHeader(), cache: 'no-store'});
    }

    async importRE(path, clientId, dateStr) {
        return axios.post(API_URL + "import/re",
            {path, clientId, dateStr},
            {headers: authHeader(), cache: 'no-store'});
    }

    async importFund(file, source) {
        // Create an object of formData
        const formData = new FormData();

        // Update the formData object
        formData.append(
            "file", file
        );
        formData.append(
            "source", source
        );

        return axios.post(API_URL + "import/fund",
            formData,
            {headers: authHeader(), cache: 'no-store', encType: 'multipart/form-data'});
    }

    // async saveStream(stream) {
    //     let content = [];
    //     stream.on(data => {
    //         content.push(data);
    //     })
    //     return axios.post(API_URL + "save/file",
    //         {content: content.join('')},
    //         {headers: authHeader(), cache: 'no-store'});
    // }

    async importGC(file) {
        // Create an object of formData
        const formData = new FormData();

        // Update the formData object
        formData.append(
            "file",
            file
        );

        return axios.post(API_URL + "import/gc",
            formData,
            {headers: authHeader(), cache: 'no-store', encType: 'multipart/form-data'});
    }

    async saveSetup(params) {
        let headers = authHeader();
        headers['Content-Type'] = 'application/json';

        return axios.post(API_URL + "setup/save",
            JSON.stringify(params),
            {headers: headers, cache: 'no-store'});
    }

    async saveGloablParams(params) {
        return axios.post(API_URL + "setup/save-params",
            JSON.stringify(params),
            {headers: jsonAuthHeader(), cache: 'no-store'});
    }

    async loadClientDates(params) {
        return axios.post(API_URL + "client-dates",
            JSON.stringify(params),
            {
                headers: jsonAuthHeader(),
                cache: 'no-store'
            })
            // .then(response => {
            //     let contentType = response.headers.get("content-type");
            //     if (contentType && contentType.indexOf("application/json") !== -1) {
            //         return response.json();
            //     } else {
            //         console.log("The content type is not JSON, found " + contentType);
            //     }
            // })
            // .then(json => json)
            // .catch(error => error)
    }

    async saveClientInfo(params) {
        return axios.post(API_URL + "setup/save-client-info",
            JSON.stringify(params),
            {
                headers: jsonAuthHeader(),
                cache: 'no-store'
            })
    }
}

const adminService = new AdminService();
export default adminService;
