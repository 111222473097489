import axios from "axios";
import {authHeader} from "./auth-header";
import {CONTEXT_URL} from "../containers/canopia/CanopiaUtils";

const API_URL = CONTEXT_URL + "api/review/";

class ReviewService {

    call(action, clientId, dateStr) {
        return axios.post(API_URL + action,
            {
                clientId,
                dateStr
            },
            {headers: authHeader(), cache: 'no-store'}); // set the auth header as the register process is not public on server side
    }
}


const reviewService = new ReviewService();
export default reviewService;
