import React from "react";

/**
 * Highlight the search keyword in the target name and limit the display to a given size.
 * In the latter case, show a small tooltip with the full name.
 */
export default function NameTruncWithSearch(props) {
    let name = props.name;
    let maxSize = props.maxSize;
    let search = props.search;

    if (!name) {
        return "";
    }

    const length = name.length;
    const isTruncated = length > maxSize;

    // The displayed name, truncated if too long
    let displayedName = isTruncated ? name.substring(0, maxSize) + "..." : name;

    if (search && search.trim() !== '') {

        // Get the start index of the search word in the name
        const indexStart = name.toLowerCase().indexOf(search.toLowerCase());
        const indexEnd = indexStart + search.length;

        // Search match and highlight the keyword (only if it appears in the displayed name)
        if (indexStart >= 0) {

            if (indexEnd <= maxSize) {
                // The search keyword is part of the displayed name
                displayedName = <>
                    {name.substring(0, indexStart)}
                    <span className='search_highlight'>{name.substring(indexStart, indexEnd)}</span>
                    {isTruncated ? name.substring(indexEnd, maxSize) + "..." : name.substring(indexEnd)}
                </>;
            } else if (indexStart < maxSize) {
                // The search keyword is partially in the displayed name
                displayedName = <>
                    {name.substring(0, indexStart)}
                    <span className='search_highlight'>{name.substring(indexStart, maxSize) + "..."}</span>
                </>;
            } else {
                // The search keyword is part of the truncated name -> just highlight the ...
                displayedName = <>
                    {name.substring(0, maxSize)}
                    <span className='search_highlight'>...</span>
                </>;
            }
        }
    }

    return <span className={"nowrap"}>{isTruncated ? <abbr title={name}>{displayedName}</abbr> : displayedName}</span>;
}
