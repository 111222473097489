import React from "react";
import {getRatingColors} from "./CanopiaUtils";
import GradeIcon from "./GradeIcon";

export default function FinalGrade(props) {

    const rank = props.rank;
    const ratingColors = getRatingColors(rank);
    const borderColor = ratingColors.lightColor;
    const innerColor = ratingColors.color;
    const fontColor = ratingColors.fontColor;

    return <GradeIcon rating={props.rating} size={props.size} borderColor={borderColor} innerColor={innerColor}
                      fontColor={fontColor}/>;
}
