import React from "react";

export default function Terms(props) {

    let mainClass = props.mainClass;
    if (mainClass == null) {
        mainClass = "main-content";
    }

    return (
        <div className={mainClass}>
            <span className={'client-title'}>Terms and conditions</span>
            <div style={{paddingTop: '20px'}}>
                <p>
                    These General Terms and Conditions (the "GTC") govern the use of the Canopia WebPortal (the "Webportal") by the User with the personal access codes duly provided by
                    Canopia Sàrl (the "User").
                </p>
                <p className={'terms-and-cond'}>
                    1. Access to Webportal
                </p>
                <p> Upon login to the Webportal, and for the duration of its use, the User confirms that he/she: 1) has a valid personal access code issued by Canopia; 2) has read and
                    understood these Terms and Conditions; 3) complies strictly with them; 4) where applicable, complies with the conditions of use set out in the documents accessible on
                    the
                    Webportal.
                </p>
                <p className={'terms-and-cond'}>
                    2. Using of the Webportal
                </p>
                <p>The information provided by the Webportal, in any form whatsoever, such as reports, documentation, files, etc. (the "Data" and/or the "Documentation"), originates from
                    Canopia Sàrl ("Canopia") and/or its partners, Conser Invest S.A. and Fundo S.A. ("Partners"). The User acknowledges that the Webportal Data should not be construed as
                    advice
                    or recommendations on portfolio management, whether for investment or disinvestment.
                </p>
                <p className={'terms-and-cond'}>3. Confidentiality</p>
                <p>The data provided in the Webportal shall be treated in strict confidence by the User in accordance with these GTCs and, where applicable, the information contained in
                    the Documentation. Some of the Data originate from third parties with whom Canopia has expressly undertaken to enforce strict confidentiality clauses, which are
                    therefore also applicable to the User. The User undertakes not to disclose the Data to third parties, unless the latter also have personal access codes provided by
                    Canopia.
                </p>
                <p className={'terms-and-cond'}>4. Representations and Warranties</p>
                <p>The User certifies and warrants that he/she has understood the scope and restrictions applicable to the use of the Webportal and its Data and undertakes to comply with
                    any restrictions that Canopia may notify him/her of with respect to its use. The Internet connection itself is not part of the service. Canopia will hold the User
                    financially responsible for all damages resulting from the violation of the rules of use of the Webportal as described in these GTC. The User notes that Canopia may
                    take such measures as it deems appropriate to protect the data provided, the integrity and performance of its computer systems, servers or networks, and may for this
                    purpose suspend or discontinue access to or provision of the Webportal and the information provided. Canopia can amend these GTC at any time. Such amendments become
                    binding for the User when he/she first use the Website after the implementation of the amendments.
                </p>
                <p className={'terms-and-cond'}>5. Intellectual Property</p>
                <p>The User expressly acknowledges that Canopia is the sole owner of the intellectual property rights or similar rights relating to the Data and information provided in
                    the Webportal or has obtained from third parties the right to provide them to the User, and that the User therefore acquires no rights (including know-how) in respect
                    of such Data or in respect of the related documentation. In particular, the User undertakes not to: (a) remove, obscure or alter any copyright or other proprietary
                    notices contained in the Documentation; (b) use, combine, duplicate, store, sell, license, distribute, publish or otherwise use the Data or Documentation in any way,
                    or permit the use of the Data or any part thereof, except as expressly provided; c) modify the visuals (including format, size, colours, logos, text, graphics,
                    hyperlinks) of the codes and/or web pages or documentation, except with the prior written consent of Canopia, authorizing such modifications to
                    ensure better integration of Canopia's Data into the User's visual environment (d) use the Webportal in violation of these T&Cs or the contractual rules applicable to
                    the relationship between the User and its other service providers; (e) copy, adapt, reverse engineer, recompile, disassemble, translate or modify, in whole or in
                    part, the Data and/or any related documentation; (f) use the Webportal in an outsourcing or access-sharing environment with third parties, or in any way allow third
                    parties to use or access the Webportal
                </p>
                <p className={'terms-and-cond'}>6. Disclaimer and limitation of liability</p>
                <p>The content of the Webportal has been compiled with meticulous care and to the best of the knowledge of Canopia and its Partners. Canopia assumes no responsibility for
                    the timeliness, completeness or accuracy of its pages and the Data provided. In no event shall Canopia be liable for any direct, indirect, incidental or special
                    damages arising from the use of the Webportal and/or the Data. Canopia shall not be liable for any problems caused by changes or modifications to the operating
                    characteristics of the Webportal. The Data and Documentation provided in the Webportal are based on the ESG Consensus® methodology, owned by Conser Invest S.A., an
                    evaluation methodology offering an easy comparison of any portfolio or fund, based on a multi-scan of several independent, complementary and recognized ESG sources.
                    The evaluation tool measures not
                    only the level (the average of the consensus) of the ESG consensus but also its strength (the strength of the consensus). The final sustainability score illustrates
                    the environmental, social and governance “(ESG”) quality and impacts of the evaluated issuers. The following scale is applied: A+, A, A-, B+, B, B-; C+, C, C- and D.
                    Further information is provided in the Webportal and on the Canopia website www.canopia.ch.
                </p>
                <p className={'terms-and-cond'}>7. Miscellaneous</p>
                <p>All consents, notices or communications between Canopia and the User are valid only if made in writing. Canopia shall not be liable for problems connecting to the
                    Webportal due to causes beyond its reasonable control, including, but not limited to, acts of God, general network failures, propagation of viruses, acts of
                    government or
                    courts, fires, floods, explosions, earthquakes.
                </p>
                <p className={'terms-and-cond'}>8. Applicable law/jurisdiction</p>
                <p>These GTC shall be governed by and construed in accordance with the substantive law of Switzerland. The courts of the Canton de Vaud shall be competent for all
                    disputes in
                    connection with these GTC.</p>
            </div>
        </div>
    );
}
