import React, {useRef} from "react";
import {OverlayTrigger, ProgressBar, Tooltip} from "react-bootstrap";
import Table from "react-bootstrap/Table";
import {Plus} from "react-bootstrap-icons";

export default function NetZero(props) {

    const target = useRef(null);

    const category = props.name;
    const pfRow = props.pfRow;
    const bmRow = props.bmRow;
    const showBm = props.showBm;
    let pfThemes, bmThemes;
    const sum = props.sum;
    let bmSum;
    let className = props.className;
    const widthClass = props.widthClass;
    let name = '';
    let keys = [], keysPart = [];
    // const pfBgClass = 'background-light';
    const bmBgClass = 'background-light';
    const borderLeftClassName = 'border-large-left';
    const bmVariant = 'co2-bm';

    switch (className) {
        case 'aligned' :
            pfThemes = pfRow.netZeroPos;
            bmThemes = bmRow ? bmRow.netZeroPos : null;
            bmSum = bmRow ? bmRow.netZeroPosSum : null;
            name = 'Aligned';
            keys = ['T1_5', 'TB2', 'T2', 'C'];
            keysPart = ['C'];
            break;
        case 'misaligned' :
            pfThemes = pfRow.netZeroNeg;
            bmThemes = bmRow ? bmRow.netZeroNeg : null;
            bmSum = bmRow ? bmRow.netZeroNegSum : null;
            name = 'Misaligned';
            keys = ['N_AL', 'N_D', 'P_A'];
            keysPart = ['P_A'];
            break;
        default:
        // nothing
    }

    if ((!pfThemes || !pfThemes.length)) {
        if (!showBm || (showBm && (!bmThemes || !bmThemes.length))) {
            return "";
        }
    }

    if (sum.status !== 'NONE') {
        let weight = sum ? sum.weight : 0;
        let bmWeight = bmSum ? bmSum.weight : 0;
        const axisWidth = '1px';
        const gaugeHeight = 8;
        const axisHeight = (showBm ? 2 * gaugeHeight + 2 * 5 : gaugeHeight + 2 * 5) + 'px';
        let axisColor = "#1D416D";
        const maxBarWidth = 95;

        let pfCommittedSum = 0;
        let bmCommittedSum = 0;

        let initiativeList = <>
            {keys.map(key => {
                let item;
                if (pfThemes && pfThemes.length) {
                    pfThemes.forEach(pfTheme => {
                        if (pfTheme.label === key) {
                            item = pfTheme;
                        }
                    });
                }
                let bmItem;
                if (showBm && bmThemes && bmThemes.length) {
                    bmThemes.forEach(bmTheme => {
                        if (bmTheme.label === key) {
                            bmItem = bmTheme;
                        }
                    });
                }

                let themeDesc;
                let tgtClass = className + (keysPart.includes(key) ? '-partial' : '');
                let pfWeight = '';
                if (item) {
                    themeDesc = item.description;
                    pfWeight = item.formattedWeight;
                    if (keysPart.includes(key)) {
                        pfCommittedSum += item.weight;
                    }
                }
                let bmWeight = '';
                if (showBm && bmItem) {
                    themeDesc = bmItem.description;
                    bmWeight = bmItem.formattedWeight;
                    if (keysPart.includes(key)) {
                        bmCommittedSum += bmItem.weight;
                    }
                }
                if (themeDesc) {
                    return <tr key={key}>
                        <td>
                            <div className={tgtClass + ' nowrap'}>{themeDesc}</div>
                        </td>
                        <td align={"right"}>{pfWeight}</td>
                        {showBm && <>
                            <td align={"right"} className={bmBgClass + ' ' + borderLeftClassName}>
                                {bmWeight}
                            </td>
                        </>}
                    </tr>;
                } else {
                    return <React.Fragment key={key}/>
                }
            })}
        </>;

        const globalMax = 1.0;
        let pfBar;
        let bmBar;
        if (pfCommittedSum > 0.005) {
            pfBar = <ProgressBar bsPrefix={"progress-canopia"}>
                <ProgressBar bsPrefix={"progress-canopia-left"} variant={className} now={weight - pfCommittedSum} max={globalMax} key={1}/>
                <ProgressBar bsPrefix={"progress-canopia"} variant={className + '-partial'} now={pfCommittedSum} max={globalMax} key={3}/>
            </ProgressBar>;
        } else {
            pfBar = <ProgressBar bsPrefix={"progress-canopia"} variant={className} now={weight} max={globalMax}/>;
        }
        if (bmCommittedSum > 0.005) {
            bmBar = <ProgressBar bsPrefix={"progress-canopia"}>
                <ProgressBar bsPrefix={"progress-canopia-left"} variant={bmVariant} now={bmWeight - bmCommittedSum} max={globalMax} key={1}/>
                <ProgressBar bsPrefix={"progress-canopia"} variant={bmVariant + '-transition'} now={bmCommittedSum} max={globalMax} key={3}/>
            </ProgressBar>;
        } else {
            bmBar = showBm && <ProgressBar bsPrefix={"progress-canopia"} variant={bmVariant} now={bmWeight} max={globalMax}/>;
        }

        pfBar = <div style={{display: "grid", gridTemplateColumns: "5fr 10px", height: '8px'}}>
            <div style={{
                width: maxBarWidth + '%',
                float: 'left'
            }}>{pfBar}</div>
            <div className={'climate-icon-plus'}>{weight > globalMax && <Plus/>}</div>
        </div>;

        bmBar = showBm && <div style={{display: "grid", gridTemplateColumns: "5fr 10px", height: '8px'}}>
            <div style={{
                width: maxBarWidth + '%',
                float: 'left'
            }}>{bmBar}</div>
            <div className={'climate-icon-plus'}>{bmWeight > globalMax && <Plus/>}</div>
        </div>;

        return <div className={widthClass}>
            <div style={{float: 'left', width: axisWidth, height: axisHeight, backgroundColor: axisColor, zIndex: -1}}/>
            <OverlayTrigger
                placement='auto'
                delay={{show: 500, hide: 0}}
                trigger={["hover", "focus"]}
                target={target}
                overlay={
                    <Tooltip>
                        <h3 className={'canopia2'}>{category}</h3>
                        <p className={'canopia'}><b>{name}</b></p>
                        <Table size="sm">
                            <thead>
                            <tr>
                                <th className={"canopia2"}>Theme</th>
                                <th className={"canopia2"}>
                                    <span style={{float: "right"}}>Weight</span>
                                </th>
                                {showBm && <th className={"canopia2 " + bmBgClass + ' ' + borderLeftClassName}>
                                    <span style={{float: "right"}}>Benchmark</span>
                                </th>}
                            </tr>
                            </thead>
                            <tbody>
                            {initiativeList}
                            <tr>
                                <td><b>Total</b></td>
                                <td align={"right"}><b>{sum ? sum.formattedWeight : ''}</b></td>
                                {showBm && <>
                                    <td align={"right"} className={bmBgClass + ' ' + borderLeftClassName}><b>{bmSum ? bmSum.formattedWeight : ''}</b></td>
                                </>}
                            </tr>
                            </tbody>
                        </Table>
                    </Tooltip>
                }
                defaultShow={false}>
                {/*need to set display to prevent conflict between tr mouseover and tooltip*/}
                <span style={{width: '100%', zIndex: -2}} ref={target}>
                    <div style={{width: '100%', height: '26px', paddingTop: 5, zIndex: -2}}>
                        {/*<ProgressBar variant={className} ref={target} now={weight} max={0.2}/>*/}
                        {pfBar}
                        {bmBar}
                    </div>
                </span>

                {/*<span style={{display: 'inline-block', width: 120}}>*/}
                {/*    <div style={{float:'left', width: 60}}><ProgressBar variant={className} ref={target} now={sum.weight} max={0.2}/></div>*/}
                {/*    <div style={{float:'right'}}>{sum.formattedWeight}</div>*/}
                {/*</span>*/}
            </OverlayTrigger>
        </div>;
    } else {
        return '';
    }
}
