import React from "react";
import AuthService from "../../services/auth.service";
import {Nav, Navbar, NavDropdown} from "react-bootstrap";
import {Link} from "react-router-dom";

export default function Navigation(props) {

    const logOut = () => {
        AuthService.logout();
        AuthService.resetStore();
    }

    const user = props.user;

    const isCanopiaUser = user && user.roles.includes("Canopia");
    const isCanopiaAdmin = user && user.roles.includes("Canopia-Admin");
    const isCanopiaReview = user && user.roles.includes("Canopia-Review");
    const isCanopiaSales = user && user.roles.includes("Canopia-Sales");
    const isCanopiaOAuth2 = user && user.roles.includes("Canopia-OAuth2"); // OAuth2 users cannot change their password

    return (
        <Navbar expand={'lg'} className={'bg-navbar'}>
            {/*<Container>*/}
            {isCanopiaUser ? (
                <>
                    <Link to={"/portfolio"} className={'navbar-brand'}>
                        <span className={'canopia-navbar-brand'}>Portfolio</span>
                    </Link>
                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Link to={"/docs"} className={'nav-link'}>
                                <span className={'canopia-navbar-link'}>Methodology</span>
                            </Link>
                            <Link to={"/policies"} className={'nav-link'}>
                                <span className={'canopia-navbar-link'}>Terms</span>
                            </Link>
                            {isCanopiaSales && (
                                <NavDropdown title={
                                    <span className={'canopia-navbar-link'}>Settings</span>
                                } id="basic-nav-dropdown">
                                    <NavDropdown.Item as={Link} to={"/user-client-settings"}>
                                        <span className={'canopia-navdd-link'}>Clients</span>
                                    </NavDropdown.Item>
                                </NavDropdown>
                            )}
                            {isCanopiaReview && (
                                <NavDropdown title={
                                    <span className={'canopia-navbar-link'}>Review</span>
                                } id="basic-nav-dropdown">
                                    <NavDropdown.Item as={Link} to={"/review-status"}>
                                        <span className={'canopia-navdd-link'}>Review Status</span>
                                    </NavDropdown.Item>
                                </NavDropdown>
                            )}
                            {isCanopiaAdmin && (
                                <NavDropdown title={
                                    <span className={'canopia-navbar-link'}>Admin</span>
                                } id="basic-nav-dropdown">
                                    <NavDropdown.Item as={Link} to={"/import"}>
                                        <span className={'canopia-navdd-link'}>Import</span>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to={"/setup"}>
                                        <span className={'canopia-navdd-link'}>Setup</span>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to={"/run"}>
                                        <span className={'canopia-navdd-link'}>Run</span>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to={"/reload"}>
                                        <span className={'canopia-navdd-link'}>Reload</span>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to={"/register"}>
                                        <span className={'canopia-navdd-link'}>Register</span>
                                    </NavDropdown.Item>
                                </NavDropdown>
                            )}
                        </Nav>
                        <Nav className="ml-auto">
                            {isCanopiaUser ? (
                                <NavDropdown align="end" title={
                                    <span className={'canopia-navbar-text'}>{user.firstname + ' ' + user.lastname}</span>
                                } id="basic-nav-dropdown">
                                    {!isCanopiaOAuth2 && <NavDropdown.Item as={Link} to={"/change-pwd"}>
                                        <span className={'canopia-navdd-link'}>Change Password</span>
                                    </NavDropdown.Item>}
                                    <NavDropdown.Item as={Link} to={"/logged-out"} onClick={logOut}>
                                        <span className={'canopia-navdd-link'}>Log Out</span>
                                    </NavDropdown.Item>
                                </NavDropdown>
                            ) : (
                                <Link to={"/login"} className={'nav-link'}>
                                    <span className={'canopia-navbar-link'}>Login</span>
                                </Link>
                            )}
                        </Nav>
                    </Navbar.Collapse>
                </>

            ) : (
                <Nav className="ml-auto">
                    <Link to={"/login"} className={'nav-link'}>
                        <span className={'canopia-navbar-link'}>Login</span>
                    </Link>
                </Nav>
            )}

            {/*</Container>*/}
        </Navbar>
    );
}

