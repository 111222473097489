import React, {useCallback, useLayoutEffect, useRef, useState} from "react";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4charts from "@amcharts/amcharts4/charts";
import {BM, CHART_DATA_SCALE, CHART_TYPES, getIconPath, LEGENDS, PF, TOTAL} from "../CanopiaUtils";
import {addAmcharts4Licence, getChartData} from "./ChartUtils";
import DetailSubChart from "./DetailSubChart";
import DetailHistoCO2Chart from "./DetailHistoCO2Chart";
import LabelWithTooltip from "../LabelWithTooltip";
import CO2Icon from "../CO2Icon";
import {renderToStaticMarkup} from "react-dom/server";
import {IconContext} from "react-icons";
import {AiOutlineDownload} from "react-icons/ai";
import TopPosition from "../TopPosition";
import FlatSelect from "../FlatSelect";

export default function CO2Charts(props) {

    const pfRow = props.pfRow;
    const bmRow = props.bmRow;
    const theme = props.theme;
    const subTheme = props.subTheme;
    // const subThemeValue = props.subThemeValue;
    // const clearSubThemeValue = props.clearSubThemeValue;
    const changePfKey = props.changePfKey;
    const chartType = props.chartType;
    const pfName = props.pfName;
    const bmName = props.bmName;
    const date = props.date;
    const maxDate = props.maxDate;
    const isReview = props.isReview;

    const data = props.data;
    const chartData = data.data;

    const chartDiv = "chartdiv";

    const tgtChart = useRef(null);

    const [dataScale, setDataScale] = useState(CHART_DATA_SCALE[1]);

    const subThemeValue = subTheme.valueFields[0];

    function changeDataScale(scale) {
        setDataScale(scale);
    }

    const createCurrentChart = useCallback(isReview => {
        addAmcharts4Licence();
        am4core.options.autoDispose = true;
        let chart = am4core.create(chartDiv, am4charts.XYChart);
        chart.paddingBottom = 30;

        // Get the data at the right date
        let dataAtDate = getChartData(chartData, date);

        // Transform the source data from a single object containing all sectors to a list of objects per sector
        // {
        //     date: '2022-12-31',
        //     BM_tobacco: 0.01,
        //     PF_tobacco: 0.02,
        //     BM_Alcohol: 0.001,
        // }
        //
        // {
        //      category: Tobacco,
        //      Portfolio: 0.02,
        //      BM Global: 0.01
        // }
        let newData = [];
        const dateField = "date";
        let fields = [];
        Object.keys(dataAtDate).forEach(field => {
            if (field !== dateField && (field.startsWith(PF) || field.startsWith(BM))) {
                const tgtField = field.replace(PF, '').replace(BM, '');
                if ((subThemeValue === TOTAL && tgtField !== TOTAL) || (subThemeValue !== TOTAL && tgtField === subThemeValue)) {
                    if (!fields.includes(tgtField)) {
                        fields.push(tgtField);
                    }
                }
            }
        });
        let relToBmIcon;
        fields.forEach(field => {
            let pfValue = null, bmValue = null;
            if (dataAtDate.hasOwnProperty(PF + field)) {
                pfValue = dataAtDate[PF + field];
            }
            if (dataAtDate.hasOwnProperty(BM + field)) {
                bmValue = dataAtDate[BM + field];
            }
            let pfBmRatio = null;
            if (pfValue && bmValue) {
                pfBmRatio = pfValue / bmValue - 1;
            }
            relToBmIcon = <CO2Icon value={pfBmRatio}/>;
            newData.push({
                category: field,
                pf: pfValue,
                bm: bmValue,
                // pf: pfValue ? pfValue : 0.0,
                // bm: bmValue ? bmValue : 0.0,
                // bullet: getIconPath("ALC", 'bad')
                bullet: relToBmIcon
            });
        });
        chart.data = newData;

        if (isReview) {
            chart.exporting.menu = new am4core.ExportMenu();
            chart.exporting.filePrefix = pfName + "_" + subTheme.labelForExport;
        } else {
            let options = chart.exporting.getFormatOptions("svg");
            options.quality = 1;
            chart.exporting.setFormatOptions("svg", options);
        }

        chart.legend = new am4charts.Legend();
        let legend = chart.legend;
        legend.position = 'top';
        legend.valign = 'top';
        legend.maxHeight = 150;
        legend.marginTop = 10;
        legend.marginBottom = 20;
        legend.x = 50;
        legend.itemContainers.template.paddingTop = 2;
        legend.itemContainers.template.paddingBottom = 2;
        let markerTemplate = legend.markers.template;
        markerTemplate.width = 10;
        markerTemplate.height = 10;

        // Axis
        let xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        xAxis.dataFields.category = 'category'
        // xAxis.cursorTooltipEnabled = true;
        // xAxis.tooltip.background.fill = am4core.color("#1D416D");

        xAxis.renderer.cellStartLocation = 0.1
        xAxis.renderer.cellEndLocation = 0.9
        xAxis.renderer.grid.template.location = 0;
        xAxis.renderer.labels.template.disabled = true;

        let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
        yAxis.numberFormatter.numberFormat = subTheme.formatAxis;
        yAxis.min = 0;
        yAxis.extraMax = 0.15;

        let pfSeries;
        if (pfName) {
            // PF Series
            pfSeries = chart.series.push(new am4charts.ColumnSeries());
            pfSeries.dataFields.valueY = 'pf';
            pfSeries.dataFields.categoryX = 'category';
            pfSeries.name = pfName;
            pfSeries.stroke = am4core.color(subTheme.colors[0]);
            pfSeries.columns.template.fill = pfSeries.stroke;

            let pfBullet = pfSeries.bullets.push(new am4charts.LabelBullet());
            pfBullet.interactionsEnabled = false;
            // pfBullet.dy = 30;
            pfBullet.dy = -10;
            pfBullet.label.html = "{valueY.formatNumber('" + subTheme.formatValue + "')} " + renderToStaticMarkup(relToBmIcon);
            pfBullet.label.fill = am4core.color('#1D416D');
        }

        let bmSeries;
        if (bmName) {
            // BM Series
            bmSeries = chart.series.push(new am4charts.ColumnSeries());
            bmSeries.dataFields.valueY = 'bm';
            bmSeries.dataFields.categoryX = 'category';
            bmSeries.name = bmName;
            bmSeries.stroke = am4core.color('#a0a0a0');
            bmSeries.columns.template.fill = bmSeries.stroke;

            let bmBullet = bmSeries.bullets.push(new am4charts.LabelBullet());
            bmBullet.interactionsEnabled = false;
            // bmBullet.dy = 30;
            bmBullet.dy = -10;
            bmBullet.label.text = "{valueY.formatNumber('" + subTheme.formatValue + "')}";
            // bmBullet.label.fill = am4core.color('#ffffff');
            bmBullet.label.fill = am4core.color('#53565a');
        }

        if (pfSeries && bmSeries) {

            pfSeries.events.on("hidden", arrangeColumns);
            pfSeries.events.on("shown", arrangeColumns);

            bmSeries.events.on("hidden", arrangeColumns);
            bmSeries.events.on("shown", arrangeColumns);

            function arrangeColumns() {

                let series = chart.series.getIndex(0);

                let w = 1 - xAxis.renderer.cellStartLocation - (1 - xAxis.renderer.cellEndLocation);
                if (series.dataItems.length > 1) {
                    let x0 = xAxis.getX(series.dataItems.getIndex(0), "categoryX");
                    let x1 = xAxis.getX(series.dataItems.getIndex(1), "categoryX");
                    let delta = ((x1 - x0) / chart.series.length) * w;
                    if (am4core.isNumber(delta)) {
                        let middle = chart.series.length / 2;

                        let newIndex = 0;
                        chart.series.each(function (series) {
                            if (!series.isHidden && !series.isHiding) {
                                series.dummyData = newIndex;
                                newIndex++;
                            } else {
                                series.dummyData = chart.series.indexOf(series);
                            }
                        })
                        let visibleCount = newIndex;
                        let newMiddle = visibleCount / 2;

                        chart.series.each(function (series) {
                            let trueIndex = chart.series.indexOf(series);
                            let newIndex = series.dummyData;

                            let dx = (newIndex - trueIndex + middle - newMiddle) * delta

                            series.animate({
                                property: "dx",
                                to: dx
                            }, series.interpolationDuration, series.interpolationEasing);
                            series.bulletsContainer.animate({
                                property: "dx",
                                to: dx
                            }, series.interpolationDuration, series.interpolationEasing);
                        })
                    }
                }
            }
        }

    }, [date, chartData, chartDiv, pfName, bmName, subThemeValue, subTheme]);

    const createChart = useCallback(isReview => {
        am4core.useTheme(am4themes_animated);

        createCurrentChart(isReview);
    }, [createCurrentChart]);

    useLayoutEffect(() => {
        createChart(isReview);
    }, [createChart, isReview]);

    const exportAsImage = () => {
        if (tgtChart) {
            tgtChart.current.exporting.filePrefix = pfName + "_" + subTheme.labelForExport;
            tgtChart.current.exporting.export("svg");
        }
    }

    let theChart;
    switch (chartType.key) {
        case 'detail':
            theChart = <>
                <>
                    <div className={'canopia2 detail-sub-title'}>
                        <LabelWithTooltip
                            label={<>Exposure to {subTheme.label}</>}
                            text={LEGENDS['detail.exposure.' + subTheme.key]}/>
                    </div>
                    <div style={{display: "grid", gridTemplateColumns: "1fr 30px"}}>
                        <p/>
                        {!isReview && <span className={'label-hover'} onClick={exportAsImage}>
                        <IconContext.Provider value={{className: "react-icons canopia2", size: "1.4em"}}>
                            <AiOutlineDownload/>
                        </IconContext.Provider>
                    </span>}
                    </div>
                    <div id={chartDiv} className={'chart-sector-single'}/>
                    <div style={{margin: '20px 0'}}>
                        <DetailSubChart pfRow={pfRow} bmRow={bmRow} data={data} theme={theme} subTheme={subTheme}
                                        subThemeValue={subThemeValue}
                                        dataScale={dataScale}
                                        changeDataScale={changeDataScale}
                                        changePfKey={changePfKey}
                                        pfName={pfName} bmName={bmName} date={date} maxDate={maxDate}
                                        isReview={isReview}/>
                        {/*<SectorSubBarChart pfRow={pfRow} bmRow={bmRow} data={data} subTheme={subTheme} sector={sector} dataScale={dataScale}*/}
                        {/*                   changeDataScale={changeDataScale}*/}
                        {/*                   pfName={pfName} bmName={bmName} date={date} maxDate={maxDate} isReview={isReview}/>*/}

                    </div>
                </>
                <div className={'canopia2 detail-sub-title'} style={{marginBottom: '20px'}}>
                    History
                </div>
                <DetailHistoCO2Chart pfRow={pfRow} bmRow={bmRow} data={data} subTheme={subTheme}
                                     pfName={pfName} bmName={bmName} date={date} maxDate={maxDate} isReview={isReview}/>
            </>;
            break;
        case 'topPos':
            theChart = <TopPosition pfRow={pfRow} bmRow={bmRow}
                                    subTheme={subTheme}
                                    changePfKey={changePfKey}
                                    pfName={pfName} bmName={bmName} date={date}/>;
            break;
        default:
            console.log("unknown chart type " + chartType.key);
    }

    return <>
        {theChart}
        {/*{!isReview && <span className={'label-hover'} onClick={exportAsImage} style={{position: "absolute", top: 65, right: 30}}>*/}
        {/*    <IconContext.Provider value={{className: "react-icons canopia2", size: "1.4em"}}>*/}
        {/*        <AiOutlineDownload/>*/}
        {/*    </IconContext.Provider>*/}
        {/*</span>}*/}
    </>
}
