import React, {useState} from "react";

import AuthService from "../../services/auth.service";
import {Card} from "react-bootstrap";
import {Link} from "react-router-dom";
import {useNavigate} from 'react-router-dom';
import {REQUIRED} from "../canopia/CanopiaUtils";

export default function Login(props) {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [errorName, setErrorName] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const navigate = useNavigate();

    const user = props.user;

    const redirect = () => {
        // Forward to the portfolio after a successful login
        // TODO: Back to the root, should get the from location
        // this.props.history.replace(this.state.from);
        navigate("/portfolio");
    }

    if (user) {
        redirect();
    }

    const handleLogin = (e) => {
        e.preventDefault();

        setLoading(true);
        setErrorName(null);
        setErrorMessage(null);

        let valid = true;
        [username, password].forEach(prop => {
            if (!prop || prop.trim().length === 0) {
                valid = false;
                setErrorName('Please fill in all the required fields');
            }
        });

        if (valid) {
            AuthService.login(username, password)
                .then(() => {
                        redirect();
                    },
                    error => {
                        setLoading(false);
                        processError(error);
                    }
                );
        } else {
            setLoading(false);
        }
    }

    const processError = (error) => {
        let response = error.response;

        let errorName;
        let errorMessage;
        if (response
            && response.data
            && response.data.error
            && response.data.message
        ) {
            errorName = response.data.error;
            errorMessage = response.data.message;
        } else {
            errorName = 'Service unavailable';
            errorMessage = 'We apologize for the inconvenience, our team is working on solving the issue. Please try to come back in a few minutes.\nThank you for your patience.';
            if (error.message) {
                console.log(error.message);
            }
        }

        setErrorName(errorName);
        setErrorMessage(errorMessage);
    }

    return (
        <div className="main-content">
            {props.authMessage && (
                <Card style={{width: '50rem', marginLeft: 'auto', marginRight: 'auto', marginBottom: '20px'}}>
                    <Card.Body>
                        <header className="jumbotron">
                            <h3 className='canopia'>Information</h3>
                            <p>{props.authMessage}</p>
                        </header>
                    </Card.Body>
                </Card>
            )}
            <Card style={{width: '30rem', margin: 'auto'}}>
                {/*<Card.Img variant="top" src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"/>*/}
                <Card.Body>
                    <Card.Title className='canopia'>Login</Card.Title>
                    <form onSubmit={handleLogin}>
                        <div className="form-group">
                            <label htmlFor="username">E-mail</label>
                            <input
                                type="text"
                                className="form-control"
                                name="username"
                                value={username}
                                placeholder={REQUIRED}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </div>
                        <br/>

                        <div className="form-group">
                            <label htmlFor="password">Password</label>
                            <input
                                type="password"
                                className="form-control"
                                name="password"
                                value={password}
                                placeholder={REQUIRED}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <br/>

                        <div className="form-group">
                            <Link to={'/request-reset-pwd'} className={'std-link'}>
                                <span>Forgot password?</span>
                            </Link>
                        </div>
                        <br/>

                        <div className="form-group">
                            <button className="btn btn-primary btn-block" disabled={loading}>
                                {loading && (
                                    <span className="spinner-border spinner-border-sm" style={{marginRight: '5px'}}/>
                                )}
                                <span>Login</span>
                            </button>
                        </div>

                        {errorName && (
                            <>
                                <br/>
                                <div className={"alert alert-danger"} role="alert">
                                    <h3 className='canopia'>{errorName}</h3>
                                    <p>{errorMessage}</p>
                                </div>
                            </>
                        )}
                    </form>
                </Card.Body>
            </Card>
        </div>
    );
}
