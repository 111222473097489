import React, {useCallback, useLayoutEffect, useRef} from "react";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4charts from "@amcharts/amcharts4/charts";
import {AiOutlineDownload} from "react-icons/ai";
import {IconContext} from "react-icons";
import {BM, PF} from "../CanopiaUtils";
import {addAmcharts4Licence} from "./ChartUtils";

export default function DetailHistoChart(props) {

    // const pfRow = props.pfRow;
    // const bmRow = props.bmRow;
    const theme = props.theme;
    const subTheme = props.subTheme;
    const pfName = props.pfName;
    const bmName = props.bmName;
    const maxDate = props.maxDate;
    // const date = props.date;
    const subThemeValue = props.subThemeValue;
    const isReview = props.isReview;

    const data = props.data;
    const chartData = data.data;

    const chartDiv = "sector_single_histo_chart_div";

    const tgtChart = useRef(null);

    const tooltipTextFormat = "{name}: {valueY.formatNumber('" + subTheme.formatValue + "')}";

    const createHistoChart = useCallback(isReview => {
        addAmcharts4Licence();
        am4core.options.autoDispose = true;

        // https://www.amcharts.com/demos-v4/vertically-stacked-axes-chart-v4/
        // https://www.amcharts.com/demos-v4/range-chart-with-different-fill-colors-v4/
        // https://www.amcharts.com/docs/v4/tutorials/titles-on-top-of-vertical-value-axis/
        let chart = am4core.create(chartDiv, am4charts.XYChart);
        chart.marginBottom = 0;
        // the following line makes value axes to be arranged vertically.
        chart.leftAxesContainer.layout = "vertical";
        // Set input format for the dates
        chart.dateFormatter.inputDateFormat = "yyyy-MM-dd";

        if (isReview) {
            chart.exporting.menu = new am4core.ExportMenu();
            chart.exporting.filePrefix = pfName + "_" + subTheme.labelForExport;
        } else {
            let options = chart.exporting.getFormatOptions("svg");
            options.quality = 1;
            chart.exporting.setFormatOptions("svg", options);
        }

        // Cursor
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.behavior = 'zoomX';
        chart.cursor.lineX = null;
        chart.cursor.lineY = null;

        chart.legend = new am4charts.Legend();
        let legend = chart.legend;
        legend.position = 'bottom';
        legend.valign = 'top';
        legend.maxHeight = 150;
        legend.marginTop = 10;
        legend.marginBottom = 20;
        legend.itemContainers.template.paddingTop = 2;
        legend.itemContainers.template.paddingBottom = 2;
        let markerTemplate = legend.markers.template;
        markerTemplate.width = 10;
        markerTemplate.height = 10;

        // Axis
        let xAxis = chart.xAxes.push(new am4charts.DateAxis());
        xAxis.cursorTooltipEnabled = true;
        xAxis.tooltip.background.fill = am4core.color("#1D416D");
        xAxis.tooltipDateFormat = "yyyy-MM-dd";
        xAxis.baseInterval = {timeUnit: "day", count: 1};
        xAxis.extraMin = 0.01; // Give some margin between the series and the left part of the tgtChart box
        if (maxDate) {
            xAxis.max = maxDate;
        }
        // xAxis.dateFormats.setKey("month", "MMM");
        // xAxis.periodChangeDateFormats.setKey("month", "YYYY"); // Remove the month label when changing year "Jan 2022" -> "2022"

        // xAxis.renderer.minGridDistance = 350;
        xAxis.gridIntervals.setAll([
            {timeUnit: "year", count: 1},
            {timeUnit: "year", count: 2},
            {timeUnit: "year", count: 5},
            {timeUnit: "year", count: 10},
            {timeUnit: "year", count: 50},
            {timeUnit: "year", count: 100}
        ]);
        xAxis.renderer.grid.template.disabled = false;
        // xAxis.renderer.cellStartLocation = 0;

        xAxis.renderer.ticks.template.disabled = false;
        xAxis.renderer.ticks.template.strokeOpacity = 1;
        xAxis.renderer.ticks.template.stroke = am4core.color("#bbbbbb");
        xAxis.renderer.ticks.template.strokeWidth = 1;
        xAxis.renderer.ticks.template.length = 5;

        const dateField = "date";
        // Get all fields
        let pfBmFields = [PF + subThemeValue]; // pf + bm prefixed
        if (bmName) {
            pfBmFields.push(BM + subThemeValue);
        }

        // Fill in the blanks to avoid uncontrolled behaviour
        let fillNullWithZero = true;

        chartData.forEach(dataPoint => {
            if (theme.key === 'netZero') {
                // Net zero data starts either on 2022-09-30 or 2022-12-30
                const date = dataPoint[dateField];

                if (date < '2022-12-30' && Object.keys(dataPoint).length <= 3) {
                    // Missing data on 2022-09-30 (3 props => date only or date + pf with 100% NA + bm with 100% NA)
                    // => don't fill with 0s and clear the props
                    fillNullWithZero = false;
                    pfBmFields.forEach(field => {
                        if (dataPoint.hasOwnProperty(field)) {
                            delete dataPoint[field];
                        }
                    });
                } else {
                    fillNullWithZero = true;
                }
            }
            if (fillNullWithZero) {
                pfBmFields.forEach(field => {
                    if (!dataPoint.hasOwnProperty(field)) {
                        dataPoint[field] = 0;
                    }
                });
            }
        });
        chart.data = chartData;

        // let axisIndex = 1;
        // const nbAxis = fields.length - 1;
        let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
        // yAxis.title.text = yAxisName;
        yAxis.cursorTooltipEnabled = false;
        yAxis.numberFormatter.numberFormat = subTheme.formatAxis; // "#'###" single quote not supported
        yAxis.extraMax = 0.01; // Give some margin between the series and the top of the tgtChart box
        yAxis.min = 0;
        // yAxis.renderer.gridContainer.background.fill = am4core.color('#1D416D');
        // yAxis.renderer.gridContainer.background.fillOpacity = 0.05;
        // yAxis.renderer.minGridDistance = 50;
        // yAxis.title.text = subThemeValue;

        let pfSeries = chart.series.push(new am4charts.LineSeries());
        pfSeries.name = pfName;
        pfSeries.dataFields.valueY = PF + subThemeValue;
        if (bmName) {
            pfSeries.dataFields.openValueY = BM + subThemeValue;
        }
        pfSeries.dataFields.dateX = dateField;
        pfSeries.yAxis = yAxis;
        pfSeries.stroke = am4core.color(subTheme.colors[0]);
        pfSeries.strokeWidth = 2;
        pfSeries.tooltipText = tooltipTextFormat;
        pfSeries.tooltip.getFillFromObject = false;
        pfSeries.tooltip.background.fill = pfSeries.stroke;

        let pfBullet = pfSeries.bullets.push(new am4charts.CircleBullet());
        pfBullet.circle.fill = am4core.color("#fff");
        pfBullet.circle.radius = 2;
        pfBullet.circle.strokeWidth = 2;

        // Makes the bullet bigger on mouse over
        let pfState = pfBullet.states.create("hover");
        pfState.properties.scale = 1.2;

        if (bmName) {
            let bmSeries = chart.series.push(new am4charts.LineSeries());
            bmSeries.name = bmName;
            bmSeries.dataFields.valueY = BM + subThemeValue;
            bmSeries.dataFields.dateX = dateField;
            bmSeries.yAxis = yAxis;
            bmSeries.stroke = am4core.color('#a0a0a0');
            bmSeries.strokeWidth = 2;
            bmSeries.tooltipText = tooltipTextFormat;
            bmSeries.tooltip.getFillFromObject = false;
            bmSeries.tooltip.background.fill = bmSeries.stroke;

            let bmBullet = bmSeries.bullets.push(new am4charts.CircleBullet());
            bmBullet.circle.fill = am4core.color("#fff");
            bmBullet.circle.radius = 2;
            bmBullet.circle.strokeWidth = 2;

            // Makes the bullet bigger on mouse over
            let bmState = bmBullet.states.create("hover");
            bmState.properties.scale = 1.2;

            // create ranges
            chart.events.on("datavalidated", function () {
                let negativeRange;
                const series = pfSeries;
                const series2 = bmSeries;
                if (!series || !series2) {
                    return;
                }
                series.dataItems.each(function (s1DataItem) {
                    let s1PreviousDataItem;
                    let s2PreviousDataItem;

                    const s1Index = s1DataItem.index;
                    let s2DataItem = series2.dataItems.getIndex(s1Index);

                    if (s1Index > 0) {
                        s1PreviousDataItem = series.dataItems.getIndex(s1Index - 1);
                        s2PreviousDataItem = series2.dataItems.getIndex(s1Index - 1);
                    }

                    let startTime = am4core.time.round(new Date(s1DataItem.dateX.getTime()), xAxis.baseInterval.timeUnit, xAxis.baseInterval.count).getTime();

                    // intersections
                    if (s1PreviousDataItem && s2PreviousDataItem) {
                        let x0 = am4core.time.round(new Date(s1PreviousDataItem.dateX.getTime()), xAxis.baseInterval.timeUnit, xAxis.baseInterval.count).getTime() + xAxis.baseDuration / 2;
                        let y01 = s1PreviousDataItem.valueY;
                        let y02 = s2PreviousDataItem.valueY;

                        let x1 = startTime + xAxis.baseDuration / 2;
                        let y11 = s1DataItem.valueY;
                        let y12 = s2DataItem.valueY;

                        let intersection = am4core.math.getLineIntersection({x: x0, y: y01}, {x: x1, y: y11}, {
                            x: x0,
                            y: y02
                        }, {x: x1, y: y12});

                        startTime = Math.round(intersection.x);
                    }

                    // start range here
                    if (s1DataItem.valueY > s2DataItem.valueY) {
                        if (!negativeRange) {
                            negativeRange = xAxis.createSeriesRange(series);
                            negativeRange.date = new Date(startTime);
                            negativeRange.contents.fill = series.stroke;
                            negativeRange.contents.fillOpacity = 0.3;
                        }
                    } else {
                        // if negative range started
                        if (negativeRange) {
                            negativeRange.endDate = new Date(startTime);
                        }
                        negativeRange = undefined;
                    }
                    // end if last
                    if (s1Index === series.dataItems.length - 1) {
                        if (negativeRange) {
                            negativeRange.endDate = new Date(s1DataItem.dateX.getTime() + xAxis.baseDuration / 2);
                            negativeRange = undefined;
                        }
                    }
                });

            });
        }

        tgtChart.current = chart;

    }, [chartData, chartDiv, pfName, bmName, maxDate, theme.key, subThemeValue, subTheme, tooltipTextFormat]);

    const createChart = useCallback(isReview => {
        am4core.useTheme(am4themes_animated);

        createHistoChart(isReview);
    }, [createHistoChart]);

    useLayoutEffect(() => {
        createChart(isReview);
    }, [createChart, isReview]);

    const exportAsImage = () => {
        if (tgtChart) {
            tgtChart.current.exporting.filePrefix = pfName + "_" + subTheme.labelForExport;
            tgtChart.current.exporting.export("svg");
        }
    }

    return <>
        <div style={{display: "grid", gridTemplateColumns: "1fr 30px"}}>
            <p className={'canopia2'} style={{margin: '0 0 10px 10px'}}>
            </p>
            {!isReview && <span className={'label-hover'} onClick={exportAsImage}>
                <IconContext.Provider value={{className: "react-icons canopia2", size: "1.4em"}}>
                    <AiOutlineDownload/>
                </IconContext.Provider>
            </span>}
        </div>
        <div id={chartDiv} className="chart-sector-single-histo"/>
    </>
}
