import React, {useCallback, useLayoutEffect, useRef} from "react";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4charts from "@amcharts/amcharts4/charts";
import {AiOutlineDownload} from "react-icons/ai";
import {IconContext} from "react-icons";
import {BM, getGradeFromRank, LEGENDS, PF, THEMES} from "../CanopiaUtils";
import {renderToStaticMarkup} from "react-dom/server";
import ConsensusGrade from "../ConsensusGrade";
import FinalGrade from "../FinalGrade";
import {addAmcharts4Licence} from "./ChartUtils";
import LabelWithTooltip from "../LabelWithTooltip";

export default function DetailHistoCoverageChart(props) {

    // const dataType = props.dataType;
    // const theme = props.theme;
    const subTheme = props.subTheme;
    const categoryField = props.categoryField;
    const pfName = props.pfName;
    const bmName = props.bmName;
    const maxDate = props.maxDate;

    const data = props.data;
    const chartData = data.data;
    const isReview = props.isReview;
    // const chartConfig = data.config;

    const chartDiv = "chart_histo_cov_div";

    const tgtChart = useRef(null);

    const createChartInternal = useCallback(isReview => {
            addAmcharts4Licence();
            am4core.options.autoDispose = true;
            let chart = am4core.create(chartDiv, am4charts.XYChart);
            chart.marginTop = 30;
            chart.marginBottom = 30;
            chart.data = chartData;
            // Set input format for the dates
            chart.dateFormatter.inputDateFormat = "yyyy-MM-dd";

            if (isReview) {
                chart.exporting.menu = new am4core.ExportMenu();
                chart.exporting.filePrefix = pfName + "_" + subTheme.labelForExport;
            } else {
                let options = chart.exporting.getFormatOptions("svg");
                options.quality = 1;
                chart.exporting.setFormatOptions("svg", options);
            }

            // Cursor
            chart.cursor = new am4charts.XYCursor();
            chart.cursor.behavior = 'zoomX';
            chart.cursor.lineX = null;
            chart.cursor.lineY = null;

            chart.legend = new am4charts.Legend();
            let legend = chart.legend;
            legend.reverseOrder = true;
            let markerTemplate = legend.markers.template;
            markerTemplate.width = 40;
            markerTemplate.height = 40;

            // Axis
            let xAxis = chart.xAxes.push(new am4charts.DateAxis());
            xAxis.cursorTooltipEnabled = true;
            xAxis.tooltip.background.fill = am4core.color("#1D416D");
            xAxis.tooltipDateFormat = "yyyy-MM-dd";
            xAxis.baseInterval = {timeUnit: "day", count: 1};
            // xAxis.renderer.labels.template.location = 0.0001;
            // xAxis.extraMax = 0.1; // Give some margin between the seriesConfig and the right part of the chart box
            xAxis.extraMin = 0.05; // Give some margin for the 1st grade bullet
            if (maxDate) {
                xAxis.max = maxDate;
            }
            xAxis.gridIntervals.setAll([
                {timeUnit: "year", count: 1},
                {timeUnit: "year", count: 2},
                {timeUnit: "year", count: 5},
                {timeUnit: "year", count: 10},
                {timeUnit: "year", count: 50},
                {timeUnit: "year", count: 100}
            ]);
            xAxis.renderer.grid.template.disabled = false;
            // xAxis.renderer.cellStartLocation = 0;

            xAxis.renderer.ticks.template.disabled = false;
            xAxis.renderer.ticks.template.strokeOpacity = 1;
            xAxis.renderer.ticks.template.stroke = am4core.color("#bbbbbb");
            xAxis.renderer.ticks.template.strokeWidth = 1;
            xAxis.renderer.ticks.template.length = 5;
            xAxis.renderer.line.strokeOpacity = 1;
            xAxis.renderer.line.strokeWidth = 1;
            xAxis.renderer.line.stroke = am4core.color("#bbbbbb");

            // start with the BM to have the PF over the BM. this requires the legend to be in the reversed order
            const seriesConfig = [];
            if (bmName) {
                seriesConfig.push({
                    prefix: BM,
                    name: bmName,
                    color: "#a0a0a0",
                    bulletSize: 6,
                    bulletOpacity: 0.8,
                    offset: 0
                });
            }
            seriesConfig.push({
                prefix: PF,
                name: pfName,
                color: "#1D416D",
                bulletSize: 6,
                bulletOpacity: 0.8,
                offset: 0
            });

            // only way to get the right colors in the legend
            chart.colors.list = [
                // am4core.color("#1D416D"),
                // am4core.color("#a0a0a0"),
                am4core.color("#fff"),
                am4core.color("#fff")
            ];

            const dateField = "date";
            // Fill in the blanks to avoid uncontrolled behaviour
            chartData.forEach(dataPoint => {
                seriesConfig.forEach(config => {
                    if (!dataPoint.hasOwnProperty(config.prefix + categoryField)) {
                        dataPoint[config.prefix + categoryField] = null;
                    }
                });
            });

            // Show the coverage chart
            let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
            yAxis.numberFormatter.numberFormat = subTheme.formatAxis; // "#'###" single quote not supported
            yAxis.min = 0;

            seriesConfig.forEach(config => {
                let series = chart.series.push(new am4charts.LineSeries());
                series.name = config.name;
                series.dataFields.valueY = config.prefix + categoryField;
                series.dataFields.dateX = dateField;
                series.stroke = am4core.color(config.color);
                series.strokeWidth = 2;
                series.strokeOpacity = 0.5;
                series.yAxis = yAxis;
                series.tooltipText = "{name}: {valueY.formatNumber('" + subTheme.formatValue + "')}";
                series.tooltip.getFillFromObject = false;
                series.tooltip.background.fill = series.stroke;

                let bullet = series.bullets.push(new am4charts.CircleBullet());
                bullet.circle.fill = am4core.color("#fff");
                bullet.circle.radius = 2;
                bullet.circle.strokeWidth = 2;

                // Makes the bullet bigger on mouse over
                let state = bullet.states.create("hover");
                state.properties.scale = 1.2;
            });

            tgtChart.current = chart;

        }, [chartData, chartDiv, pfName, bmName, maxDate, subTheme]
    );

    useLayoutEffect(() => {
        am4core.useTheme(am4themes_animated);

        createChartInternal(isReview);
    }, [createChartInternal, isReview]);

    const exportAsImage = () => {
        if (tgtChart) {
            tgtChart.current.exporting.filePrefix = pfName + "_" + subTheme.labelForExport;
            tgtChart.current.exporting.export("svg");
        }
    }

    return <>
        <div className={'canopia2 detail-sub-title'}>
            <LabelWithTooltip label={'Coverage history'} text={LEGENDS['detail.coverageHistory']}/>
        </div>
        <div id={chartDiv} className="chart-sector-single-histo"/>
    </>
}
