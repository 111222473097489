import React from "react";
import ConsensusIndicator from "./ConsensusIndicator";
import {AMOUNT_CONTROLS, getAboveBelowColor, getAmountValueForFunds, REAL_ESTATE_THEMES} from "./CanopiaUtils";
import {useSelector} from "react-redux";
import {selectPortfolioState} from "../../reducer/portfolioSlice";
import NameTrunc from "./NameTrunc";

export default function RealEstateRow(props) {

    let pfRow = props.row;
    let theme = props.theme;

    const themes = REAL_ESTATE_THEMES;

    const borderLeftClassName = 'canopia-border-left';

    const name = pfRow.name;
    const nameTrunc = <NameTrunc name={name} size={40}/>;

    const {selectedAmountField} = useSelector(selectPortfolioState);

    const adjAmountField = selectedAmountField.key === 'relPct' ? AMOUNT_CONTROLS[1] : selectedAmountField;
    let amountValue = getAmountValueForFunds(pfRow, adjAmountField);

    let themeValues;

    function showInd(value) {
        return value && value.trim() !== "" && value !== 'NR';
    }

    if ((!pfRow)) {
        themeValues = <td className={borderLeftClassName} colSpan={theme.cols}></td>;
    } else {
        switch (theme.key) {
            case themes[0].key:
                let ratingCO2Perf = "";
                let ratingHeatingPerf = "";
                let ratingObs = "";
                let ratingCO2Needed2030 = "";
                let ratingCO2Planned2030 = "";
                let ratingCO2Planned2050 = "";
                let showRatings = false;

                if (showInd(pfRow.ratingCO2Perf)) {
                    // Grade
                    // let gradeIconPath = getIconPath(pfRow.ratingCO2Total);
                    // ratingCO2Total = <img alt={pfRow.ratingCO2Total} src={gradeIconPath} width={ICON_SIZE + 4} height={ICON_SIZE + 4} style={{marginRight: 4}}/>;
                    // let label = getAboveBelow(pfRow.ratingCO2Total);
                    let color = getAboveBelowColor(pfRow.ratingCO2Perf);
                    ratingCO2Perf = <ConsensusIndicator label={" "} color={color}/>;
                    showRatings = true;
                }
                if (showInd(pfRow.ratingHeatingPerf)) {
                    // Grade
                    // let gradeIconPath = getIconPath(pfRow.ratingStateOfRepair);
                    // ratingStateOfRepair = <img alt={pfRow.ratingStateOfRepair} src={gradeIconPath} width={ICON_SIZE + 4} height={ICON_SIZE + 4} style={{marginRight: 4}}/>;
                    // let label = getAboveBelow(pfRow.ratingStateOfRepair);
                    let color = getAboveBelowColor(pfRow.ratingHeatingPerf);
                    ratingHeatingPerf = <ConsensusIndicator label={" "} color={color}/>;
                    showRatings = true;
                }
                if (showInd(pfRow.ratingObs)) {
                    // Grade
                    // let gradeIconPath = getIconPath(pfRow.ratingCO2Objective);
                    // ratingCO2Objective = <img alt={pfRow.ratingCO2Objective} src={gradeIconPath} width={ICON_SIZE + 4} height={ICON_SIZE + 4} style={{marginRight: 4}}/>;
                    // let label = getAboveBelow(pfRow.ratingCO2Objective);
                    let color = getAboveBelowColor(pfRow.ratingObs);
                    ratingObs = <ConsensusIndicator label={" "} color={color}/>;
                    showRatings = true;
                }
                if (showInd(pfRow.ratingCO2Needed2030)) {
                    let color = getAboveBelowColor(pfRow.ratingCO2Needed2030);
                    ratingCO2Needed2030 = <ConsensusIndicator label={" "} color={color}/>;
                    showRatings = true;
                }
                if (showInd(pfRow.ratingCO2Planned2030)) {
                    let color = getAboveBelowColor(pfRow.ratingCO2Planned2030);
                    ratingCO2Planned2030 = <ConsensusIndicator label={" "} color={color}/>;
                    showRatings = true;
                }
                // if (pfRow.ratingProjectedWorks && pfRow.ratingProjectedWorks.trim() !== "") {
                //     // Grade
                //     let gradeIconPath = getIconPath(pfRow.ratingProjectedWorks);
                //     ratingProjectedWorks = <img alt={pfRow.ratingProjectedWorks} src={gradeIconPath} width={ICON_SIZE + 4} height={ICON_SIZE + 4} style={{marginRight: 4}}/>;
                // }
                if (showInd(pfRow.ratingCO2Planned2050)) {
                    let color = getAboveBelowColor(pfRow.ratingCO2Planned2050);
                    ratingCO2Planned2050 = <ConsensusIndicator label={" "} color={color}/>;
                    showRatings = true;
                }
                themeValues = showRatings ? <>
                    <td className={borderLeftClassName}>{ratingCO2Perf}</td>
                    <td>{ratingHeatingPerf}</td>
                    <td>{ratingObs}</td>
                    <td className={borderLeftClassName}>{ratingCO2Needed2030}</td>
                    <td>{ratingCO2Planned2030}</td>
                    <td className={borderLeftClassName}>{ratingCO2Planned2050}</td>
                </> : <>
                    <td className={borderLeftClassName} colSpan={3}/>
                    <td className={borderLeftClassName} colSpan={2}/>
                    <td className={borderLeftClassName} colSpan={1}/>
                </>
                break;
            case themes[1].key:
                // let ratingCO2Alignment = "";
                // let ratingCO2FutureEmissions = "";
                // if (pfRow.ratingCO2Alignment && pfRow.ratingCO2Alignment.trim() !== "") {
                // Grade
                // let gradeIconPath = getIconPath(pfRow.ratingCO2Alignment);
                // ratingCO2Alignment = <img alt={pfRow.ratingCO2Alignment} src={gradeIconPath} width={ICON_SIZE + 4} height={ICON_SIZE + 4} style={{marginRight: 4}}/>;
                // let label = getAboveBelow(pfRow.ratingCO2Alignment);
                // let color = getAboveBelowColor(pfRow.ratingCO2Alignment);
                // ratingCO2Alignment = <ConsensusIndicator label={" "} color={color}/>
                // }
                // if (pfRow.ratingCO2FutureEmissions && pfRow.ratingCO2FutureEmissions.trim() !== "") {
                // Grade
                // let gradeIconPath = getIconPath(pfRow.ratingCO2FutureEmissions);
                // ratingCO2FutureEmissions = <img alt={pfRow.ratingCO2FutureEmissions} src={gradeIconPath} width={ICON_SIZE + 4} height={ICON_SIZE + 4} style={{marginRight: 4}}/>;
                // let label = getAboveBelow(pfRow.ratingCO2FutureEmissions);
                // let color = getAboveBelowColor(pfRow.ratingCO2FutureEmissions);
                // ratingCO2FutureEmissions = <ConsensusIndicator label={" "} color={color}/>
                // }
                themeValues = <>
                    <td className={borderLeftClassName} align={"right"}>{pfRow.co2IDC && pfRow.co2IDC > 0 ? pfRow.co2IDCFmt : ''}</td>
                    <td align={"right"}>{pfRow.totalCO2 && pfRow.totalCO2 > 0 ? pfRow.totalCO2Fmt : ''}</td>
                    <td align={"right"}>{pfRow.totalCO2BySREFmt}</td>
                    <td align={"right"}>{pfRow.totalCO2ByRevenuesFmt}</td>
                    <td className={borderLeftClassName} align={"right"}>{pfRow.co2Reduction && pfRow.co2Reduction >= 0 ? "On target" : pfRow.co2ReductionFmt}</td>
                    <td align={"right"}>{pfRow.co2BySRETgt2030Fmt}</td>
                    <td className={borderLeftClassName} align={"right"}>{pfRow.co2YearlyExcessFmt}</td>
                    <td align={"right"}>{pfRow.co2ExcessTotalFmt}</td>
                </>

                break;
            case themes[2].key:
                themeValues = <>
                    <td className={borderLeftClassName} align={"right"}>{pfRow.buildingId}</td>
                    <td align={"right"}>{pfRow.zip}</td>
                    <td>
                        <span className={"nowrap"}>{pfRow.type}</span>
                    </td>
                    <td>
                        <span className={"nowrap"}>{pfRow.primaryEnergy}</span>
                    </td>
                    <td>
                        <span className={"nowrap"}>{pfRow.label}</span>
                    </td>
                    {/*<td align={"right"}>{pfRow.refYear}</td>*/}
                </>
                break;
            default:
            // console.log('unknown theme key ' + theme.key);
        }
    }

    return <tr key={name}>
        <td colSpan={2}>
            {nameTrunc}
        </td>
        <td>
            <span className={"nowrap"}>{pfRow.city}</span>
        </td>
        <td>
            <span className={"nowrap"}>{pfRow.canton}</span>
        </td>
        <td align={"right"}>{pfRow.sreFmt}</td>
        <td align="right">{amountValue}</td>
        <td><ConsensusIndicator label={pfRow.consensusLabel} color={pfRow.consensusColor}/></td>

        {themeValues}
    </tr>
}
