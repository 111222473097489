import React, {useEffect} from "react";
import Table from "react-bootstrap/Table";
import {useDispatch, useSelector} from 'react-redux';
import {changeSelectedAmountField, selectPortfolioState} from '../../reducer/portfolioSlice';
import THSort from "./THSort";
import {AMOUNT_CONTROLS, getAmountControlsForFunds} from "./CanopiaUtils";
import FlatSelect from "./FlatSelect";
import DirectLineRow from "./DirectLineRow";
import {selectClientState} from "../../reducer/clientSlice";
import THFilter from "./THFilter";
import {setDirectLines, clearAllDirectLinesFilters, selectDirectLinesState, filterDirectLines} from "../../reducer/directLinesSlice";
import FilterResult from "./FilterResult";

export default function DirectLines(props) {

    // const searchCategoryInput = props.searchInput;
    const dateSelect = props.dateSelect;

    const dispatch = useDispatch();

    const {clientConfig} = useSelector(selectClientState);
    const {pfData, date, view, selectedAmountField} = useSelector(selectPortfolioState);
    const {tgtDataOrig, tgtData} = useSelector(selectDirectLinesState);

    useEffect(() => {
        if (pfData) {
            dispatch(setDirectLines({pfData, date}));
        }
    }, [pfData, date, view, dispatch]);

    const pfBgClass = 'background-light';
    const borderLeftClassName = 'canopia-border-left';

    const amountFields = getAmountControlsForFunds(clientConfig.showAmounts, clientConfig.clientWrapper.currency);

    // Relative % is not relevant in Funds tab
    const adjAmountField = selectedAmountField.key === 'relPct' ? AMOUNT_CONTROLS[1] : selectedAmountField;

    // Amount data
    function changeAmountField(field) {
        dispatch(changeSelectedAmountField({amountField: field}));
    }

    let amountControlDiv = amountFields.length > 1 ?
        <FlatSelect enums={amountFields} curValue={adjAmountField} curClass={'canopia-nav-link-active'} otherClass={'canopia-nav-link'}
                    clickFunction={changeAmountField} clickParamFromEnum={true}/>
        : amountFields[0].label;

    let colorClass = 'canopia2';

    const targetSort = "directLines";

    let rows;
    let displayed = 0;
    let message;
    if (tgtData && tgtData.length > 0) {
        displayed = tgtData.length;

        let rowId = 0;
        rows = tgtData.map(pfRow => {
            return <DirectLineRow key={rowId++} row={pfRow}/>
        });
    } else {
        message = <div style={{width: '100%', padding: '10px 0 0 10px'}}><b>No data found</b></div>;
    }

    const total = tgtDataOrig ? tgtDataOrig.length : 0;

    const showClearAllFilters = displayed !== total;

    function clearAllFilters() {
        dispatch(clearAllDirectLinesFilters());
    }

    return (
        <div>
            <Table hover className={'portfolio sticky-thc'}>
                <thead>
                <tr>
                    <th className={colorClass + " " + pfBgClass} style={{width: '100px'}}>
                        Holdings
                    </th>
                    <th className={colorClass + " " + pfBgClass + " form-padding"} colSpan={4}>
                        <span style={{float: 'left'}}>{dateSelect}</span>
                    </th>
                    <th className={colorClass + " " + pfBgClass + " form-padding"} colSpan={2}>
                        <FilterResult showClearButton={showClearAllFilters}
                                      clearFunc={clearAllFilters}
                                      displayed={displayed}
                                      total={total}>
                        </FilterResult>
                    </th>
                    <th className={colorClass + " " + pfBgClass + " " + borderLeftClassName}>ESG Review</th>
                    <th className={colorClass + " " + pfBgClass + " " + borderLeftClassName} colSpan={2}>Controversies</th>
                    <th className={colorClass + " " + pfBgClass + " " + borderLeftClassName} colSpan={2}>Impact</th>
                    {clientConfig.showNetZeroData && <th className={colorClass + " " + pfBgClass + " " + borderLeftClassName}>Net Zero</th>}
                </tr>
                <tr>
                    <th className={colorClass + " " + pfBgClass} colSpan={2}>
                        <THSort target={targetSort} colName="name" label="Name"/>
                    </th>
                    <th className={colorClass + " " + pfBgClass}>
                        <THSort target={targetSort} colName="isin" label="ISIN"/>
                    </th>
                    <th className={colorClass + " " + pfBgClass}>
                        <THFilter targetSort={targetSort} sortCol="type" filterCol="type" label="Rating Type" showLegend={true} selectFunc={selectDirectLinesState}
                                  filterFunc={filterDirectLines}/>
                    </th>
                    <th className={colorClass + " " + pfBgClass}>
                        <THSort target={targetSort} colName="issuer" label="Issuer" showLegend={true}/>
                    </th>
                    <th className={colorClass + " " + pfBgClass}>
                        <THFilter targetSort={targetSort} sortCol="leafCategory" filterCol="leafCategory" label="Category" selectFunc={selectDirectLinesState}
                                  filterFunc={filterDirectLines}/>
                    </th>
                    <th className={colorClass + " " + pfBgClass}>
                        <span className={'float-right'}>
                            <THSort target={targetSort} colName="weight" label={amountControlDiv}/>
                        </span>
                    </th>
                    <th className={colorClass + " " + pfBgClass + " " + borderLeftClassName}>
                        <THFilter targetSort={targetSort} sortCol="consensusRank" filterCol="consensusLabel" label="ESG Consensus" showLegend={true} selectFunc={selectDirectLinesState}
                                  filterFunc={filterDirectLines}/>
                    </th>
                    <th className={colorClass + " " + pfBgClass + " " + borderLeftClassName}>
                        <THSort target={targetSort} colName="sectors" label="Sensitive sectors" showLegend={true}/>
                    </th>
                    <th className={colorClass + " " + pfBgClass}>
                        <THSort target={targetSort} colName="norms" label="Intl. norms" showLegend={true}/>
                    </th>
                    <th className={colorClass + " " + pfBgClass + " " + borderLeftClassName}>
                        <THSort target={targetSort} colName="impacts" label="Climate" showLegend={true}/>
                    </th>
                    <th className={colorClass + " " + pfBgClass}>
                        <THSort target={targetSort} colName="thematicBonds" label="Thematic Bds" showLegend={true}/>
                    </th>
                    {clientConfig.showNetZeroData && <th className={colorClass + " " + pfBgClass + " " + borderLeftClassName}>
                        <THSort target={targetSort} colName="netZero.rank" label="Alignment" showLegend={true}/>
                    </th>}
                </tr>
                </thead>
                <tbody>
                {rows}
                </tbody>
            </Table>
            {message}
        </div>
    );
}
