import React, {useCallback, useLayoutEffect, useRef} from "react";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4charts from "@amcharts/amcharts4/charts";
import {BM, getIconPath, LEGENDS, PF, PF_KEY_SEPARATOR, TOTAL} from "../CanopiaUtils";
import {addAmcharts4Licence, createCO2SubChartData, createESGMetricsSubChartData} from "./ChartUtils";
import * as am4plugins_sliceGrouper from "@amcharts/amcharts4/plugins/sliceGrouper";
import LabelWithTooltip from "../LabelWithTooltip";
import {IconContext} from "react-icons";
import {AiOutlineDownload} from "react-icons/ai";

export default function DetailSubChart(props) {

    const pfRow = props.pfRow;
    const theme = props.theme;
    const subTheme = props.subTheme;
    const pfName = props.pfName;
    const subThemeValue = props.subThemeValue;
    const isReview = props.isReview;

    const dataScale = props.dataScale;

    const changePfKey = props.changePfKey;

    const themeField = subTheme.valueFields[0];
    const tgtData = theme.key === 'co2' ?
        createCO2SubChartData(themeField, subThemeValue, pfRow, dataScale) :
        createESGMetricsSubChartData(themeField, subThemeValue, pfRow, dataScale);

    const NB_SLICES_MAX = 6;

    const chartDiv = "chart-sub-pie-div";

    const tgtChart = useRef(null);

    const createSubPieChart = useCallback(isReview => {
        addAmcharts4Licence();
        am4core.options.autoDispose = true;
        let chartSub = am4core.create(chartDiv, am4charts.PieChart);

        // Transform the source data from a single object containing all sectors to a list of objects per subThemeValue
        // {
        //     date: '2022-12-31',
        //     pf_Equities: 0.02,
        //     BM_Equities: 0.01,
        //     PF_Bonds: 0.001,
        //     BM_Bonds: 0.001
        // }
        //
        // {
        //      category: Equities,
        //      pf: 0.02,
        //      bm: 0.01
        // }
        let newData = [];
        const dateField = "date";
        let fields = [];
        Object.keys(tgtData).forEach(field => {
            if (field !== dateField) {
                const tgtField = field.replace(PF, '').replace(BM, '');
                if (!fields.includes(tgtField)) {
                    fields.push(tgtField);
                }
            }
        });
        fields.forEach(field => {
            newData.push({
                category: field,
                pf: tgtData.hasOwnProperty(PF + field) ? tgtData[PF + field] : 0.0,
                bm: tgtData.hasOwnProperty(BM + field) ? tgtData[BM + field] : 0.0,
                bullet: getIconPath(field, 'bad')
            });
        });
        newData.sort(function (a, b) {
            return b.pf - a.pf;
        })
        chartSub.data = newData;
        chartSub.innerRadius = am4core.percent(60);
        if (isReview) {
            chartSub.exporting.menu = new am4core.ExportMenu();
            chartSub.exporting.filePrefix = pfName + "_" + subTheme.labelForExport;
        } else {
            let options = chartSub.exporting.getFormatOptions("svg");
            options.quality = 1;
            chartSub.exporting.setFormatOptions("svg", options);
        }

        // PF Series
        let pfSeries = chartSub.series.push(new am4charts.PieSeries());
        pfSeries.dataFields.value = 'pf';
        pfSeries.dataFields.category = 'category';
        pfSeries.name = pfName;

        // Colors
        let colors = subTheme.colors.map(color => am4core.color(color));
        const nbSlices = newData.length;
        const nbExtra = nbSlices - colors.length;
        if (nbExtra > 0) {
            // Dynamic number of colors
            for (let i = 0; i < nbExtra; i++) {
                colors.push(am4core.color("rgba(173, 173, 47, " + (1 - (i / nbExtra)) + ")"));
            }
            // Others
            colors.push(am4core.color("rgba(173, 173, 47, 1)"));
        }
        pfSeries.colors.list = colors;
        // Put a white border around each Slice
        const sliceTemplate = pfSeries.slices.template;
        sliceTemplate.stroke = am4core.color("#fff");
        sliceTemplate.strokeWidth = 1;
        sliceTemplate.strokeOpacity = 1;
        sliceTemplate.tooltipText = "{category}: {value.percent.formatNumber('#.#')}% ({value.formatNumber('" + subTheme.formatValue + "')})";
        sliceTemplate.states.getKey("hover").properties.scale = 1;
        sliceTemplate.states.getKey("active").properties.shiftRadius = 0;

        const groupName = "Others";
        let grouper = pfSeries.plugins.push(new am4plugins_sliceGrouper.SliceGrouper());
        // grouper.threshold = 0.05;
        grouper.limit = NB_SLICES_MAX;
        grouper.groupName = groupName;
        grouper.clickBehavior = "zoom"; // zoom/break/none

        // pfSeries.labels.template.html = "<span class='chart-label-hover'>{category}: {value.formatNumber('" + subTheme.formatValue + "')}</span>";
        pfSeries.labels.template.html = "<span class='chart-label-hover'>{category}: {value.percent.formatNumber('#.#')}%</span>";
        pfSeries.labels.template.events.on("hit", function (ev) {
            let data = ev.target.dataItem;
            const category = data.category;
            if (category !== groupName) {
                changePfKey(pfRow.key + PF_KEY_SEPARATOR + category);
            }
        });
        pfSeries.labels.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;

    }, [pfName, tgtData, changePfKey, pfRow.key, subTheme]);

    const createChart = useCallback(isReview => {
        am4core.useTheme(am4themes_animated);

        createSubPieChart(isReview);
    }, [createSubPieChart]);

    useLayoutEffect(() => {
        createChart(isReview);
    }, [createChart, isReview]);

    const exportAsImage = () => {
        if (tgtChart) {
            tgtChart.current.exporting.filePrefix = pfName + "_" + subTheme.labelForExport;
            tgtChart.current.exporting.export("svg");
        }
    }

    if (Object.keys(tgtData).length <= 1) {
        return <></>;
    }

    return <>
        {/*<FlatSelect enums={CHART_DATA_SCALE} curValue={dataScale} curClass={'canopia-nav-link-active'}*/}
        {/*            otherClass={'canopia-nav-link'}*/}
        {/*            clickFunction={changeDataScale} clickParamFromEnum={true} divStyle={{marginBottom: '20px'}}/>*/}
        <div className={'canopia2 detail-sub-title'}>
            <LabelWithTooltip
                label={<>Contribution to {subThemeValue === TOTAL || theme.key === 'co2' ? subTheme.label : subThemeValue} exposure by sub portfolios</>}
                text={LEGENDS['detail.exposureContrib']}/>
        </div>
        <div style={{display: "grid", gridTemplateColumns: "1fr 30px"}}>
            <p/>
            {!isReview && <span className={'label-hover'} onClick={exportAsImage}>
                        <IconContext.Provider value={{className: "react-icons canopia2", size: "1.4em"}}>
                            <AiOutlineDownload/>
                        </IconContext.Provider>
                    </span>}
        </div>
        <div id={chartDiv} className="chart-sector-sub-pie"/>
    </>
}
