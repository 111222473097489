import React, {useRef} from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

export default function ChipIndicator(props) {

    const target = useRef(null);

    let label = props.label;
    let className = props.className;

    return <OverlayTrigger
        placement='auto'
        delay={{show: 500, hide: 0}}
        trigger={["hover", "focus"]}
        target={target}
        overlay={
            <Tooltip id="tooltip-bottom">
                {label}
            </Tooltip>
        }
        defaultShow={false}>
        {/*need to set display to prevent conflict between tr mouseover and tooltip*/}
        <div className={className} style={{height: 12, width: 12}}/>
    </OverlayTrigger>;
}
