import {createSlice} from '@reduxjs/toolkit';
import {filter, getNextSort, parseNull, sort} from "../containers/canopia/CanopiaUtils";

const realEstateInitialSortStatus = {
    "name": "asc",
    "weight": "asc", // Same sort than for the amount
    "city": "asc",
    "type": "asc",
    "zip": "asc",
    "canton": "asc",
    "buildingId": "asc",
    "primaryEnergy": "asc",
    "label": "asc",
    "consensusRank": "asc",
    "totalCO2": "asc",
    "totalCO2BySRE": "asc",
    "totalCO2ByRevenues": "asc",
    "co2Reduction": "asc",
    "co2BySRETgt2030": "asc",
    "co2YearlyExcess": "asc",
    "co2ExcessTotal": "asc"
}

const initialState = {

    date: null, // the date at which the root pf is displayed
    // Direct lines
    tgtDataOrig: null,
    tgtData: [], // The displayed ones, filtered, sorted, ...
    sortStatus: realEstateInitialSortStatus,
    sortCol: "name",
    filterValuesOrig: {}, // all the values of the filters corresponding to directLinesOrig (initialized once, immutable then)
    filterValues: {}, // all the values of the filters
    filterSelectedValues: {}, // the current selected values of the filters
}

const filterCols = { //
    'city': 0, // 0 => the filter is inactive, 1 => 1st active filter, ...
    'canton': 0, //
    'type': 0, //
    'buildingId': 0, //
    'zip': 0, //
    'primaryEnergy': 0, //
    'label': 0, //
    'consensusLabel': 0 //
};

function resetFilters(state) {
    state.filterValues = state.filterValuesOrig;
    state.filterSelectedValues = state.filterValuesOrig;
    Object.keys(filterCols).forEach(filterCol => {
        filterCols[filterCol] = 0;
    });
}

export const realEstateSlice = createSlice({
    name: 'realEstate',
    initialState,
    reducers: {
        setTgtData: (state, action) => {
            state.date = action.payload.date;
            state.tgtDataOrig = action.payload.pfData.realEstateReports[state.date];
            state.tgtData = state.tgtDataOrig;

            if (state.tgtDataOrig) {
                const tmpValues = {};
                Object.keys(filterCols).forEach(filterCol => {
                    tmpValues[filterCol] = [];
                });
                state.tgtDataOrig.forEach(dLine => {
                    Object.keys(filterCols).forEach(filterCol => {
                        const value = parseNull(dLine[filterCol]);
                        if (!tmpValues[filterCol].includes(value)) {
                            tmpValues[filterCol].push(value);
                        }
                    });
                });
                Object.keys(tmpValues).forEach(filterCol => {
                    tmpValues[filterCol].sort();
                });
                state.filterValuesOrig = tmpValues;

                resetFilters(state);
            }

            // filter(state);
        },
        sortRealEstatePositions: (state, action) => {
            // load the portfolio of the selected client
            let col = action.payload.col;

            sort(state, col, realEstateInitialSortStatus);
        },
        filterRealEstatePositions: (state, action) => {
            // Update the filters with the new selection
            const colName = action.payload.colName;
            const selectedValues = action.payload.selectedValues;

            filter(state, colName, filterCols, selectedValues, realEstateInitialSortStatus);
        },
        clearAllRealEstateFilters: (state, action) => {
            state.tgtData = state.tgtDataOrig;

            resetFilters(state);

            let prevSort = state.sortStatus[state.sortCol];
            state.sortStatus[state.sortCol] = getNextSort(prevSort);
            sort(state, state.sortCol, realEstateInitialSortStatus);
        }
    },
    extraReducers: {}
});

export const {
    setTgtData,
    filterRealEstatePositions,
    sortRealEstatePositions,
    clearAllRealEstateFilters,
} = realEstateSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectRealEstateState = state => state.realEstate;

export default realEstateSlice.reducer;