import React from "react";
import {Cloud, CloudFill} from "react-bootstrap-icons";

/**
 * Shows an icon depending on the CO2 exposure relative to its benchmark.
 */
export default function CO2Icon(props) {
    let value = props.value;

    let emptyIcon = <div style={{height: 2, marginRight: '1.7em'}}/>;

    if (value != null) {
        let size = "1.4em";
        if (value === 0) {
            return emptyIcon;
        }
        if (value <= -0.5) {
            return <CloudFill color={'#00b140'} size={size}/>;
        }
        if (value <= -0.3) {
            return <Cloud color={'#00b140'} size={size}/>;
        }
        if (value >= 0.5) {
            return <CloudFill color={'#fe5000'} size={size}/>;
        }
        if (value >= 0.3) {
            return <Cloud color={'#fe5000'} size={size}/>;
        }
    }
    return emptyIcon;
}
