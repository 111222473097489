import React, {useCallback, useLayoutEffect, useRef} from "react";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4charts from "@amcharts/amcharts4/charts";
import {LEGENDS, PF_KEY_SEPARATOR} from "../CanopiaUtils";
import xyaxis from "../../../images/consensus-chart-xyaxis.png";
import {
    addAmcharts4Licence,
    averageMax,
    averageMin,
    createGradeSubChartData,
    strengthMax,
    strengthMin
} from "./ChartUtils";
import LabelWithTooltip from "../LabelWithTooltip";
import ConsensusGrade from "../ConsensusGrade";
import {renderToStaticMarkup} from "react-dom/server";

export default function DetailSubGradeChart(props) {

    const pfRow = props.pfRow;
    const subTheme = props.subTheme;
    const pfName = props.pfName;
    const isReview = props.isReview;

    const changePfKey = props.changePfKey;

    const tgtData = createGradeSubChartData(pfRow, subTheme.colors);

    const chartDiv = "chart-sub-pie-div";

    const tgtChart = useRef(null);

    function getCircleRadius(wgt, scaling) {
        return Math.max(scaling * Math.sqrt(wgt / Math.PI), 3);
    }

    const createSubPieChart = useCallback(isReview => {
        addAmcharts4Licence();
        am4core.options.autoDispose = true;
        let chartSub = am4core.create(chartDiv, am4charts.XYChart);

        // Add x axis
        let xAxis = chartSub.xAxes.push(new am4charts.ValueAxis());
        // Give some margin if the point is at the max/min
        xAxis.min = averageMin - 0.05;
        xAxis.max = averageMax + 0.05;
        xAxis.strictMinMax = true;
        xAxis.title.text = "Consensus average";
        xAxis.title.dy = -25;
        // Hide the axis, grid and labels
        xAxis.renderer.grid.template.opacity = 0;
        xAxis.renderer.baseGrid.disabled = true;
        xAxis.renderer.labels.template.opacity = 0;
        xAxis.renderer.minGridDistance = 40;

        // Add y axis
        let yAxis = chartSub.yAxes.push(new am4charts.ValueAxis());
        yAxis.min = strengthMin - 0.05;
        yAxis.max = strengthMax + 0.05;
        yAxis.strictMinMax = true;
        yAxis.title.text = "Consensus strength";
        yAxis.title.dx = 30;
        // Hide the axis, grid and labels
        yAxis.renderer.grid.template.opacity = 0;
        yAxis.renderer.baseGrid.disabled = true;
        yAxis.renderer.labels.template.opacity = 0;
        yAxis.renderer.minGridDistance = 40;
        // yAxis.renderer.grid.template.width = 5;

        // Props
        const wgt = "wgt";
        const x = "avg";
        const y = "str";

        chartSub.legend = new am4charts.Legend();
        let legend = chartSub.legend;
        legend.scrollable = true;
        legend.background.fillOpacity = 0.05;
        legend.width = 200;
        legend.position = "right";
        legend.itemContainers.template.paddingTop = 0
        legend.padding(0, 0, 0, 15);
        legend.valign = 'top';
        legend.maxHeight = 250;
        legend.maxWidth = 200;
        legend.minWidth = 200;
        legend.labels.template.truncate = false;
        legend.labels.template.wrap = true;

        legend.useDefaultMarker = true;

        // Remove square from marker template
        let marker = legend.markers.template;
        marker.disposeChildren();
        marker.width = 18;
        marker.height = 18;
        const circle = marker.createChild(am4core.Circle);
        circle.align = "center";
        circle.valign = "middle";
        circle.strokeOpacity = 0;
        circle.fillOpacity = 0.85;
        circle.adapter.add("radius", function (value, target) {
            if (target.dataItem && target.dataItem.dataContext) {
                // scaling = 15 => radius max = 8.5
                return getCircleRadius(target.dataItem.dataContext.data[0][wgt], 15);
            }
        });
        circle.adapter.add("fill", function (value, target) {
            if (target.dataItem && target.dataItem.dataContext) {
                return am4core.color(target.dataItem.dataContext.data[0].color);
            }
        });

        // if (isReview) {
        //     chartSub.exporting.menu = new am4core.ExportMenu();
        //     chartSub.exporting.filePrefix = pfName + "_" + subTheme.labelForExport;
        // } else {
        //     let options = chartSub.exporting.getFormatOptions("svg");
        //     options.quality = 1;
        //     chartSub.exporting.setFormatOptions("svg", options);
        // }

        // only way to get the right colors in the legend
        chartSub.colors.list = [];

        const dimmedOpacity = 0.2;
        tgtData.forEach(pfData => {

            chartSub.colors.list.push(am4core.color(pfData["color"]));

            // PF Series
            let pfSeries = chartSub.series.push(new am4charts.LineSeries());
            pfSeries.name = pfData["name"];
            pfSeries.data = [pfData];
            pfSeries.dataFields.valueX = x;
            pfSeries.dataFields.valueY = y;
            pfSeries.dataFields.value = wgt;
            pfSeries.dataFields.categoryX = x + "_raw"; // To show the raw value in the tooltip (not truncated)
            pfSeries.dataFields.categoryY = y + "_raw"; // To show the raw value in the tooltip (not truncated)
            pfSeries.tooltip.pointerOrientation = "vertical";
            // pfSeries.tooltip.dy = 0;
            // pfSeries.tooltip.background.opacity = 0.9;
            pfSeries.tooltip.getFillFromObject = false;
            pfSeries.tooltip.label.propertyFields.fill = "font_color";
            pfSeries.tooltip.background.propertyFields.stroke = "color";

            let bullet = pfSeries.bullets.push(new am4core.Circle());
            bullet.fill = am4core.color(subTheme.colors[0]);
            bullet.propertyFields.fill = "color";
            bullet.strokeOpacity = 0.5;
            bullet.strokeWidth = 1;
            bullet.fillOpacity = 0.85;
            // scaling = 26.5 => radius max = 26.5 * 0.56 = 15
            // scaling = 20 => radius max = 20 * 0.56 = 11
            bullet.radius = getCircleRadius(pfData[wgt], 22);
            bullet.stroke = am4core.color("#ffffff");
            // bullet.stroke = am4core.color("#000000");
            bullet.hiddenState.properties.opacity = 0;
            // Set the tt text at bullet level to get the right color but props are set on the series side
            // bullet.tooltipText = "[bold]{name}[/]\nAverage: {categoryX.formatNumber('#.0%')}\nStrength: {categoryY.formatNumber('#.0%')}\nWeight: {value.formatNumber('#.0%')}";
            bullet.tooltipHTML =
                "<div style='max-width: 150px; text-wrap: wrap'><b>{name}</b></div>" +
                "<table><tr>" +
                "<td>Consensus Grade</td>" +
                "<td style='padding-left: 5px; text-align: right'>" + renderToStaticMarkup(
                    <ConsensusGrade rating={pfData["cons_rating"]} rank={pfData["cons_rating_rank"]}/>
                ) + "</td>" +
                "</tr>" +
                // "<tr>" +
                // "<th>Average</th>" +
                // "<td style='padding-left: 5px; text-align: right'>{categoryX.formatNumber('#.0%')}</td>" +
                // "</tr><tr>" +
                // "<th>Strength</th>" +
                // "<td style='padding-left: 5px; text-align: right'>{categoryY.formatNumber('#.0%')}</td>" +
                // "</tr><tr>" +
                // "<th>Weight</th>" +
                // "<td style='padding-left: 5px; text-align: right'>{value.formatNumber('#.0%')}</td>" +
                // "</tr>" +
                "</table>";

            bullet.events.on("hit", function (ev) {
                let data = ev.target.dataItem;
                if (data.dataContext.hasOwnProperty('isSub')) {
                    changePfKey(pfRow.key + PF_KEY_SEPARATOR + data.dataContext["name"]);
                }
            });
            bullet.cursorOverStyle = am4core.MouseCursorStyle.pointer;
            bullet.applyOnClones = true; // this is needed to make changing template's "circle" properties apply to clones

            let outline = chartSub.plotContainer.createChild(am4core.Circle);
            outline.fillOpacity = 0;
            outline.strokeOpacity = 1;
            outline.stroke = am4core.color("#adad2f");
            outline.strokeWidth = 3;
            outline.hide(0);

            let blurFilter = new am4core.BlurFilter();
            outline.filters.push(blurFilter);

            bullet.events.on("over", function (event) {
                let target = event.target;
                outline.radius = target.pixelRadius + 2;
                outline.x = target.pixelX;
                outline.y = target.pixelY;
                outline.show();
            })

            bullet.events.on("out", function (event) {
                outline.hide();
            })

            let hoverState = bullet.states.create("hover");
            hoverState.properties.fillOpacity = 1;
            hoverState.properties.strokeOpacity = 1;

            let dimmedState = bullet.states.create("dimmed");
            dimmedState.properties.fillOpacity = dimmedOpacity;
            dimmedState.properties.strokeOpacity = dimmedOpacity;

        });

        // image
        let axisImage = new am4core.Image();
        axisImage.href = xyaxis;
        chartSub.plotContainer.children.push(axisImage);
        axisImage.horizontalCenter = "middle";
        axisImage.verticalCenter = "middle";
        axisImage.opacity = 1;
        axisImage.height = 353;
        axisImage.width = 516;
        axisImage.dx = 185;
        axisImage.dy = 142;

        // Legend
        legend.markers.template.states.create("dimmed").properties.opacity = dimmedOpacity;
        legend.labels.template.states.create("dimmed").properties.opacity = dimmedOpacity;

        legend.itemContainers.template.events.on("over", function (event) {
            processOver(event.target.dataItem);
        });

        legend.itemContainers.template.events.on("out", function (event) {
            processOut();
        });

        legend.itemContainers.template.togglable = false;
        legend.itemContainers.template.events.on("hit", function (ev) {
            let data = ev.target.dataItem;
            if (data.dataContext.data[0].hasOwnProperty('isSub')) {
                changePfKey(pfRow.key + PF_KEY_SEPARATOR + data.name);
            }
        });

        function processOver(dataItem) {
            let tgtBullet;
            dataItem.dataContext.bullets.each(function (bullet) {
                bullet.setState("hover");
                tgtBullet = bullet;
            });
            chartSub.series.each(s => {
                let isTgtSeries = true;
                s.bullets.each(function (bullet) {
                    if (tgtBullet && tgtBullet !== bullet) {
                        bullet.setState("dimmed");
                        isTgtSeries = false;
                    }
                });
                if (!isTgtSeries) {
                    s.legendDataItem.marker.setState("dimmed");
                    s.legendDataItem.label.setState("dimmed");
                }
            });
        }

        function processOut() {
            chartSub.series.each(s => {
                s.bullets.each(function (bullet) {
                    bullet.setState("default");
                });
                s.legendDataItem.marker.setState("default");
                s.legendDataItem.label.setState("default");
            });
        }

    }, [tgtData, changePfKey, pfRow.key, subTheme.colors]);

    const createChart = useCallback(isReview => {
        am4core.useTheme(am4themes_animated);

        createSubPieChart(isReview);
    }, [createSubPieChart]);

    useLayoutEffect(() => {
        createChart(isReview);
    }, [createChart, isReview]);

    const exportAsImage = () => {
        if (tgtChart) {
            tgtChart.current.exporting.filePrefix = pfName + "_" + subTheme.labelForExport;
            tgtChart.current.exporting.export("svg");
        }
    }

    // if (Object.keys(tgtData).length <= 1) {
    //     return <></>;
    // }

    return <>
        {/*<FlatSelect enums={CHART_DATA_SCALE} curValue={dataScale} curClass={'canopia-nav-link-active'}*/}
        {/*            otherClass={'canopia-nav-link'}*/}
        {/*            clickFunction={changeDataScale} clickParamFromEnum={true} divStyle={{marginBottom: '20px'}}/>*/}
        <div className={'canopia2 detail-sub-title'}>
            <LabelWithTooltip label={'Consensus relative positioning'} text={LEGENDS["detail.consensus"]}/>
        </div>
        {/*<div style={{display: "grid", gridTemplateColumns: "1fr 30px"}}>*/}
        {/*    <p/>*/}
        {/*    {!isReview && <span className={'label-hover'} onClick={exportAsImage}>*/}
        {/*                <IconContext.Provider value={{className: "react-icons canopia2", size: "1.4em"}}>*/}
        {/*                    <AiOutlineDownload/>*/}
        {/*                </IconContext.Provider>*/}
        {/*            </span>}*/}
        {/*</div>*/}
        <div id={chartDiv} className="chart-consensus-sub-pie"/>
    </>
}
