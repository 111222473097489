import React from "react";
import Button from "react-bootstrap/Button";
import {useNavigate} from "react-router-dom";

export function GenericNotFound() {

    const navigate = useNavigate();

    const redirect = () => {
        navigate("/");
    }

    return <>
        <br/>
        <header className="jumbotron">
            <h3 className='canopia'>Page not found</h3>
            <p>The page you are trying to reach does not exist. Please check the url or contact our support team if the problem persists.</p>
            <p><Button variant="primary" onClick={redirect}>Back to Home ></Button></p>
        </header>
    </>
}