import React, {useRef} from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

export default function LabelWithTooltip(props) {

    const target = useRef(null);

    let label = props.label;
    let text = props.text;
    let className = props.className ? props.className : 'canopia-label-with-legend';

    return <OverlayTrigger
        placement='bottom'
        delay={{show: 500, hide: 0}}
        trigger={["hover", "focus"]}
        target={target}
        overlay={
            <Tooltip id="tooltip-bottom">
                {text}
            </Tooltip>
        }
        defaultShow={false}>
        {/*need to set display to prevent conflict between tr mouseover and tooltip*/}
        <span style={{display: 'inline-block'}}>
            <span style={{whiteSpace: "nowrap"}} className={className} ref={target}>{label}</span>
        </span>
    </OverlayTrigger>;
}
