import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {
    ALL_CATEGORY_LEVELS,
    CONTEXT_URL,
    EMPTY_DATE,
    findPeriodicity,
    getAllAmountControls,
    HIDE,
    HISTO_SIZE,
    SHOW
} from "../containers/canopia/CanopiaUtils";
import {authHeader} from "../services/auth-header";

const API_URL = CONTEXT_URL + "api/main/";

const initialState = {
    selectedAmountField: null, //
    categoryLevel: ALL_CATEGORY_LEVELS, //
    views: null, // The views of the pf
    view: null, // The current view of the pf

    // Client config
    clientConfig: null, //
    periodicity: null, //

    // Reports
    pfReportsAll: null, // All pf reports even the hidden ones for CO2 charts
    dates: null, // All the report dates available in pfReportsAll

    // Dashboard report
    date: null, // the date at which the root pf is displayed

    // History reports
    histoDates: null, // The 4 dates displayed in the history tab (used to filter pfReportsAll)

    pfData: [],
    pfStatus: "idle",
    pfError: null,

    searchString: '',
    // {
    // "View By Asset class":
    //  {
    //      "Portfolio": {status: {display: "show", showChildren: "hide"}, detail: {category: "Portfolio", depth: 2, parent: "", pfRows: [...]}},
    //      "Portfolio#@#Bond": {status: {display: "show", showChildren: "hide"}, detail: {category: "Bond", depth: 2, parent: "Portfolio", pfRows: [...]}}
    //  },
    //  {
    // "View By Manager":
    //  {
    //      "Portfolio": {status: {display: "show", showChildren: "hide"}, detail: {category: "Portfolio", depth: 2, parent: "", pfRows: [...]}},
    //      "Portfolio#@#BCV": {status: {display: "show", showChildren: "hide"}, detail: {category: "BCV", depth: 2, parent: "Portfolio", pfRows: [...]}}
    //  }
    // }
    pfRowStatus: {},
    curRowKeyFocus: [],
    searchCount: null,

    dnlStatus: "idle",
    dnlError: null
}

export const loadPortfolio = createAsyncThunk(
    'portfolio/portfolio',
    async (params) =>
        fetch(API_URL + "portfolio?clientId=" + params.clientId, {headers: authHeader(), cache: 'no-store'})
            .then(response => {
                let contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json();
                } else {
                    console.log("The content type is not JSON, found " + contentType);
                }
            })
            .then(json => json)
            .catch(error => error)
);

export const downloadReport = createAsyncThunk(
    'portfolioRow/download',
    async (params) => {
        let cat = params.category.replaceAll('&', '')
        window.location.href = API_URL + "download?category=" + cat
    }
);

export const portfolioSlice = createSlice({
    name: 'portfolio',
    initialState,
    reducers: {
        setPfClientConfig: (state, action) => {
            state.clientConfig = action.payload.clientConfig;
            state.periodicity = findPeriodicity(state.clientConfig.periodicityKey);
            state.selectedAmountField = getAllAmountControls(state.clientConfig.showAmounts, state.clientConfig.clientWrapper.currency)[0];
        },
        changeSelectedAmountField: (state, action) => {
            state.selectedAmountField = action.payload.amountField;
        },
        changeSelectedCategoryLevel: (state, action) => {
            state.categoryLevel = action.payload.categoryLevel;
        },
        changeView: (state, action) => {
            state.view = action.payload.view;
            const pfReports = applyViewChange(state);
            setPfReports(pfReports, state);
        },
        changeDate: (state, action) => {
            state.date = action.payload.date;
            const pfReports = state.pfData.pfReports[state.view.viewId];
            setPfReports(pfReports, state);
        },
        changeHistoPeriodicity: (state, action) => {
            state.periodicity = action.payload.periodicityValue;
            const pfReports = state.pfData.pfReports[state.view.viewId];
            setPfReports(pfReports, state);
        },
        resetPfRowStatus: (state, action) => {
            state.searchString = '';
            Object.entries(state.pfRowStatus).forEach(([key, value]) => {
                let depth = value.detail.depth;
                state.pfRowStatus[key].status = {
                    display: depth > 2 ? HIDE : SHOW,
                    showChildren: depth > 1 ? HIDE : SHOW,
                    focus: state.pfRowStatus[key].status.focus
                };
            })
        },
        setPfRow: (state, action) => {
            // store the status and detail of a pf row
            state.pfRowStatus[action.payload.key] = action.payload.status;
        },
        showHideSubRows: (state, action) => {
            // load the portfolio of the selected client
            let rowKey = action.payload.key;
            let value = state.pfRowStatus[rowKey];
            let newShowChildren = value.status.showChildren === HIDE ? SHOW : HIDE;
            if (newShowChildren === SHOW) {
                // all children are hidden, show the children of the sub level
                let depth = value.detail.depth;
                Object.keys(state.pfRowStatus).forEach(key => {
                    if (isDescendant(key, rowKey)) {
                        if (state.pfRowStatus[key].detail.depth === depth + 1) {
                            state.pfRowStatus[key].status = {
                                display: SHOW,
                                showChildren: HIDE,
                                focus: state.pfRowStatus[key].status.focus
                            };
                        }
                    }
                })
            } else {
                // hide all the children no matter the sub level
                Object.keys(state.pfRowStatus).forEach(key => {
                    if (isDescendant(key, rowKey)) {
                        state.pfRowStatus[key].status = {
                            display: HIDE,
                            showChildren: HIDE,
                            focus: state.pfRowStatus[key].status.focus
                        };
                    }
                })
            }

            // Change the current showChildren of this rowKey
            state.pfRowStatus[rowKey].status = {
                display: value.status.display,
                showChildren: newShowChildren,
                focus: state.pfRowStatus[rowKey].status.focus
            };
        },
        expandAllSubRows: (state, action) => {
            // load the portfolio of the selected client
            let rowKey = action.payload.key;
            let status = state.pfRowStatus[rowKey].status;
            let newShowChildren = SHOW;
            // all children are hidden, show the children of the sub level
            Object.keys(state.pfRowStatus).forEach(key => {
                if (isDescendant(key, rowKey)) {
                    state.pfRowStatus[key].status = {
                        display: newShowChildren,
                        showChildren: newShowChildren,
                        focus: state.pfRowStatus[key].status.focus
                    };
                }
            });

            // Change the current showChildren of this rowKey
            state.pfRowStatus[rowKey].status = {
                display: status.display,
                showChildren: newShowChildren,
                focus: state.pfRowStatus[rowKey].status.focus
            };
        },
        searchAllCategories: (state, action) => {
            state.searchString = action.payload.searchString;

            searchCategory(state);
        },
        focusOnRow: (state, action) => {
            // load the portfolio of the selected client
            const rowKey = action.payload.key;
            let tgtKey = rowKey;
            if (state.curRowKeyFocus.length > 0) {
                if (!rowKey) {
                    // No key -> Clear all
                    state.curRowKeyFocus = [];
                } else if (state.curRowKeyFocus.includes(rowKey)) {
                    // Same focus key, reset
                    state.curRowKeyFocus.pop();
                    tgtKey = !state.curRowKeyFocus.length ? null : state.curRowKeyFocus[state.curRowKeyFocus.length - 1];
                } else {
                    // Change focus, save it
                    state.curRowKeyFocus.push(rowKey);
                }
            } else {
                // No focus yet, save it
                state.curRowKeyFocus = [rowKey];
            }

            // all children are hidden, show the children of the sub level
            Object.keys(state.pfRowStatus).forEach(key => {
                if (!state.curRowKeyFocus.length || key === tgtKey || isDescendant(key, tgtKey)) {
                    state.pfRowStatus[key].status.focus = SHOW;
                } else {
                    state.pfRowStatus[key].status.focus = HIDE;
                }
            });
        }
        // focusOnRow: (state, action) => {
        //     // load the portfolio of the selected client
        //     const rowKey = action.payload.key;
        //     if (state.curRowKeyFocus) {
        //         if (!rowKey || rowKey === state.curRowKeyFocus) {
        //             // Same focus key, reset
        //             state.curRowKeyFocus = null;
        //         } else {
        //             // Change focus, save it
        //             state.curRowKeyFocus = rowKey;
        //         }
        //     } else {
        //         // No focus yet, save it
        //         state.curRowKeyFocus = rowKey;
        //     }
        //
        //     // all children are hidden, show the children of the sub level
        //     Object.keys(state.pfRowStatus).forEach(key => {
        //         if (!state.curRowKeyFocus || key.startsWith(rowKey)) {
        //             state.pfRowStatus[key].status.focus = SHOW;
        //         } else {
        //             state.pfRowStatus[key].status.focus = HIDE;
        //         }
        //     });
        // }
    },
    extraReducers: {
        [loadPortfolio.pending]: (state, action) => {
            state.pfStatus = 'loading';
        },
        [loadPortfolio.fulfilled]: (state, action) => {
            // Reset the portfolio data
            state.pfData = [];
            state.pfStatus = "idle";
            state.pfError = null;
            state.pfReportsAll = null;
            state.date = null;
            state.dates = null;
            state.histoDates = null;
            state.pfRowStatus = {};
            state.curRowKeyFocus = [];
            state.searchString = '';

            let payload = action.payload;
            if (payload.status) {
                state.pfStatus = 'error';
                state.pfError = payload;
            } else if (payload.message === 'Failed to fetch') {
                state.pfStatus = 'error';
                state.pfError = {
                    error: 'Service unavailable', //
                    message: 'We apologize for the inconvenience, our team is working on solving the issue. ' + //
                        'Please try to come back in a few minutes. Thank you for your patience.'
                };
            } else {
                state.pfStatus = 'success';
                state.pfData = payload["data"];

                // Init view
                const pfReportViews = state.pfData.pfReports;
                if (!pfReportViews || Object.keys(pfReportViews).length === 0) {
                    // No report -> reset the pfData
                    state.pfData = null;
                } else {
                    state.views = state.clientConfig.clientViews.filter(v => !v.defaultFSF);
                    // Default view
                    state.view = state.views[0];

                    // Init date
                    const pfReports = applyViewChange(state);

                    // Init portfolio
                    setPfReports(pfReports, state);
                }
            }
        },
        [loadPortfolio.rejected]: (state, action) => {
            state.pfStatus = 'error';
            state.pfError = action.payload;
        },
        [downloadReport.pending]: (state, action) => {
            state.dnlStatus = 'loading';
        },
        [downloadReport.fulfilled]: (state, action) => {
            state.dnlStatus = 'success';
        },
        [downloadReport.rejected]: (state, action) => {
            state.dnlStatus = 'error';
            state.dnlError = action.payload;
        }
    }
});

function applyViewChange(state) {
    let allDates = [];
    const pfReports = state.pfData.pfReports[state.view.viewId];
    Object.entries(pfReports).forEach(([date, pfReport]) => {
        if (!pfReport.hidden) {
            allDates.push(date);
        }
    });
    state.dates = allDates;
    state.date = allDates.includes(state.date) ? state.date : allDates[0];

    // Init row status
    initRowStatus(state, pfReports);

    return pfReports;
}

function setPfReports(pfReports, state) {
    state.pfReportsAll = pfReports;

    // History
    let histoDates = [];
    const periodicity = state.periodicity.months;

    // Get the month of the latest report shown
    const lastMonthId = parseInt(state.date.split('-')[1]);

    Object.entries(pfReports).forEach(([d, pfReport]) => {
        if (d <= state.date && //
            !pfReport.hidden && // CO2 only
            histoDates.length < HISTO_SIZE) // only the last 4 dates for now
        {
            const dMonthId = parseInt(d.split('-')[1]);
            if (Math.abs(lastMonthId - dMonthId) % periodicity === 0) {
                histoDates.push(d);
            }
        }
    });
    for (let i = histoDates.length; i < HISTO_SIZE; i++) {
        histoDates.push(EMPTY_DATE + "_" + i);
    }
    state.histoDates = histoDates;

    if (state.searchString !== '') {
        searchCategory(state);
    }
}

function initRowStatus(state, pfReports) {
    state.pfRowStatus = {};
    let depth = 1;
    // store the status and detail of a pf row
    Object.entries(pfReports).forEach(([date, pfRow]) => {
        setRowStatus(state, pfRow, depth, "");
    });
}

function setRowStatus(state, pfRow, depth, parentKey) {
    // if (!pfRow.hidden) {
    const key = pfRow.key;
    if (state.pfRowStatus.hasOwnProperty(key)) {
        state.pfRowStatus[key].detail.pfRows.push(pfRow);
    } else {
        state.pfRowStatus[key] = {
            status: {display: depth > 2 ? HIDE : SHOW, showChildren: depth > 1 ? HIDE : SHOW, focus: SHOW},
            detail: {category: pfRow.category, depth: depth, parent: parentKey, pfRows: [pfRow]}
        };
    }

    if (pfRow.subRows.length > 0) {
        pfRow.subRows.forEach(subRow => {
            setRowStatus(state, subRow, depth + 1, key);
        });
    }
    // }
}

function isDescendant(key, parentKey) {
    return key.startsWith(parentKey + '#');
}

function searchCategory(state) {
    let keys = [];
    state.searchCount = 0;
    // state.searchHistoCount = 0;
    Object.entries(state.pfRowStatus).forEach(([key, value]) => {
        if (value.detail.category != null && value.detail.category.toLowerCase().includes(state.searchString.toLowerCase())) {
            keys.push(key);
            addParent(keys, value.detail.parent, state.pfRowStatus);
            state.searchCount++;
        }
    });

    // The keys contain the parents as well which do not count as a search match
    let uniqueKeys = new Set(keys);
    Object.keys(state.pfRowStatus).forEach(key => {
        let status;
        const focus = state.pfRowStatus[key].status.focus;
        if (uniqueKeys.has(key)) {
            status = {display: SHOW, showChildren: SHOW, focus: focus}; //TODO wrong but how to do it???
        } else {
            status = {display: HIDE, showChildren: HIDE, focus: focus};
        }
        state.pfRowStatus[key].status = status;
    })
}

function addParent(array, parent, ref) {
    if (parent !== "") {
        array.push(parent);
        addParent(array, ref[parent].detail.parent, ref);
    }
}

export const {
    setPfClientConfig,
    changeSelectedAmountField,
    changeSelectedCategoryLevel,
    changeView,
    changeDate,
    changeHistoPeriodicity,
    resetPfRowStatus,
    setPfRow,
    showHideSubRows,
    expandAllSubRows,
    searchAllCategories,
    focusOnRow
} = portfolioSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectPortfolioState = state => state.portfolio;

export default portfolioSlice.reducer;