import React from "react";
import {getRatingColors} from "./CanopiaUtils";
import GradeIcon from "./GradeIcon";

export default function ConsensusGrade(props) {

    const rank = props.rank;
    const borderColor = getRatingColors(rank).color;

    return <GradeIcon rating={props.rating} size={30} borderColor={borderColor} innerColor={"#fff"} fontColor={'#505050'} />;
}
