import React, {useEffect, useState} from "react";
import ajaxloader from "../../images/ajax-loader.gif";
import {Card, DropdownButton, FormControl, FormGroup, Tab, Tabs} from "react-bootstrap";
import Funds from "./Funds";
import {useDispatch, useSelector} from 'react-redux';
import {
    changeDate,
    changeView,
    loadPortfolio,
    resetPfRowStatus,
    searchAllCategories,
    selectPortfolioState,
    setPfClientConfig
} from '../../reducer/portfolioSlice';
import Download from "./Download";
import PortfolioHisto from "./PortfolioHisto";
import Button from "react-bootstrap/Button";
import {IconContext} from "react-icons";
import {TiDeleteOutline} from "react-icons/ti";
import {loadClients, selectClientState, setClientConfig} from '../../reducer/clientSlice';
import DropdownItem from "react-bootstrap/DropdownItem";
import RealEstate from "./RealEstate";
import DirectLines from "./DirectLines";

export default function ClientMonitoring(props) {

    const [addRequestStatus, setAddRequestStatus] = useState('idle');
    const [clearSearchVisibility, setClearSearchVisibility] = useState('hidden');
    const [searchClient, setSearchClient] = useState('');
    const [searchString, setSearchString] = useState('');

    const dispatch = useDispatch();

    const {clientConfig, clientConfigs, status, error} = useSelector(selectClientState);
    const {pfData, pfStatus, pfError, views, view, dates, date} = useSelector(selectPortfolioState);

    const user = props.user;

    const isCanopiaSales = user && user.roles.includes("Canopia-Sales");

    // Load all the clients to populate the dropdown (done only once)
    useEffect(() => {
        if (status === "idle") {
            dispatch(loadClients());
        }
    }, [status, dispatch]);

    useEffect(() => {
        if (pfStatus === "idle" && clientConfigs.length > 0) {
            let initialClient = clientConfigs[0];
            dispatch(setClientConfig({clientConfig: initialClient}));
            dispatch(setPfClientConfig({clientConfig: initialClient}));
            dispatch(loadPortfolio({clientId: initialClient.clientWrapper.id}));
        }
    }, [clientConfigs, pfStatus, dispatch]);

    useEffect(() => {
        // Change the logo only when new client data is available
        if (clientConfig && clientConfig.clientBrand) {
            props.changeLogo(clientConfig.clientBrand);
        }
    }, [clientConfig, props]);

    const onChangeClient = async (e) => {
        // let tgtClientId = parseInt(e.target.value);
        let tgtClientId = parseInt(e);
        resetSearch();
        if (addRequestStatus === 'idle') {
            try {
                setAddRequestStatus('pending');
                let tgtClient = null;
                clientConfigs.forEach(clientConfig => {
                    if (clientConfig.clientWrapper.id === tgtClientId) {
                        tgtClient = clientConfig;
                    }
                });
                dispatch(setClientConfig({clientConfig: tgtClient}));
                dispatch(setPfClientConfig({clientConfig: tgtClient}));
                await dispatch(loadPortfolio({clientId: tgtClientId}));
            } catch (err) {
                console.error('Failed to load the portfolio: ', err)
            } finally {
                setAddRequestStatus('idle');
            }
        }
    }

    // Search category, the component is common to the global and histo view
    const searchCategory = (e) => {
        let searchString = e.target.value;
        searchCat(searchString);
    }

    const resetSearch = () => {
        searchCat('');
        setSearchClient('');
    }

    // const resetSearchField = () => {
    //     setSearchString('');
    //     setClearSearchVisibility("hidden");
    // }

    function searchCat(searchString) {
        setSearchString(searchString);
        if (searchString.trim() === "") {
            setClearSearchVisibility("hidden");
            // Reset the view, show the default categories
            dispatch(resetPfRowStatus());
        } else {
            if (clearSearchVisibility === "hidden") {
                setClearSearchVisibility("visible");
            }
            dispatch(searchAllCategories({searchString: searchString}));
        }
    }

    const changePfView = (view) => {
        // resetSearchField();
        dispatch(changeView({view: view}));
    };

    const changePfDate = (date) => {
        // resetSearch();
        dispatch(changeDate({date: date}));
    }

    let upperContent = <></>;
    let content;

    if (status === "error") {
        content = <div className="dashboard">
            <header className="jumbotron">
                <h3 className='canopia'>{error ? error.error : 'Error'}</h3>
                <p>{error ? error.message : 'An error occurred while processing your request. If the problem persists, please contact our support team (info@canopia.ch)'}</p>
            </header>
        </div>
    } else if (status !== "success") {
        content = <div><br/><img alt="loading" src={ajaxloader}/></div>;
    } else {

        if (pfError || pfStatus === "error") {
            content = <div className="dashboard">
                <header className="jumbotron">
                    <h3 className='canopia'>{pfError.error}</h3>
                    <p>{pfError.message}</p>
                </header>
            </div>
        } else if (pfStatus !== "success") {
            content = <div><br/><img alt="loading" src={ajaxloader}/></div>;
        } else if (clientConfigs == null || clientConfigs.length === 0) {
            content = <div className="dashboard">
                <header className="jumbotron">
                    <h3 className='canopia'>Information</h3>
                    <p>This server could not verify that you are authorized to access the document requested.</p>
                </header>
            </div>
        } else {

            // If the user has access to at least 2 clients, display a dropdown
            let clientComp = (clientConfigs.length >= 2 ? <div style={{clear: 'left', paddingTop: '20px'}}>
                <DropdownButton id="dropdown-basic-button" title={clientConfig.clientWrapper.displayName}
                                value={clientConfig.clientWrapper.id} variant={'client-dropdown'}>
                    {/*If the user has access to at least 10 clients, add a search filter */}
                    {clientConfigs.length >= 10 && <FormControl
                        autoFocus
                        className="mx-3 my-2 w-auto"
                        placeholder="Search..."
                        onChange={(e) => setSearchClient(e.target.value)}
                        value={searchClient}
                    />}
                    {clientConfigs
                        .filter(c => !searchClient || c.clientWrapper.displayName.toLowerCase().includes(searchClient.toLowerCase()))
                        .map(c =>
                            (c.clientWrapper.id !== clientConfig.clientWrapper.id &&
                                <DropdownItem key={c.clientWrapper.id} value={c.clientWrapper.id}
                                              onClick={() => onChangeClient(c.clientWrapper.id)}
                                              bsPrefix={'canopia-dropdown-item'}>
                                    <span style={{fontSize: '18px'}}>{c.clientWrapper.displayName}</span>
                                </DropdownItem>
                            )
                        )}
                </DropdownButton>
            </div> : <div className={'client-title'}
                          style={{clear: 'left', paddingTop: '20px'}}>{clientConfig.clientWrapper.displayName}</div>);

            upperContent = <>
                {clientComp}
            </>;

            if (!pfData) {
                content = <Card style={{width: '100%', margin: 'auto'}}>
                    <Card.Body>
                        <Card.Title className='canopia'>No data available</Card.Title>
                        <p style={{marginTop: '20px'}}>There is no data available yet to show in the Canopia platform.
                            Most likely the first report is under preparation.</p>
                        <p>If you have any question please contact our support team (info@canopia.ch).</p>
                    </Card.Body>
                </Card>;
            } else {

                let searchCategoryInput = <FormGroup controlId="selectView">
                    <input value={searchString}
                           placeholder="Search..."
                           className={'form-control float-left'}
                        // style={{minWidth: "180px"}}
                           aria-label="Search category"
                           onChange={searchCategory}
                           aria-describedby="basic-addon2"/>
                </FormGroup>;

                let searchCategoryComp;
                const isIE = /*@cc_on!@*/false || !!document.documentMode;
                if (isIE) {
                    searchCategoryComp =
                        <div style={{display: "flex", flexWrap: "nowrap", marginRight: 'auto'}}>
                            {searchCategoryInput}
                        </div>
                } else {
                    searchCategoryComp = <div style={{display: "flex", flexWrap: "nowrap"}}>
                        {searchCategoryInput}
                        <Button variant="light" size="sm"
                                style={{
                                    marginLeft: 0,
                                    padding: 4,
                                    borderWidth: '1px 1px 1px 0',
                                    borderColor: '#ced4da',
                                    borderRadius: '0.25rem .25rem .25rem 0.25rem',
                                    visibility: clearSearchVisibility
                                }}
                                onClick={resetSearch}>
                            <IconContext.Provider value={{className: "react-icons canopia2", size: "1.4em"}}>
                                <TiDeleteOutline/>
                            </IconContext.Provider>
                        </Button>
                        {/*<span style={{paddingLeft: '10px', margin: 'auto'}}>{searchString.length > 0 ? searchCount + ' result(s)' : ''}</span>*/}
                    </div>
                }

                let viewSelect = views.length > 1 &&
                    <DropdownButton id="dropdown-basic-button" title={view.name} value={view.viewId}
                                    variant={'default-dropdown'}>
                        {views.map(v => (
                            v.viewId !== view.viewId &&
                            <DropdownItem key={v.viewId} value={v.viewId} onClick={() => changePfView(v)}
                                          bsPrefix={'canopia-dropdown-item'}>
                                <span style={{fontWeight: 'normal'}}>{v.name}</span>
                            </DropdownItem>
                        ))}
                    </DropdownButton>;

                let dateSelect = dates.length > 1 ?
                    <DropdownButton id="dropdown-basic-button" title={date} value={date} variant={'default-dropdown'}>
                        {dates.map(hDate => (
                            hDate !== date &&
                            <DropdownItem key={hDate} value={hDate} onClick={() => changePfDate(hDate)}
                                          bsPrefix={'canopia-dropdown-item'}>
                                <span style={{fontWeight: 'normal'}}>{hDate}</span>
                            </DropdownItem>
                        ))}
                    </DropdownButton> : dates.length === 1 ? <span className={'nowrap'}>{dates[0]}</span> : <></>;

                // <FormGroup controlId="selectDate">
                //     <FormControl as="select" custom onChange={changePfDate.bind(this)} value={date}>
                //         {dates.map(hDate => (
                //             <option key={hDate} value={hDate}>{hDate}</option>
                //         ))}
                //     </FormControl>
                // </FormGroup>

                const showDirectLines = clientConfig.showDirectLines;
                const hasRealEstateComponents = Object.keys(pfData.realEstateReports).length !== 0;
                // const showSuitability = clientConfig.showSuitability;
                const showSuitability = clientConfig.clientWrapper.id === 243;
                // const showSuitability = false;

                content = <div>
                    {/* transition generates a warning: findDOMNode is deprecated in StrictMode */}
                    <Tabs defaultActiveKey="histo" id="portfolio-tabs" transition={false}>
                        {/*<Tab eventKey="pf" title="Dashboard">*/}
                        {/*    <Portfolio mode={PF_MODE} searchInput={searchCategoryComp} viewSelect={viewSelect}*/}
                        {/*               dateSelect={dateSelect}/>*/}
                        {/*</Tab>*/}
                        {/*{showSuitability && <Tab eventKey="suitability" title="Suitability">*/}
                        {/*    <Portfolio mode={SA_MODE} searchInput={searchCategoryComp} viewSelect={viewSelect} dateSelect={dateSelect}/>*/}
                        {/*</Tab>}*/}
                        <Tab eventKey="histo" title="Dashboard">
                            <PortfolioHisto searchInput={searchCategoryComp} viewSelect={viewSelect}
                                            dateSelect={dateSelect} changePfDate={changePfDate}
                                            showSuitability={showSuitability}/>
                        </Tab>
                        <Tab eventKey="funds" title="Funds">
                            <Funds searchInput={searchCategoryComp} viewSelect={viewSelect} dateSelect={dateSelect}/>
                        </Tab>
                        {showDirectLines && <Tab eventKey="directLines" title="Direct Lines">
                            <DirectLines dateSelect={dateSelect}/>
                        </Tab>}
                        {hasRealEstateComponents && <Tab eventKey="realestate2" title="Real Estate">
                            <RealEstate dateSelect={dateSelect}/>
                        </Tab>}
                        <Tab eventKey="download" title="Download">
                            <Download isSales={isCanopiaSales}/>
                        </Tab>
                    </Tabs>
                </div>
            }
        }
    }

    return (
        <>
            {upperContent}
            <div className="dashboard">
                {content}
            </div>
        </>
    );
}
