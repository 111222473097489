import React, {useRef} from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {getIconPath, ICON_SIZE} from "./CanopiaUtils";
import Table from "react-bootstrap/Table";

export default function Controversy(props) {

    const target = useRef(null);

    const category = props.name;
    const widthClass = props.widthClass;
    const pfRow = props.pfRow;
    const bmRow = props.bmRow;
    const showBm = props.showBm;
    const theme = props.theme;
    const type = props.type;
    let themes, bmThemes;
    let sum, bmSum;
    let name = '';
    const pfBgClass = 'background-light';
    const bmBgClass = 'background-light';
    const borderLeftClassName = 'border-large-left';

    switch (theme) {
        case 'norms' :
            themes = pfRow.norms;
            sum = pfRow.normsSum;
            bmThemes = bmRow ? bmRow.norms : null;
            bmSum = bmRow ? bmRow.normsSum : null;
            name = 'Breach to Intl Norms';
            break;
        case 'sectors' :
            themes = pfRow.sectors;
            sum = pfRow.sectorsSum;
            bmThemes = bmRow ? bmRow.sectors : null;
            bmSum = bmRow ? bmRow.sectorsSum : null;
            name = 'Sensitive Sectors';
            break;
        default:
    }

    if ((!themes || !themes.length) && (!bmThemes || !bmThemes.length)) {
        // if (!themes || !themes.length) {
        return "";
    }

    if (sum.status !== 'NONE' || (bmSum && bmSum.status !== 'NONE')) {

        // benchmark themes not in pf
        let bmOnlyThemes = [];
        if (showBm && bmThemes && bmThemes.length) {
            bmThemes.forEach(bmTheme => {
                let themeFound;
                themes.forEach(theme => {
                    if (bmTheme.theme.name === theme.theme.name && theme.status !== 'NONE' && bmTheme.status !== 'NONE') {
                        themeFound = bmTheme;
                    }
                });
                if (!themeFound) {
                    bmOnlyThemes.push(bmTheme);
                }
            });
        }

        return <OverlayTrigger
            placement='auto'
            delay={{show: 500, hide: 0}}
            trigger={["hover", "focus"]}
            target={target}
            overlay={
                <Tooltip id="tooltip-bottom">
                    <h3 className={'canopia2'}>{category}</h3>
                    <p className={'canopia'}><b>{name}</b></p>
                    <Table size="sm">
                        <thead>
                        <tr>
                            <th className={"canopia2"}>Theme</th>
                            <th className={"canopia2"} colSpan={2}>
                                <span style={{float: "right"}}>Weight</span>
                            </th>
                            {showBm && <th className={"canopia2 " + bmBgClass + ' ' + borderLeftClassName} colSpan={2}>
                                <span style={{float: "right"}}>Benchmark</span>
                            </th>}
                        </tr>
                        </thead>
                        <tbody>
                        {themes.map(function (item) {
                            if (item.status !== 'NONE') {
                                let iconPath;
                                switch (item.status) {
                                    case 'SIGNIFICANT':
                                        iconPath = getIconPath(item.theme.name, 'bad');
                                        break;
                                    case 'NOT_SIGNIFICANT' :
                                        iconPath = getIconPath(item.theme.name, 'good');
                                        break;
                                    default:
                                }
                                let bmItem;
                                let bmIcon = '';
                                if (bmThemes && bmThemes.length) {
                                    bmThemes.forEach(bmTheme => {
                                        if (bmTheme.theme.name === item.theme.name && bmTheme.status !== 'NONE') {
                                            bmItem = bmTheme;
                                            let bmIconPath;
                                            switch (bmItem.status) {
                                                case 'SIGNIFICANT':
                                                    bmIconPath = getIconPath(bmItem.theme.name, 'bad');
                                                    break;
                                                case 'NOT_SIGNIFICANT' :
                                                    bmIconPath = getIconPath(bmItem.theme.name, 'good');
                                                    break;
                                                default:
                                            }
                                            bmIcon = <img ref={target} alt={bmItem.theme.name} src={bmIconPath} width={ICON_SIZE} height={ICON_SIZE} style={{marginRight: 4}}/>;
                                        }
                                    })
                                }
                                return <tr key={item.description}>
                                    <td className={pfBgClass}>
                                        {/*<span style={{whiteSpace: "nowrap"}}>{item.description}</span>*/}
                                        {item.description}
                                    </td>
                                    <td className={pfBgClass} align={"right"}>
                                        <img ref={target} alt={item.theme.name} src={iconPath} width={ICON_SIZE} height={ICON_SIZE} style={{marginRight: 4}}/>
                                    </td>
                                    <td className={pfBgClass} align={"right"}>
                                        {item.formattedWeight}
                                    </td>
                                    {showBm && <>
                                        <td className={bmBgClass + ' ' + borderLeftClassName} align={"right"}>
                                            {bmIcon}
                                        </td>
                                        <td className={bmBgClass} align={"right"}>
                                            {bmItem ? bmItem.formattedWeight : ''}
                                        </td>
                                    </>}
                                </tr>
                            } else {
                                return <React.Fragment key={item.description}/>
                            }
                        })}
                        {bmOnlyThemes.map(function (item) {
                            if (item.status !== 'NONE') {
                                let iconPath;
                                switch (item.status) {
                                    case 'SIGNIFICANT':
                                        iconPath = getIconPath(item.theme.name, 'bad');
                                        break;
                                    case 'NOT_SIGNIFICANT' :
                                        iconPath = getIconPath(item.theme.name, 'good');
                                        break;
                                    default:
                                }
                                return <tr key={item.description}>
                                    {/*<td><span style={{whiteSpace: "nowrap"}}>{item.description}</span></td>*/}
                                    <td className={pfBgClass}>{item.description}</td>
                                    <td className={pfBgClass}/>
                                    <td className={pfBgClass}/>
                                    {showBm && <>
                                        <td className={bmBgClass + ' ' + borderLeftClassName} align={"right"}>
                                            <img ref={target} alt={item.theme.name} src={iconPath} width={ICON_SIZE} height={ICON_SIZE} style={{marginRight: 4}}/>
                                        </td>
                                        <td className={bmBgClass} align={"right"}>
                                            {item ? item.formattedWeight : ''}
                                        </td>
                                    </>}
                                </tr>
                            } else {
                                return <React.Fragment key={item.description}/>
                            }
                        })}
                        <tr>
                            <td className={pfBgClass}><b>Total</b></td>
                            <td className={pfBgClass}/>
                            <td className={pfBgClass} align={"right"}><b>{sum.formattedWeight}</b></td>
                            {showBm && <>
                                <td className={bmBgClass + ' ' + borderLeftClassName}/>
                                <td className={bmBgClass} align={"right"}><b>{bmSum ? bmSum.formattedWeight : ''}</b></td>
                            </>}
                        </tr>
                        </tbody>
                    </Table>
                </Tooltip>
            }
            defaultShow={false}>
            {/*need to set display to prevent conflict between tr mouseover and tooltip*/}
            <span className={widthClass} style={{display: 'inline-block'}}>
                {(type === 'pf' ? themes : bmThemes).map(function (item) {
                    if (item.status !== 'NONE') {
                        let iconPath;
                        switch (item.status) {
                            case 'SIGNIFICANT':
                                iconPath = getIconPath(item.theme.name, 'bad');
                                break;
                            case 'NOT_SIGNIFICANT' :
                                iconPath = getIconPath(item.theme.name, 'good');
                                break;
                            default:
                        }
                        return <img key={item.theme.name} ref={target} alt={item.theme.name} src={iconPath} width={ICON_SIZE} height={ICON_SIZE} style={{marginRight: 4}}/>
                    } else return '';
                    //     <span style={{display: 'flex', width: 60}}>
                    //     <div style={{float: 'left', width: 60}}><ProgressBar variant={className} ref={target} now={sum.weight} max={0.2}/></div>
                    // </span>
                })}
            </span>

            {/*<span style={{display: 'inline-block', width: 120}}>*/}
            {/*    <div style={{float:'left', width: 60}}><ProgressBar variant={className} ref={target} now={sum.weight} max={0.2}/></div>*/}
            {/*    <div style={{float:'right'}}>{sum.formattedWeight}</div>*/}
            {/*</span>*/}
        </OverlayTrigger>;
    } else {
        return '';
    }
}
