import React from "react";
import Controversy from "./Controversy";
import Impact from "./Impact";
import CO2 from "./CO2";
import TrendIcon from "./TrendIcon";
import Grade from "./Grade";
import Coverage from "./Coverage";
import NetZero from "./NetZero";

export default function ESGMetricsValues(props) {

    const pfRow = props.pfEsgReport;
    const bmRow = props.bmEsgReport;
    const bmClassName = props.bmClassName;
    const name = props.name;
    const bmName = props.bmName;
    const pfTrend = props.pfTrend;
    const bmTrend = props.bmTrend;
    const theme = props.theme;
    const subTheme = props.subTheme;
    const themes = props.themes;
    const viewModeKey = props.viewModeKey;
    const borderLeftClassName = props.borderLeftClassName;
    // const pfBgClass = 'background-light';
    const bmBgClass = 'background-bm-light';
    // const bmBgClass = '';

    if (!pfRow) {
        return <td colSpan={theme.cols}>&nbsp;</td>;
    }

    const showBm = bmClassName === 'bm-display';

    let esgTheme;
    let bmEsgThemeEmpty = <td className={bmClassName + ' ' + bmBgClass + " border-large-left"} colSpan={theme.cols}/>;

    function getControversy(row, subThemeKey, showBm, type) {
        switch (subThemeKey) {
            case themes[1].subThemes[0].key:
                return <td className={'form-padding ' + borderLeftClassName}>
                    <Controversy name={name} widthClass='esg-bars-long'
                                 type={type}
                                 showBm={showBm}
                                 theme='sectors'
                                 themes={row.sectors}
                                 sum={row.sectorsSum}
                                 pfRow={pfRow} bmRow={bmRow}/>
                </td>;
            case themes[1].subThemes[1].key:
                return <td className={'form-padding ' + borderLeftClassName}>
                    <Controversy name={name} widthClass='esg-bars-long'
                                 type={type}
                                 showBm={showBm}
                                 theme='norms'
                                 themes={row.norms}
                                 sum={row.normsSum}
                                 pfRow={pfRow} bmRow={bmRow}/>
                </td>;
            default:
                return null;
        }
    }

    switch (theme.key) {
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // ESG REVIEW
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        case themes[0].key:
            esgTheme = <>
                <td className={'form-padding ' + borderLeftClassName}>
                    <Grade row={pfRow} name={name} bmName={bmName} pfRow={pfRow} bmRow={bmRow} showBm={showBm}/>
                </td>
                <td className={'form-padding'}>
                    <div style={{marginLeft: "10px"}}>
                        <TrendIcon value={pfTrend} show={pfRow.rating && pfRow.rating.trim() !== ""}/>
                    </div>
                </td>
                <td className={'form-padding'}>
                    <div style={{marginLeft: "25px"}}>
                        <Coverage esgReport={pfRow} key={'cov_' + theme.key}/>
                    </div>
                </td>
            </>;
            if (showBm) {
                if (bmRow) {
                    return <>
                        {esgTheme}
                        <td className={'form-padding ' + bmBgClass + ' ' + borderLeftClassName}>
                            <Grade row={bmRow} name={name} bmName={bmName} pfRow={pfRow} bmRow={bmRow} showBm={showBm}/>
                        </td>
                        <td className={'form-padding ' + bmBgClass}>
                            <TrendIcon value={bmTrend} show={bmRow.rating && bmRow.rating.trim() !== ""}/>
                        </td>
                        <td className={'form-padding ' + bmBgClass}>
                            <div style={{marginLeft: "25px"}}>
                                <Coverage esgReport={bmRow} key={'cov_bm_' + theme.key}/>
                            </div>
                        </td>
                    </>;
                } else {
                    return <>
                        {esgTheme}
                        {bmEsgThemeEmpty}
                    </>
                }
            }
            return esgTheme;

        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // CONTROVERSIES
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        case themes[1].key:
            // let normWidth = 3 * (ICON_SIZE + 4) + (2 * 10) + 2; // #icons * size + 2 * padding + border
            // let sectorWidth = 4 * (ICON_SIZE + 4) + (2 * 10) + 2; // #icons * size + 2 * padding + border
            if (viewModeKey === 'pf') {
                // Dashboard view
                return <>
                    <td className={'form-padding ' + borderLeftClassName}>
                        <Controversy name={name} widthClass='esg-bars-long' type='pf' theme='sectors' pfRow={pfRow}
                                     bmRow={bmRow} showBm={showBm} themes={pfRow.sectors}
                                     sum={pfRow.sectorsSum}/>
                    </td>
                    <td className={'form-padding'}>
                        <Controversy name={name} widthClass='esg-bars-long' type='pf' theme='norms' pfRow={pfRow}
                                     bmRow={bmRow} showBm={showBm} themes={pfRow.norms}
                                     sum={pfRow.normsSum}/>
                    </td>
                </>;
            }

            // For both Histo and Benchmark view
            esgTheme = getControversy(pfRow, subTheme.key, showBm, 'pf');

            if (viewModeKey === 'hi') {
                return esgTheme;
            }

            // if (viewModeKey === 'bm') {
            // Add the BM side
            let bmEsgTheme;
            if (bmRow) {
                bmEsgTheme = getControversy(bmRow, subTheme.key, showBm, 'bm');
            } else {
                bmEsgTheme = <td className={bmClassName + ' ' + bmBgClass + " border-large-left"}/>;
            }
            return <>
                {esgTheme}
                {bmEsgTheme}
            </>
        // }

        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // IMPACTS
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        case themes[2].key:
            if (viewModeKey === 'hi' && subTheme && theme.subThemesHistoView === 'singleField') {
                // Always show the bm in histo view
                const impactsShowBm = true;
                switch (subTheme.key) {
                    case themes[2].subThemes[0].key:
                        esgTheme = <td align={"center"} className={'form-padding ' + borderLeftClassName}>
                            <Impact name={name} className='climate-pos' widthClass='esg-bars-long' pfRow={pfRow}
                                    bmRow={bmRow} showBm={impactsShowBm}
                                    themes={pfRow.posImpacts} sum={pfRow.posImpactsSum}/>
                        </td>;
                        break;
                    case themes[2].subThemes[1].key:
                        esgTheme = <td align={"center"} className={'form-padding ' + borderLeftClassName}>
                            <Impact name={name} className='climate-neg' widthClass='esg-bars-long' pfRow={pfRow}
                                    bmRow={bmRow} showBm={impactsShowBm}
                                    themes={pfRow.negImpacts} sum={pfRow.negImpactsSum}/>
                        </td>;
                        break;
                    // case themes[2].subThemes[2].key:
                    //     esgTheme = <td align={"center"} className={'form-padding ' + borderLeftClassName}>
                    //         <Impacts name={name} className='thematic-bonds-pos' pfRow={pfRow} bmRow={bmRow} showBm={impactsShowBm} themes={pfRow.thematicBonds} sum={pfRow.thematicBondsSum}/>
                    //     </td>;
                    //     break;
                    default:
                }
            } else {
                esgTheme = <>
                    <td align={"center"} className={'form-padding ' + borderLeftClassName}>
                        <Impact name={name} className='climate-pos' widthClass='esg-bars-short' pfRow={pfRow}
                                bmRow={bmRow} showBm={showBm} themes={pfRow.posImpacts}
                                sum={pfRow.posImpactsSum}/>
                    </td>
                    <td align={"center"} className={'form-padding ' + borderLeftClassName}>
                        <Impact name={name} className='climate-neg' widthClass='esg-bars-short' pfRow={pfRow}
                                bmRow={bmRow} showBm={showBm} themes={pfRow.negImpacts}
                                sum={pfRow.negImpactsSum}/>
                    </td>
                    {/*<td align={"center"} className={'form-padding ' + borderLeftClassName}>*/}
                    {/*    <Impacts name={name} className='thematic-bonds-pos' pfRow={pfRow} bmRow={bmRow} showBm={showBm} themes={pfRow.thematicBonds} sum={pfRow.thematicBondsSum}/>*/}
                    {/*</td>*/}
                </>
            }
            // if (showBm) {
            //     if (bmRow) {
            //         return <>
            //             {esgTheme}
            //             <td align={"center"} className={'form-padding ' + bmBgClass + ' ' + borderLeftClassName}>
            //                 <Impacts className='climate-pos' themes={bmRow.posImpacts} sum={bmRow.posImpactsSum}/>
            //             </td>
            //             <td align={"center"} className={'form-padding ' + bmBgClass}>
            //                 <Impacts className='climate-neg' themes={bmRow.negImpacts} sum={bmRow.negImpactsSum}/>
            //             </td>
            //             <td align={"center"} className={'form-padding ' + bmBgClass}>
            //                 <Impacts className='thematic-bonds-pos' themes={bmRow.thematicBonds} sum={bmRow.thematicBondsSum}/>
            //             </td>
            //         </>
            //     } else {
            //         return <>
            //             {esgTheme}
            //             {bmEsgThemeEmpty}
            //         </>
            //     }
            // }
            return esgTheme;

        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // CO2
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        case themes[3].key:
            if (viewModeKey === 'hi' && subTheme && theme.subThemesHistoView === 'singleField') {
                switch (subTheme.key) {
                    case themes[3].subThemes[0].key:
                        esgTheme = <CO2 name={name} bmName={bmName} className='climate-pos' widthClass='esg-bars-long'
                                        pfRow={pfRow} bmRow={bmRow} subTheme={subTheme}
                                        borderLeftClassName={borderLeftClassName}/>;
                        break;
                    case themes[3].subThemes[1].key:
                        esgTheme = <CO2 name={name} bmName={bmName} className='climate-pos' widthClass='esg-bars-long'
                                        pfRow={pfRow} bmRow={bmRow} subTheme={subTheme}
                                        borderLeftClassName={borderLeftClassName}/>;
                        break;
                    default:
                }
            } else {
                if (showBm) {
                    esgTheme = <>
                        <CO2 name={name} bmName={bmName} widthClass='esg-bars-short' pfRow={pfRow} bmRow={bmRow}
                             subTheme={themes[3].subThemes[0]}
                             borderLeftClassName={borderLeftClassName}/>
                        <CO2 name={name} bmName={bmName} widthClass='esg-bars-short' pfRow={pfRow} bmRow={bmRow}
                             subTheme={themes[3].subThemes[1]}
                             borderLeftClassName={borderLeftClassName}/>
                    </>;
                } else {
                    esgTheme = <>
                        <CO2 name={name} bmName={bmName} widthClass='esg-bars-short' pfRow={pfRow} bmRow={bmRow}
                             subTheme={themes[3].subThemes[0]}
                             borderLeftClassName={borderLeftClassName}/>
                    </>;
                }
            }
            return <>{esgTheme}</>

        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // INITIATIVE
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        case themes[4].key:
            if (viewModeKey === 'hi' && subTheme && theme.subThemesHistoView === 'singleField') {
                // Always show the bm in histo view
                const sbtiShowBm = true;
                switch (subTheme.key) {
                    case themes[4].subThemes[0].key:
                        esgTheme = <td align={"center"} className={'form-padding ' + borderLeftClassName}>
                            <NetZero name={name} className='aligned' widthClass='esg-bars-long' pfRow={pfRow}
                                     bmRow={bmRow} showBm={sbtiShowBm}
                                     sum={pfRow.netZeroPosSum}/>
                        </td>;
                        break;
                    case themes[4].subThemes[1].key:
                        esgTheme = <td align={"center"} className={'form-padding ' + borderLeftClassName}>
                            <NetZero name={name} className='misaligned' widthClass='esg-bars-long' pfRow={pfRow}
                                     bmRow={bmRow} showBm={sbtiShowBm}
                                     sum={pfRow.netZeroNegSum}/>
                        </td>;
                        break;
                    default:
                }
            } else {
                esgTheme = <>
                    <td align={"center"} className={'form-padding ' + borderLeftClassName}>
                        <NetZero name={name} className='aligned' widthClass='esg-bars-short' pfRow={pfRow} bmRow={bmRow}
                                 showBm={showBm} sum={pfRow.netZeroPosSum}/>
                    </td>
                    <td align={"center"} className={'form-padding ' + borderLeftClassName}>
                        <NetZero name={name} className='misaligned' widthClass='esg-bars-short' pfRow={pfRow}
                                 bmRow={bmRow} showBm={showBm} sum={pfRow.netZeroNegSum}/>
                    </td>
                </>
            }
            return esgTheme;
        default:
            return '';
    }
}
