import React from "react";
import {ICON_SIZE, LEGENDS} from "./CanopiaUtils";
import THSort from "./THSort";
import LabelWithTooltip from "./LabelWithTooltip";
import FlatSelect from "./FlatSelect";

export default function ESGMetricsHeaders(props) {

    if (!props.themes || !props.themes.length) {
        return "";
    }

    const theme = props.theme;
    const subTheme = props.subTheme;
    const themes = props.themes;
    const changeSubThemeFunc = props.changeSubThemeFunc;
    const bgClass = props.bgClass;
    const borderLeftClassName = props.borderLeftClassName;
    const sort = props.sort;
    const target = props.target;
    const viewModeKey = props.viewModeKey;
    const type = props.type; // the BM side
    const showBm = props.showBm;
    // let histoDepth = props.histoDepth;
    // let colorClass = type === 'bm' ? 'canopia3' : 'canopia2';
    let colorClass = 'canopia2';

    if (viewModeKey === 'hi' && changeSubThemeFunc && theme.subThemesHistoView === 'singleField') {
        return <th className={colorClass + " background-light histo-single-field " + borderLeftClassName}>
            <FlatSelect enums={theme.subThemes} curValue={subTheme} curClass={'canopia-nav-link-active'} otherClass={'canopia-nav-link'}
                        clickFunction={changeSubThemeFunc} clickParamFromEnum={true}/>
        </th>;
    }

    let text, width;

    switch (theme.key) {

        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // ESG REVIEW
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        case themes[0].key:
            text = LEGENDS["esgReport.ratingRank"];

            let textTrend = LEGENDS["esgReport.trend"];

            let textCoverage = LEGENDS["esgReport.coverage"];

            // if (changeSubThemeFunc && theme.subThemesHistoView === 'allFields') {
            //     return <>
            //         <th className={colorClass + " " + bgClass + " " + borderLeftClassName}>
            //             {sort ? <THSort colName="esgReport.ratingRank" label="Final Grade"/> : <LabelWithTooltip label={'Final Grade'} text={text}/>}
            //         </th>
            //         <th className={colorClass + " " + bgClass}>
            //             {sort ? <THSort colName="esgReport.trend" label="Trend"/> : <LabelWithTooltip label={'Trend'} text={textTrend}/>}
            //         </th>
            //         <th className={colorClass + " " + bgClass}>
            //             {sort ? <THSort colName="esgReport.coverage" label="Coverage"/> : <LabelWithTooltip label={'Coverage'} text={textCoverage}/>}
            //         </th>
            //         <th className={colorClass + " background-light " + className} colSpan={(histoDepth - 1) * theme.cols}/>
            //     </>
            // } else {
            return <>
                {/*<th className={colorClass + " " + bgClass + " " + borderLeftClassName + " " + (viewModeKey === 'hi' ? 'histo-esg-review-90' : 'pf-esg-review-90')}>*/}
                <th className={colorClass + " " + bgClass + " " + borderLeftClassName + " " + (viewModeKey === 'hi' ? 'histo-esg-review-90' : '')}>
                {/*<th className={colorClass + " " + bgClass + " " + borderLeftClassName}>*/}
                    {sort ? <THSort target={target} colName="esgReport.ratingRank" label="Final Grade" showLegend={true}/> :
                        <LabelWithTooltip label={'Final Grade'} text={text}/>}
                </th>
                {/*<th className={colorClass + " " + bgClass + " " + (viewModeKey === 'hi' ? 'histo-esg-review-60' : 'pf-esg-review-60')}>*/}
                <th className={colorClass + " " + bgClass + " " + (viewModeKey === 'hi' ? 'histo-esg-review-60' : '')}>
                {/*<th className={colorClass + " " + bgClass}>*/}
                    {sort ? <THSort target={target} colName="esgReport.trend" label="Trend" showLegend={true}/> : <LabelWithTooltip label={'Trend'} text={textTrend}/>}
                </th>
                {/*<th className={colorClass + " " + bgClass + " " + (viewModeKey === 'hi' ? 'histo-esg-review-70' : 'pf-esg-review-70')}>*/}
                <th className={colorClass + " " + bgClass + " " + (viewModeKey === 'hi' ? 'histo-esg-review-70' : '')}>
                {/*<th className={colorClass + " " + bgClass}>*/}
                    {sort ? <THSort target={target} colName="esgReport.coverage" label="Coverage" showLegend={true}/> :
                        <LabelWithTooltip label={'Coverage'} text={textCoverage}/>}
                </th>
            </>
        // }

        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // CONTROVERSIES
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        case themes[1].key:
            if (type === 'bm') {
                return <th className={colorClass + " background-light " + borderLeftClassName}/>;
            }
            // let normWidth = 3 * (ICON_SIZE + 4) + (2 * 10) + 2; // #icons * size + 2 * padding + border
            // let sectorWidth = 4 * (ICON_SIZE + 4) + (2 * 10) + 2; // #icons * size + 2 * padding + border

            if (viewModeKey === 'bm') {
                return <th className={colorClass + " background-light " + borderLeftClassName}>
                    <FlatSelect enums={theme.subThemes} curValue={subTheme} curClass={'canopia-nav-link-active'} otherClass={'canopia-nav-link'}
                                clickFunction={changeSubThemeFunc} clickParamFromEnum={true}/>
                </th>;
            }

            // if (changeSubThemeFunc && theme.subThemesHistoView === 'singleField') {
            //     return <th className={colorClass + " background-light " + borderLeftClassName} colSpan={histoDepth}>
            //         <FlatSelect enums={themes[1].subThemes} curValue={subTheme} curClass={'canopia-nav-link-active'} otherClass={'canopia-nav-link'}
            //                     clickFunction={changeSubThemeFunc} clickParamFromEnum={true}/>
            //     </th>
            // } else {
                return <>
                    {/*<th style={{minWidth: sectorWidth + 'px'}} className={colorClass + " " + bgClass + " " + borderLeftClassName}>*/}
                    <th className={colorClass + " " + bgClass + " " + borderLeftClassName}>
                        {sort ? <THSort target={target} colName="esgReport.sectorsSum.weight" label={themes[1].subThemes[0].label} showLegend={true}/> :
                            <LabelWithTooltip label={themes[1].subThemes[0].label} text={LEGENDS["esgReport.sectorsSum.weight"]}/>}
                    </th>
                    {/*<th style={{minWidth: normWidth + 'px'}} className={colorClass + " " + bgClass}>*/}
                    <th className={colorClass + " " + bgClass}>
                        {sort ? <THSort target={target} colName="esgReport.normsSum.weight" label={themes[1].subThemes[1].label} showLegend={true}/> :
                            <LabelWithTooltip label={themes[1].subThemes[1].label} text={LEGENDS["esgReport.normsSum.weight"]}/>}
                    </th>
                </>
            // }

        // return <>
        //     <th style={{minWidth: normWidth + 'px'}} className={colorClass + " " + bgClass + " " + borderLeftClassName}>
        //         {sort ? <THSort colName="esgReport.normsSum.weight" label="Breach to Intl Norms"/> : <LabelWithTooltip label={'Breach to Intl Norms'} text={text}/>}
        //     </th>
        //     <th style={{minWidth: sectorWidth + 'px'}} className={colorClass + " " + bgClass}>
        //         {sort ? <THSort colName="esgReport.sectorsSum.weight" label="Sensitive Sectors"/> : <LabelWithTooltip label={'Sensitive Sectors'} text={text}/>}
        //     </th>
        // </>

        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // IMPACTS
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        case themes[2].key:
            if (type === 'bm') {
                return <></>;
            }
            const textPosImpacts = LEGENDS["esgReport.allPosImpactsSum.weight"];
            const textNegImpacts = LEGENDS["esgReport.negImpactsSum.weight"];
            // const textThBds = LEGENDS["esgReport.thematicBondsSum.weight"];

            // if (changeSubThemeFunc && theme.subThemesHistoView === 'singleField') {
            //     return <th className={colorClass + " background-light " + borderLeftClassName} colSpan={histoDepth}>
            //         <FlatSelect enums={themes[2].subThemes} curValue={subTheme} curClass={'canopia-nav-link-active'} otherClass={'canopia-nav-link'}
            //                     clickFunction={changeSubThemeFunc} clickParamFromEnum={true}/>
            //     </th>
            // } else {
                width = showBm ? '230px' : '130px';
                return <>
                    <th width={width} className={colorClass + " background-light " + borderLeftClassName}>
                        {sort ? <THSort target={target} colName="esgReport.allPosImpactsSum.weight" label={themes[2].subThemes[0].label} showLegend={true}/> :
                            <LabelWithTooltip label={themes[2].subThemes[0].label} text={textPosImpacts}/>}
                    </th>
                    <th width={width} className={colorClass + " background-light"}>
                        {sort ? <THSort target={target} colName="esgReport.negImpactsSum.weight" label={themes[2].subThemes[1].label} showLegend={true}/> :
                            <LabelWithTooltip label={themes[2].subThemes[1].label} text={textNegImpacts}/>}
                    </th>
                    {/*<th className={colorClass + " background-light"}>*/}
                    {/*    {sort ? <THSort target={target} colName="esgReport.thematicBondsSum.weight" label={themes[2].subThemes[2].label} showLegend={true}/> :*/}
                    {/*        <LabelWithTooltip label={themes[2].subThemes[2].label} text={textThBds}/>}*/}
                    {/*</th>*/}
                </>
            // }

        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // CO2
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        case themes[3].key:
            if (type === 'bm') {
                return <></>;
            }

            // Histo tab
            // if (changeSubThemeFunc && theme.subThemesHistoView === 'singleField') {
            //     return <th className={colorClass + " background-light " + borderLeftClassName} colSpan={histoDepth}>
            //         <FlatSelect enums={themes[3].subThemes} curValue={subTheme} curClass={'canopia-nav-link-active'} otherClass={'canopia-nav-link'}
            //                     clickFunction={changeSubThemeFunc} clickParamFromEnum={true}/>
            //     </th>
            // } else {
                width = showBm ? '230px' : '180px';
                // Dashboard tab - Benchmark view
                // Funds tab
                if (showBm) {
                    return <React.Fragment key={'funds_esg_header_' + theme.key}>
                        {themes[3].subThemes.map(subTheme => {
                            return <th width={width} className={colorClass + " background-light " + borderLeftClassName}
                                       key={'funds_esg_header_' + subTheme.key}>
                                {sort ? <THSort target={target} colName={"esgReport." + subTheme.valueFields[0]} label={subTheme.label} showLegend={true}/> :
                                    <LabelWithTooltip label={subTheme.label} text={LEGENDS["esgReport." + subTheme.valueFields[0]]}/>}
                            </th>
                        })}
                    </React.Fragment>;
                } else {
                    // Dashboard tab - Portfolio view
                    return <th width={width} className={colorClass + " background-light " + borderLeftClassName}>
                        <LabelWithTooltip label={themes[3].subThemes[0].label} text={LEGENDS["esgReport." + themes[3].subThemes[0].valueFields[0]]}/>
                    </th>;
                    // This code generates the "Each child should have a unique key warning" but no clue why
                    // return <React.Fragment key={'db_esg_header_' + theme.key}>
                    //     {themes[3].subThemes.map(subTheme => {
                    //         if (subTheme.mainViewShow) {
                    //             return <th className={colorClass + " background-light " + borderLeftClassName} key={'db_esg_header_' + subTheme.key}>
                    //                 <LabelWithTooltip label={subTheme.label} text={LEGENDS["esgReport." + subTheme.valueFields[0]]}/>
                    //             </th>
                    //         }
                    //         return <></>
                    //     })}
                    // </React.Fragment>;
                }
            // }
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // INITIATIVES
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        case themes[4].key:
            if (type === 'bm') {
                return <></>;
            }
            // if (changeSubThemeFunc && theme.subThemesHistoView === 'singleField') {
            //     return <th className={colorClass + " background-light " + borderLeftClassName} colSpan={histoDepth}>
            //         <FlatSelect enums={themes[4].subThemes} curValue={subTheme} curClass={'canopia-nav-link-active'} otherClass={'canopia-nav-link'}
            //                     clickFunction={changeSubThemeFunc} clickParamFromEnum={true}/>
            //     </th>
            // } else {
                width = showBm ? '230px' : '130px';
                return <>
                    <th width={width} className={colorClass + " background-light " + borderLeftClassName}>
                        {sort ? <THSort target={target} colName="esgReport.netZeroPosSum.weight" label={themes[4].subThemes[0].label} showLegend={true}/> :
                            <LabelWithTooltip label={themes[4].subThemes[0].label} text={LEGENDS["esgReport.netZeroPosSum.weight"]}/>}
                    </th>
                    <th width={width} className={colorClass + " background-light " + borderLeftClassName}>
                        {sort ? <THSort target={target} colName="esgReport.netZeroNegSum.weight" label={themes[4].subThemes[1].label} showLegend={true}/> :
                            <LabelWithTooltip label={themes[4].subThemes[1].label} text={LEGENDS["esgReport.netZeroNegSum.weight"]}/>}
                    </th>
                </>
            // }
        default:
            return '';
    }
}
