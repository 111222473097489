import {useEffect} from "react";
import {useNavigate} from 'react-router-dom';

export default function Home(props) {

    const navigate = useNavigate();

    const user = props.user;

    useEffect(() => {
        if (user) {
            navigate("/portfolio");
        } else {
            navigate("/login");
        }
    }, [user, navigate])

    return '';
}
