import React, {useState} from "react";
import {Card} from "react-bootstrap";
import AuthService from "../../services/auth.service";
import {Eye, EyeSlash} from "react-bootstrap-icons";
import {useNavigate} from "react-router-dom";

const TIMEOUT = 5000;

export default function UserPwdChange(props) {

    const origin = props.origin;
    const isReset = origin === 'reset';

    // When resetting the pwd, the user is not available (not logged in)
    const username = isReset ? props.username : props.user.username;
    const pwdResetToken = isReset ? props.token : 'NA';

    const [passwordNew, setPasswordNew] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');
    const [showPwd, setShowPwd] = useState(false);

    const [successful, setSuccessful] = useState(false);
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const handlePwdChange = (e) => {
        e.preventDefault();

        const match = checkPwdMatch(passwordNew, passwordRepeat);

        if (match) {

            setLoading(true);

            AuthService.changePwd(
                username, passwordNew, isReset, pwdResetToken
            ).then(response => {

                    let resMessage = response.data.message;

                    if (isReset) {
                        resMessage += " You are going to be redirected to the login page soon."
                        setTimeout(() => {
                            navigate("/login");
                        }, TIMEOUT);
                    } else {
                        setTimeout(() => {
                            setMessages([]);
                            setSuccessful(false);
                            setPasswordNew('');
                            setPasswordRepeat('');
                        }, TIMEOUT);
                    }

                    setMessages([resMessage]);
                    setSuccessful(true);
                },
                error => {
                    processError(error);
                    setSuccessful(false);
                }
            );

            setLoading(false);
        }
    }

    const processError = (error) => {
        let messages = [];

        const errorData = error.response && error.response.data;
        if (errorData.errors) {
            errorData.errors.forEach(valError => {
                messages.push(valError.defaultMessage);
            });

        } else if (errorData.message) {
            messages.push(errorData.message);
        } else {
            messages.push(error.toString());
        }
        setMessages(messages);
    }

    const onChangePasswordNew = (e) => {
        e.preventDefault();
        const pwdNew = e.target.value;
        setPasswordNew(pwdNew);

        checkPwdMatch(pwdNew, passwordRepeat);
    }

    const onChangePasswordRepeat = (e) => {
        e.preventDefault();
        const pwdRepeat = e.target.value;
        setPasswordRepeat(pwdRepeat);

        checkPwdMatch(passwordNew, pwdRepeat);
    }

    const checkPwdMatch = (pwd1, pwd2) => {
        let messagesTmp = [];
        let success = true;

        // Only check if both are not empty
        if (pwd1 && pwd1.length > 0 && pwd2 && pwd2.length > 0 && pwd1 !== pwd2) {
            messagesTmp.push("The passwords do not match");
            success = false;
        }

        setMessages(messagesTmp);
        setSuccessful(success);

        return success;
    }

    const changeShowPwd = () => {
        setShowPwd(!showPwd);
    }

    return (
        <div className="main-content">
            <Card style={{width: '580px', margin: 'auto'}}>
                {/*<Card.Img variant="top" src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"/>*/}
                <Card.Body>
                    <Card.Title className='canopia'>Password Change</Card.Title>

                    <br/>
                    <form onSubmit={handlePwdChange}>
                        <div style={{display: "grid", gridTemplateColumns: '220px 1fr'}}>
                            <div className="form-group">
                                <div>
                                    <input
                                        type="hidden"
                                        className="form-control"
                                        autoComplete="username"
                                        name="username"
                                        value={username}
                                    />
                                    <label htmlFor="password" className={"nowrap"}>New Password</label>
                                    <input
                                        type={showPwd ? "text" : "password"}
                                        className="form-control"
                                        autoComplete="new-password"
                                        name="passwordNew"
                                        defaultValue={passwordNew}
                                        onChange={onChangePasswordNew}
                                    />
                                    <span style={{
                                        position: 'absolute',
                                        top: '82px',
                                        left: '205px',
                                        cursor: 'pointer'
                                    }} onClick={changeShowPwd}>
                                        {showPwd ? <Eye className={'canopia'} size={'1.4em'}/> : <EyeSlash className={'canopia'} size={'1.4em'}/>}
                                    </span>
                                </div>
                                <br/>
                                <div className="form-group">
                                    <label htmlFor="password" className={"nowrap"}>Confirm Password</label>
                                    <input
                                        type={showPwd ? "text" : "password"}
                                        className="form-control"
                                        autoComplete="new-password"
                                        name="passwordRepeat"
                                        defaultValue={passwordRepeat}
                                        onChange={onChangePasswordRepeat}
                                    />
                                </div>
                            </div>
                            <div style={{marginLeft: '2vw', position: 'relative'}}>
                                <span className={'nowrap'}>The password must fulfill</span> the following rules:
                                <ul>
                                    <li>
                                        Be at least 6 characters long.
                                    </li>
                                    <li>
                                        Contain at least 1 lowercase character.
                                    </li>
                                    <li>
                                        Contain at least 1 uppercase character.
                                    </li>
                                    <li>
                                        Contain at least 1 number.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <br/>

                        <div className="form-group">
                            <button className="btn btn-primary btn-block" disabled={loading}>
                                {loading && (
                                    <span className="spinner-border spinner-border-sm" style={{marginRight: '5px'}}/>
                                )}
                                <span>Save</span>
                            </button>
                        </div>

                        {messages && messages.length > 0 && (
                            <>
                                <br/>
                                <div className="form-group">
                                    <div className={
                                        successful
                                            ? "alert alert-success"
                                            : "alert alert-danger"
                                    } role="alert">
                                        {messages.map(mess => {
                                            return <p key={mess}>{mess}</p>;
                                        })}
                                    </div>
                                </div>
                            </>
                        )}
                    </form>
                </Card.Body>
            </Card>
        </div>
    );
}
