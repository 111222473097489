import React, {useEffect, useState} from 'react';
import Navigation from "../core/Navigation";
import AuthService from "../../services/auth.service";
import ajaxloader from "../../images/ajax-loader.gif";
import Footer from "../core/Footer";

const PublicRoute = ({component: Component}) => {

    const [user, setUser] = useState(null);
    const [reqStatus, setReqStatus] = useState('idle');

    useEffect(() => {
        setReqStatus('pending');
        const currentUser = AuthService.getCurrentUser();
        if (currentUser) {
            setUser(currentUser);
        }
        setReqStatus('success');
    }, [])

    if (reqStatus !== 'success') {
        return <div style={{textAlign: 'center', paddingTop: '10rem'}}><img alt="loading" src={ajaxloader}/></div>
    } else {
        return (
            <>
                <Navigation user={user}/>

                <div className="frm-body">
                    <div className="frm-body2">
                        <div className='logo-canopia'/>
                        <Component user={user}/>
                    </div>
                </div>

                <Footer/>
            </>
        )
    }
}

export default PublicRoute