import React, {useRef} from "react";
import {OverlayTrigger, ProgressBar, Tooltip} from "react-bootstrap";
import {getAllPosImpacts, getIconPath, ICON_SIZE} from "./CanopiaUtils";
import Table from "react-bootstrap/Table";
import {Plus} from "react-bootstrap-icons";

export default function Impact(props) {

    const target = useRef(null);

    const category = props.name;
    const pfRow = props.pfRow;
    const bmRow = props.bmRow;
    const showBm = props.showBm;
    let themes, bmThemes;
    let sum, bmSum;
    let className = props.className;
    const widthClass = props.widthClass;
    let name = '';
    let impactKeys = [];
    // const pfBgClass = 'background-light';
    const bmBgClass = 'background-light';
    const borderLeftClassName = 'border-large-left';
    const bmVariant = 'co2-bm';

    switch (className) {
        case 'climate-pos' :
            themes = getAllPosImpacts(pfRow);
            sum = pfRow.allPosImpactsSum;
            bmThemes = bmRow ? getAllPosImpacts(bmRow) : null;
            bmSum = bmRow ? bmRow.allPosImpactsSum : null;
            name = 'Positive Impacts';
            impactKeys = ['CT', 'WT', 'GB', 'SB', 'HIF']; //, 'IF', 'MF', 'RE'];
            break;
        case 'climate-neg' :
            themes = pfRow.negImpacts;
            sum = pfRow.negImpactsSum;
            bmThemes = bmRow ? bmRow.negImpacts : null;
            bmSum = bmRow ? bmRow.negImpactsSum : null;
            name = 'Climate Negative';
            impactKeys = ['FF', 'FFT', 'FC', 'FCT'];
            break;
        // case 'thematic-bonds-pos' :
        //     themes = pfRow.thematicBonds;
        //     sum = pfRow.thematicBondsSum;
        //     bmThemes = bmRow ? bmRow.thematicBonds : null;
        //     bmSum = bmRow ? bmRow.thematicBondsSum : null;
        //     name = 'Thematic Bonds';
        //     impactKeys = ['GB', 'SB'];
        //     break;
        default:
        // nothing
    }

    if ((!themes || !themes.length)) {
        if (!showBm || (showBm && (!bmThemes || !bmThemes.length))) {
            return "";
        }
    }

    if (sum.status !== 'NONE') {
        let weight = sum ? sum.weight : 0;
        // if (weight < 0.01) {
        //     weight = 0;
        // }
        let bmWeight = bmSum ? bmSum.weight : 0;
        // if (bmWeight < 0.01) {
        //     bmWeight = 0;
        // }
        const axisWidth = '1px';
        const gaugeHeight = 8;
        const axisHeight = (showBm ? 2 * gaugeHeight + 2 * 5 : gaugeHeight + 2 * 5) + 'px';
        let axisColor = "#1D416D";
        const maxBarWidth = 95;

        // climate neg only
        let pfTransitionSum = 0;
        let bmTransitionSum = 0;

        let impacts = <>
            {impactKeys.map(key => {
                let item;
                if (themes && themes.length) {
                    themes.forEach(pfTheme => {
                        if (pfTheme.theme.name === key) {
                            item = pfTheme;
                        }
                    });
                }
                let bmItem;
                if (showBm && bmThemes && bmThemes.length) {
                    bmThemes.forEach(bmTheme => {
                        if (bmTheme.theme.name === key) {
                            bmItem = bmTheme;
                        }
                    });
                }

                let themeDesc;
                let pfIcon = '';
                let pfWeight = '';
                if (item && item.status !== 'NONE') {
                    themeDesc = item.description;
                    let iconPath = getIconPath(item.theme.name);
                    pfIcon = item.weight >= 0.01 && <img ref={target} alt={item.theme.name} src={iconPath} width={ICON_SIZE} height={ICON_SIZE}/>;
                    pfWeight = item.formattedWeight;
                    if (key === 'FFT' || key === 'FCT') {
                        pfTransitionSum += item.weight;
                    }
                }
                let bmIcon = '';
                let bmWeight = '';
                if (showBm && bmItem && bmItem.status !== 'NONE') {
                    themeDesc = bmItem.description;
                    let iconPath = getIconPath(bmItem.theme.name);
                    bmIcon = bmItem.weight >= 0.01 && <img ref={target} alt={bmItem.theme.name} src={iconPath} width={ICON_SIZE} height={ICON_SIZE}/>;
                    bmWeight = bmItem.formattedWeight;
                    if (key === 'FFT' || key === 'FCT') {
                        bmTransitionSum += bmItem.weight;
                    }
                }
                if (themeDesc) {
                    return <tr key={key}>
                        <td>{themeDesc}</td>
                        <td>{pfIcon}</td>
                        <td align={"right"}>{pfWeight}</td>
                        {showBm && <>
                            <td align={"right"} className={bmBgClass + ' ' + borderLeftClassName}>
                                {bmIcon}
                            </td>
                            <td align={"right"} className={bmBgClass}>
                                {bmWeight}
                            </td>
                        </>}
                    </tr>;
                } else {
                    return <React.Fragment key={key}/>
                }
            })}
        </>;

        const globalMax = 0.2;
        let pfBar;
        let bmBar;
        if (className === 'climate-neg') {
            // pfBar = <ProgressBar bsPrefix={"progress-canopia"}>
            //     <ProgressBar bsPrefix={"progress-canopia-left"} variant={className+'-transition'} now={pfTransitionSum} max={globalMax} key={1}/>
            //     <ProgressBar bsPrefix={"progress-canopia"} variant={className} now={weight-pfTransitionSum} max={globalMax} key={3}/>
            // </ProgressBar>;
            // bmBar = <ProgressBar bsPrefix={"progress-canopia"}>
            //     <ProgressBar bsPrefix={"progress-canopia-left"} variant={bmVariant+'-transition'} now={bmTransitionSum} max={globalMax} key={1}/>
            //     <ProgressBar bsPrefix={"progress-canopia"} variant={bmVariant} now={bmWeight-bmTransitionSum} max={globalMax} key={3}/>
            // </ProgressBar>;
            if (pfTransitionSum > 0.005) {
                pfBar = <ProgressBar bsPrefix={"progress-canopia"}>
                    <ProgressBar bsPrefix={"progress-canopia-left"} variant={className} now={weight - pfTransitionSum} max={globalMax} key={1}/>
                    <ProgressBar bsPrefix={"progress-canopia"} variant={className + '-transition'} now={pfTransitionSum} max={globalMax} key={3}/>
                </ProgressBar>;
            } else {
                pfBar = <ProgressBar bsPrefix={"progress-canopia"} variant={className} now={weight} max={globalMax}/>;
            }
            if (bmTransitionSum > 0.005) {
                bmBar = <ProgressBar bsPrefix={"progress-canopia"}>
                    <ProgressBar bsPrefix={"progress-canopia-left"} variant={bmVariant} now={bmWeight - bmTransitionSum} max={globalMax} key={1}/>
                    <ProgressBar bsPrefix={"progress-canopia"} variant={bmVariant + '-transition'} now={bmTransitionSum} max={globalMax} key={3}/>
                </ProgressBar>;
            } else {
                bmBar = showBm && <ProgressBar bsPrefix={"progress-canopia"} variant={bmVariant} now={bmWeight} max={globalMax}/>;
            }
        } else {
            // pfBar = <Gauge value={weight} max={globalMax} variant={className}/>;
            pfBar = <ProgressBar bsPrefix={"progress-canopia"} variant={className} now={weight} max={globalMax}/>;
            bmBar = showBm && <ProgressBar bsPrefix={"progress-canopia"} variant={bmVariant} now={bmWeight} max={globalMax}/>;
        }

        pfBar = <div style={{display: "grid", gridTemplateColumns: "5fr 10px", height: '8px'}}>
            <div style={{
                width: maxBarWidth + '%',
                float: 'left'
            }}>{pfBar}</div>
            <div className={'climate-icon-plus'}>{weight > globalMax && <Plus/>}</div>
        </div>;

        bmBar = showBm && <div style={{display: "grid", gridTemplateColumns: "5fr 10px", height: '8px'}}>
            <div style={{
                width: maxBarWidth + '%',
                float: 'left'
            }}>{bmBar}</div>
            <div className={'climate-icon-plus'}>{bmWeight > globalMax && <Plus/>}</div>
        </div>;

        return <div className={widthClass}>
            <div style={{float: 'left', width: axisWidth, height: axisHeight, backgroundColor: axisColor, zIndex: -1}}/>
            <OverlayTrigger
                placement='auto'
                delay={{show: 500, hide: 0}}
                trigger={["hover", "focus"]}
                target={target}
                overlay={
                    <Tooltip>
                        <h3 className={'canopia2'}>{category}</h3>
                        <p className={'canopia'}><b>{name}</b></p>
                        <Table size="sm">
                            <thead>
                            <tr>
                                <th className={"canopia2"}>Theme</th>
                                <th className={"canopia2"} colSpan={2}>
                                    <span style={{float: "right"}}>Weight</span>
                                </th>
                                {showBm && <th className={"canopia2 " + bmBgClass + ' ' + borderLeftClassName} colSpan={2}>
                                    <span style={{float: "right"}}>Benchmark</span>
                                </th>}
                            </tr>
                            </thead>
                            <tbody>
                            {impacts}
                            {className === 'climate-neg' && <tr>
                                <td><b>Total</b></td>
                                <td align={"right"} colSpan={2}><b>{sum ? sum.formattedWeight : ''}</b></td>
                                {showBm && <>
                                    <td className={bmBgClass + ' ' + borderLeftClassName}/>
                                    <td align={"right"} className={bmBgClass}><b>{bmSum ? bmSum.formattedWeight : ''}</b></td>
                                </>}
                            </tr>}
                            </tbody>
                        </Table>
                    </Tooltip>
                }
                defaultShow={false}>
                {/*need to set display to prevent conflict between tr mouseover and tooltip*/}
                <span style={{width: '100%', zIndex: -2}} ref={target}>
                    <div style={{width: '100%', height: '26px', paddingTop: 5, zIndex: -2}}>
                        {/*<ProgressBar variant={className} ref={target} now={weight} max={0.2}/>*/}
                        {pfBar}
                        {bmBar}
                    </div>
                </span>

                {/*<span style={{display: 'inline-block', width: 120}}>*/}
                {/*    <div style={{float:'left', width: 60}}><ProgressBar variant={className} ref={target} now={sum.weight} max={0.2}/></div>*/}
                {/*    <div style={{float:'right'}}>{sum.formattedWeight}</div>*/}
                {/*</span>*/}
            </OverlayTrigger>
        </div>;
    } else {
        return '';
    }
}
