import {getIconPath, ICON_SIZE} from "./CanopiaUtils";
import React from "react";

export default function ThemePosition(props) {

    const descriptions = props.descriptions;
    const subThemeValueFunc = props.subThemeValueFunc;

    return <div style={{marginLeft: "10px", paddingTop: "0.45em"}}>
        {descriptions.map(themeDesc => {
            return <span key={themeDesc.lbl} onClick={() => subThemeValueFunc(themeDesc.desc)}
                         style={{
                             marginLeft: "5px", cursor: "pointer"
                         }}>
                    {themeDesc.hasOwnProperty('class') ?
                        <div className={themeDesc.class + ' nowrap'}>
                            {themeDesc.desc}
                        </div> : // <abbr title={themeDesc.desc}>
                        <img key={themeDesc.name} alt={themeDesc.desc}
                             src={getIconPath(themeDesc.name, 'bad')}
                             width={ICON_SIZE} height={ICON_SIZE}
                             style={{marginBottom: 3}}/>
                        // </abbr>
                    }
                </span>
        })}
    </div>
}