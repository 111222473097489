import React from "react";
import {formatPct, getValue} from "../CanopiaUtils";
import LabelWithTooltip from "../LabelWithTooltip";

export default function ConsensusColumnChart(props) {

    const esgReport = props.esgReport;
    const width = props.width;

    if (!esgReport) {
        return <></>;
    }

    const wgtAboveS = getValue(esgReport, "wgtAboveS");
    const wgtAboveSFmt = formatPct(wgtAboveS);
    const wgtAboveM = getValue(esgReport, "wgtAboveM");
    const wgtAboveMFmt = formatPct(wgtAboveM);
    const wgtAboveW = getValue(esgReport, "wgtAboveW");
    const wgtAboveWFmt = formatPct(wgtAboveW);
    const wgtBelowW = getValue(esgReport, "wgtBelowW");
    const wgtBelowWFmt = formatPct(wgtBelowW);
    const wgtBelowM = getValue(esgReport, "wgtBelowM");
    const wgtBelowMFmt = formatPct(wgtBelowM);
    const wgtBelowS = getValue(esgReport, "wgtBelowS");
    const wgtBelowSFmt = formatPct(wgtBelowS);

    const displayLimit = 0.04;
    return <div style={{display: "flex", flexDirection: "row", width: width, height: "28px", margin: "8px 10px"}}>
            {wgtAboveS && <div className={'consensus-column-chart'} style={{flex: wgtAboveS + " 1 0%", backgroundColor: "#5DCA8B"}}>
                {wgtAboveS >= displayLimit ? wgtAboveSFmt : <LabelWithTooltip className={'hidden'} label={'H'} text={wgtAboveSFmt}/>}
            </div>}
            {wgtAboveM && <div className={'consensus-column-chart'} style={{flex: wgtAboveM + " 1 0%", backgroundColor: "#9EDFB9"}}>
                {wgtAboveM >= displayLimit ? wgtAboveMFmt : <LabelWithTooltip className={'hidden'} label={'H'} text={wgtAboveMFmt}/>}
            </div>}
            {wgtAboveW && <div className={'consensus-column-chart'} style={{flex: wgtAboveW + " 1 0%", backgroundColor: "#CEEFDC"}}>
                {wgtAboveW >= displayLimit ? wgtAboveWFmt : <LabelWithTooltip className={'hidden'} label={'H'} text={wgtAboveWFmt}/>}
            </div>}
            {wgtBelowW && <div className={'consensus-column-chart'} style={{flex: wgtBelowW + " 1 0%", backgroundColor: "#F8BFC2"}}>
                {wgtBelowW >= displayLimit ? wgtBelowWFmt : <LabelWithTooltip className={'hidden'} label={'H'} text={wgtBelowWFmt}/>}
            </div>}
            {wgtBelowM && <div className={'consensus-column-chart'} style={{flex: wgtBelowM + " 1 0%", backgroundColor: "#F17F86"}}>
                {wgtBelowM >= displayLimit ? wgtBelowMFmt : <LabelWithTooltip className={'hidden'} label={'H'} text={wgtBelowMFmt}/>}
            </div>}
            {wgtBelowS && <div className={'consensus-column-chart'} style={{flex: wgtBelowS + " 1 0%", backgroundColor: "#E72A35"}}>
                {wgtBelowS >= displayLimit ? wgtBelowSFmt : <LabelWithTooltip className={'hidden'} label={'H'} text={wgtBelowSFmt}/>}
            </div>}
        </div>;
}
