import React, {useState} from "react";
import {Card} from "react-bootstrap";
import AuthService from "../../services/auth.service";
import {REQUIRED} from "../canopia/CanopiaUtils";

export default function UserRequestPwdReset(props) {

    const [username, setUsername] = useState('');

    const [successful, setSuccessful] = useState(false);
    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(false);

    const handlePwdReset = (e) => {
        e.preventDefault();

        setLoading(true);
        setMessage(null);

        let valid = true;
        [username].forEach(prop => {
            if (!prop || prop.trim().length === 0) {
                valid = false;
                setMessage('Please fill in all the required fields');
            }
        });

        if (valid) {
            AuthService.requestResetPwd(
                username
            ).then(
                response => {
                    setMessage(response.data.message);
                    setSuccessful(true);
                    setLoading(false);
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    setMessage(resMessage);
                    setSuccessful(false);
                    setLoading(false);
                }
            );
        } else {
            setLoading(false);
        }
    }

    return (
        <div className="main-content">
            <Card style={{width: '500px', margin: 'auto'}}>
                <Card.Body>
                    <Card.Title className='canopia'>Password Reset</Card.Title>

                    <form onSubmit={handlePwdReset}>

                        <div className="form-group">
                            <label htmlFor="username" className={"nowrap"}>E-mail</label>
                            <input
                                type="text"
                                className="form-control"
                                autoComplete="username"
                                name="username"
                                value={username}
                                placeholder={REQUIRED}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </div>
                        <br/>

                        {!successful && <div className="form-group">
                            <button className="btn btn-primary btn-block" disabled={loading}>
                                {loading && (
                                    <span className="spinner-border spinner-border-sm" style={{marginRight: '5px'}}/>
                                )}
                                <span>Send</span>
                            </button>
                        </div>}

                        {message && (
                            <><br/>
                                <div className="form-group">
                                    <div className={successful ? "alert alert-success" : "alert alert-danger"}
                                         role="alert">
                                        {message}
                                    </div>
                                </div>
                            </>
                        )}
                    </form>

                </Card.Body>
            </Card>
        </div>
    );
}
