import React from "react";
import SimpleToolTip from "../SimpleToolTip";
import Table from "react-bootstrap/Table";
import {CheckCircleFill, ExclamationCircleFill} from "react-bootstrap-icons";

export default function ExclusionTarget(props) {

    const pfName = props.pfName;
    const pfRow = props.pfRow;

    const pfConsensusRatingRank = pfRow.consensusRatingRank;

    const pfBgClass = 'background-light';
    const borderLeftClassName = 'border-large-left';

    // TODO get from param
    const targets = {
        'T': {value: 0.01, valueFmt: '1%', label: 'Tobacco'},
        'ESG_CB': {value: 0.00005, valueFmt: '0.005%', label: 'Cluster bombs'}
    }

    let exclusions = pfRow.sectors.concat(pfRow.norms);
    let warning = false;
    exclusions.forEach(exc => {
        if (targets.hasOwnProperty(exc.label)) {
            targets[exc.label]['item'] = exc; // keep the exclusion associated to this target
            if (exc.weight > targets[exc.label].value) {
                warning = true;
            }
        }
    });
    let indicator = pfConsensusRatingRank > 0 && (warning ?
        <ExclamationCircleFill size={'1.5em'} className={'suitability-warning'}/> :
        <CheckCircleFill size={'1.5em'} className={'suitability-ok'}/>);

    let rows = [];
    Object.entries(targets).forEach(([key, conf]) => {
        let item;
        let desc = conf.label, wgt = '-';
        let icon = <CheckCircleFill size={'1.5em'} className={'suitability-ok'}/>;
        if (conf.hasOwnProperty('item')) {
            item = conf['item'];
            wgt = item.formattedWeight;
            if (item.weight > conf.value) {
                icon = <ExclamationCircleFill size={'1.5em'} className={'suitability-warning'}/>;
            }
        }
        rows.push(<tr key={key}>
            <td className={pfBgClass}>
                {desc}
            </td>
            <td className={pfBgClass} align={"right"}>
                {icon}
            </td>
            <td className={pfBgClass} align={"right"}>
                {wgt}
            </td>
            <td className={pfBgClass + " " + borderLeftClassName} align={"right"}>
                {conf.valueFmt}
            </td>
        </tr>);
    });

    let tooltipContent = <>
        <h3 className={'canopia2'}>{pfName}</h3>
        <p className={'canopia'}><b>Exclusions</b></p>
        <Table size="sm">
            <thead>
            <tr>
                <th className={"canopia2"}>Theme</th>
                <th className={"canopia2"} colSpan={2}>
                    <span style={{float: "right"}}>Weight</span>
                </th>
                <th className={"canopia2 " + borderLeftClassName} colSpan={2}>
                    <span style={{float: "right"}}>Limit</span>
                </th>
            </tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
        </Table>
    </>;

    return <SimpleToolTip content1={indicator} content2={tooltipContent}/>;
}
