import React, {useEffect, useState} from "react";
import {Card} from "react-bootstrap";
import AuthService from "../../services/auth.service";
import {useSearchParams} from "react-router-dom";
import ajaxloader from "../../images/ajax-loader.gif";
import UserPwdChange from "./UserPwdChange";

export default function UserPwdReset(props) {

    const [successful, setSuccessful] = useState(false);
    const [message, setMessage] = useState('');
    const [validateToken, setValidateToken] = useState(true);

    const [searchParams, setSearchParams] = useSearchParams();
    const token = searchParams.get("token");
    const username = searchParams.get("username");

    useEffect(() => {
        if (validateToken) {
            AuthService.checkPwdResetToken(token, username)
                .then(response => {
                        // if (response.data.accessToken) {
                        if (response.data === 'valid') {
                            setMessage(null);
                            setSuccessful(true);
                            // navigate("/change-pwd");
                        } else {
                            let responseMessage = response.data.message;
                            setSuccessful(false);
                            setMessage(responseMessage);
                        }
                        setValidateToken(false);
                    },
                    error => {
                        const resMessage =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();

                        setMessage(resMessage);
                        setSuccessful(false);
                        setValidateToken(false);
                    }
                );
        }
    }, [validateToken, token, username]);

    return (validateToken ? <div className="main-content">
                <Card style={{width: '500px', margin: 'auto'}}>
                    <Card.Body>
                        <Card.Title className='canopia'>Validating your request, please wait</Card.Title>
                        <div>
                            <img alt="loading" src={ajaxloader}/>
                        </div>
                    </Card.Body>
                </Card>
            </div>
            :
            (successful ? <UserPwdChange origin={'reset'} username={username} token={token}/>
                :
                <div className="main-content">
                    <Card style={{width: '500px', margin: 'auto'}}>
                        <Card.Body>
                            <Card.Title className='canopia'>Error</Card.Title>
                            <div className="form-group">
                                <div className={"alert alert-danger"} role="alert">
                                    {message}
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </div>)
    );
}
