import {createSlice} from '@reduxjs/toolkit';
import {filter, getNextSort, parseNull, sort} from "../containers/canopia/CanopiaUtils";

const directLinesInitialSortStatus = {
    "name": "asc", // initial sort is made on the server side
    "isin": "desc",
    "type": "desc",
    "issuer": "desc",
    "leafCategory": "desc",
    "weight": "asc", // Same sort than for the amount
    "consensusRank": "asc",
    "sectors": "asc",
    "norms": "asc",
    "impacts": "asc",
    "thematicBonds": "asc",
    "netZero.rank": "asc"
}

const initialState = {

    date: null, // the date at which the root pf is displayed
    // Direct lines
    tgtDataOrig: null, // all the direct lines (initialized once, immutable then)
    tgtData: [], // The displayed ones, filtered, sorted, ...
    sortStatus: directLinesInitialSortStatus,
    sortCol: "name",
    filterValuesOrig: {}, // all the values of the filters corresponding to tgtDataOrig (initialized once, immutable then)
    filterValues: {}, // all the values of the filters
    filterSelectedValues: {}, // the current selected values of the filters
}

const filterCols = { //
    'type': 0, // 0 => the filter is inactive, 1 => 1st active filter, ...
    'leafCategory': 0, //
    'consensusLabel': 0 //
};

function resetFilters(state) {
    state.filterValues = state.filterValuesOrig;
    state.filterSelectedValues = state.filterValuesOrig;
    Object.keys(filterCols).forEach(filterCol => {
        filterCols[filterCol] = 0;
    });
}

export const directLinesSlice = createSlice({
    name: 'directLines',
    initialState,
    reducers: {
        setDirectLines: (state, action) => {
            state.date = action.payload.date;
            state.tgtDataOrig = action.payload.pfData.directLinesReports[state.date];
            state.tgtData = state.tgtDataOrig;

            if (state.tgtDataOrig) {
                const tmpValues = {};
                Object.keys(filterCols).forEach(filterCol => {
                    tmpValues[filterCol] = [];
                });
                state.tgtDataOrig.forEach(dLine => {
                    Object.keys(filterCols).forEach(filterCol => {
                        const value = parseNull(dLine[filterCol]);
                        if (!tmpValues[filterCol].includes(value)) {
                            tmpValues[filterCol].push(value);
                        }
                    });
                });
                Object.keys(tmpValues).forEach(filterCol => {
                    tmpValues[filterCol].sort();
                });
                state.filterValuesOrig = tmpValues;

                resetFilters(state);
            }

            // filter(state);
        },
        sortDirectLines: (state, action) => {
            // load the portfolio of the selected client
            let col = action.payload.col;

            sort(state, col, directLinesInitialSortStatus);
        },
        filterDirectLines: (state, action) => {
            // Update the filters with the new selection
            const colName = action.payload.colName;
            const selectedValues = action.payload.selectedValues;

            filter(state, colName, filterCols, selectedValues, directLinesInitialSortStatus);
        },
        clearAllDirectLinesFilters: (state, action) => {
            state.tgtData = state.tgtDataOrig;

            resetFilters(state);

            let prevSort = state.sortStatus[state.sortCol];
            state.sortStatus[state.sortCol] = getNextSort(prevSort);
            sort(state, state.sortCol, directLinesInitialSortStatus);
        }
    },
    extraReducers: {}
});

export const {
    setDirectLines,
    filterDirectLines,
    sortDirectLines,
    clearAllDirectLinesFilters,
} = directLinesSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectDirectLinesState = state => state.directLines;

export default directLinesSlice.reducer;