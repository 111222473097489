import React from "react";
import {useDispatch, useSelector} from 'react-redux';
import {ArrowDownCircle, ArrowDownUp, ArrowUpCircle} from "react-bootstrap-icons";
import {selectDirectLinesState, sortDirectLines} from "../../reducer/directLinesSlice";
import LabelWithTooltip from "./LabelWithTooltip";
import {LEGENDS} from "./CanopiaUtils";
import {selectFundsState, sortFunds} from "../../reducer/fundsSlice";
import {selectRealEstateState, sortRealEstatePositions} from "../../reducer/realEstateSlice";
import {selectSettingsState, sortSettings} from "../../reducer/settingsSlice";
import {selectReviewState, sortReviewStatus} from "../../reducer/reviewSlice";
import {selectSetupState, sortGC} from "../../reducer/setupSlice";

export default function THSort(props) {

    const target = props.target;
    const label = props.label;
    let selectFunc, sortFunc;
    switch (target) {
        case "funds":
            selectFunc = selectFundsState;
            sortFunc = sortFunds;
            break;
        case "directLines":
            selectFunc = selectDirectLinesState;
            sortFunc = sortDirectLines;
            break;
        case "realEstatePositions":
            selectFunc = selectRealEstateState;
            sortFunc = sortRealEstatePositions;
            break;
        case "userSettings":
            selectFunc = selectSettingsState;
            sortFunc = sortSettings;
            break;
        case "reviewStatus":
            selectFunc = selectReviewState;
            sortFunc = sortReviewStatus;
            break;
        case "gc":
            selectFunc = selectSetupState;
            sortFunc = sortGC;
            break;
        default:
            // required to call the hook useSelector
            selectFunc = selectFundsState;
    }

    const {sortCol, sortStatus} = useSelector(selectFunc);

    const dispatch = useDispatch();

    if (!sortFunc) {
        return label;
    }

    const colName = props.colName;
    const showLegend = props.showLegend;

    let sortIcon;

    if (sortCol === colName) {
        if (sortStatus[sortCol] === 'asc') {
            sortIcon = <ArrowUpCircle className={"react-icons canopia"} size={'1.4em'} style={{paddingBottom: "2px"}}/>;
        } else {
            sortIcon = <ArrowDownCircle className={"react-icons canopia"} size={'1.4em'} style={{paddingBottom: "2px"}}/>;
        }
    } else {
        sortIcon = <ArrowDownUp className={"react-icons canopia2"} size={'1.1em'} style={{paddingBottom: "2px"}}/>;
    }

    return (
        <span style={{display: "inline"}} className={'nowrap'}>
            {showLegend ? <LabelWithTooltip label={label} text={LEGENDS[colName]}/> : label}
            <span style={{display: "inline", marginLeft: "5px"}} className={'label-hover nowrap'} onClick={() => dispatch(sortFunc({col: colName}))}>
                {sortIcon}
            </span>
        </span>
    );
}
