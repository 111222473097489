export const authHeader = () => {
    const user = JSON.parse(localStorage.getItem('user'));

    if (user && user.accessToken) {
        return {Authorization: 'Bearer ' + user.accessToken}; // for Spring Boot back-end
        // return {'x-access-token': user.accessToken};       // for Node.js Express back-end
    } else {
        return {};
    }
}

export const jsonAuthHeader = () => {

    let headers = authHeader();
    headers['Content-Type'] = 'application/json';
    return headers;
}

export const oauth2Header = (token) => {
    if (token) {
        return {Authorization: 'Bearer ' + token}; // for Spring Boot back-end
    } else {
        return {};
    }
}
