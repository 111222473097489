import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {authHeader} from "../services/auth-header";
import {CONTEXT_URL} from "../containers/canopia/CanopiaUtils";

const API_URL = CONTEXT_URL + "api/main/";

const initialState = {
    positions: [],
    status: "idle",
    error: null,
}

export const loadPositions = createAsyncThunk(
    'position/load',
    async (params) =>
        fetch(API_URL + "position?" + new URLSearchParams(params).toString(), {
            headers: authHeader(),
            cache: 'no-store'
        })
            .then(response => {
                let contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json();
                } else {
                    console.log("The content type is not JSON, found " + contentType);
                    return response;
                }
            })
);

export const positionSlice = createSlice({
    name: 'position',
    initialState,
    reducers: {},
    extraReducers: {
        [loadPositions.pending]: (state, action) => {
            state.status = 'loading';
        },
        [loadPositions.fulfilled]: (state, action) => {
            let payload = action.payload;

            if (payload.status) {
                state.status = 'error';
                state.error = payload;
            } else if (payload.message === 'Failed to fetch') {
                state.status = 'error';
                state.error = {
                    error: 'Service unavailable', //
                    message: 'We apologize for the inconvenience, our team is working on solving the issue. ' + //
                        'Please try to come back in a few minutes. Thank you for your patience.'
                };
            } else {
                state.status = 'success';
                state.positions = payload["data"];
            }
        },
        [loadPositions.rejected]: (state, action) => {
            state.status = 'error';
            state.error = action.payload;
        }
    }
});

// export const {} = positionSlice.actions;

export const selectPositionState = state => state.position;

export default positionSlice.reducer;