import React, {Fragment, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {loadPositions, selectPositionState} from "../../reducer/positionSlice";
import {Placeholder} from "react-bootstrap";
import {selectClientState} from "../../reducer/clientSlice";
import {selectPortfolioState} from "../../reducer/portfolioSlice";
import {
    getAllAmountControls,
    getAmountValue,
    getCO2Value,
    PF_KEY_SEPARATOR,
    THEMES,
    TOTAL
} from "./CanopiaUtils";
import {HiChevronDown, HiChevronRight} from "react-icons/hi";
import FlatSelect from "./FlatSelect";
import ThemePosition from "./ThemePosition";

export default function TopPosition(props) {

    const pfRow = props.pfRow;
    // const bmRow = props.bmRow;
    const subTheme = props.subTheme;
    const subThemeValue = props.subThemeValue;
    const subThemeValueFunc = props.subThemeValueFunc;
    const changePfKey = props.changePfKey;
    // const pfName = props.pfName;
    // const bmName = props.bmName;
    const date = props.date;

    const dispatch = useDispatch();
    const {clientConfig} = useSelector(selectClientState);
    const {view} = useSelector(selectPortfolioState);

    const {positions, status} = useSelector(selectPositionState);

    const [showSubPos, setShowSubPos] = useState({});

    const amountFields = getAllAmountControls(clientConfig.showAmounts, clientConfig.clientWrapper.currency);
    const [selectedAmountField, setSelectedAmountField] = useState(amountFields[amountFields.length - 1]);

    useEffect(() => {
        let params = {
            clientId: clientConfig.clientWrapper.id,
            dateStr: date,
            viewId: view.viewId,
            pfKey: pfRow.key,
            subTheme: subTheme.key
        };
        if (subThemeValue) {
            params['subThemeValue'] = subThemeValue === TOTAL ? '' : subThemeValue;
        }
        dispatch(loadPositions(params));

    }, [dispatch, date, view, pfRow.key, subTheme, clientConfig, subThemeValue]);

    if (status !== "success") {
        return <>
            <div style={{display: "flex", width: "100%", marginTop: "20px"}}/>
            <Placeholder animation="glow">
                <Placeholder xs={7} bg="placeholder-top-pos" size="lg"/>
            </Placeholder>
            <Placeholder animation="glow">
                <Placeholder xs={7} bg="placeholder-top-pos" size="lg"/>
                <Placeholder xs={7} bg="placeholder-top-pos" size="lg"/>
                <Placeholder xs={7} bg="placeholder-top-pos" size="lg"/>
                <Placeholder xs={7} bg="placeholder-top-pos" size="lg"/>
                <Placeholder xs={7} bg="placeholder-top-pos" size="lg"/>
            </Placeholder>
        </>
    }

    function showHideSubPos(posName) {
        let showSubPosTmp = {...showSubPos};
        if (showSubPos.hasOwnProperty(posName)) {
            showSubPosTmp[posName] = !showSubPos[posName];
        } else {
            showSubPosTmp[posName] = true;
        }
        setShowSubPos(showSubPosTmp);
    }

    function changeAmountField(field) {
        setSelectedAmountField(field);
    }

    const amountSelect = <FlatSelect enums={amountFields} curValue={selectedAmountField}
                                     curClass={'canopia-nav-link-active'} otherClass={'canopia-nav-link'}
                                     clickFunction={changeAmountField} clickParamFromEnum={true}
                                     divStyle={{float: 'right'}}/>

    function getPfSelector(categories) {
        // Create the pf selector
        let pfNames = categories.split('|');
        pfNames.unshift('Portfolio');
        let pfKeys = [];
        let prevKey = null;
        for (let i = 0; i < pfNames.length; i++) {
            const newPfKey = (prevKey ? prevKey : '') + pfNames[i];
            pfKeys.push(newPfKey);
            prevKey = newPfKey + PF_KEY_SEPARATOR;
        }
        let pfKeySelector = [];
        let catFound = false;
        for (let i = 0; i < pfNames.length; i++) {
            const pfName = pfNames[i];
            let isCurrentPf = pfName === pfRow.category;
            if (isCurrentPf) {
                catFound = true;
                continue;
            }
            if (!catFound) {
                continue;
            }
            const pfKey = pfKeys[i];
            pfKeySelector.push(
                <React.Fragment key={pfKey}>
                    <span onClick={() => changePfKey(pfKey)} className={'canopia-nav-link'}>
                        {pfName}
                    </span>
                    {i < pfNames.length - 1 && <HiChevronRight size={'1.2em'} style={{margin: '0 10px 2px 10px'}}/>}
                </React.Fragment>
            );
        }
        return <p style={{marginBottom: '0'}}>
            {pfKeySelector}
        </p>;
    }

    const issuerWidth = '200px';
    const otherWidth = '100px';

    switch (subTheme.key) {
        case THEMES[3].subThemes[0].key:
        case THEMES[3].subThemes[1].key:
            return <>
                <div style={{display: "flex", flexWrap: "nowrap", margin: "20px 0 5px 5px"}}>
                    <div style={{width: issuerWidth}}><b>Issuer</b></div>
                    <div style={{width: otherWidth}}><b>{amountSelect}</b></div>
                    <div style={{width: otherWidth, marginLeft: '10px'}}><b>{subTheme.label}</b></div>
                    <div></div>
                </div>
                {positions.map(pos => {
                    const co2Pos = pos.descriptions[0];
                    let amountValue = getAmountValue(pos.themePosition, selectedAmountField);
                    let co2Value = getCO2Value(co2Pos, selectedAmountField);
                    return <>
                        <div style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            margin: "5px 0 5px 5px",
                            borderTop: "1px solid #bbc6d3"
                        }} key={pos.name}>
                            <span style={{width: issuerWidth, paddingTop: "0.75em"}} className={'label-hover'}
                                  onClick={() => showHideSubPos(pos.themePosition.name)}>
                                {showSubPos[pos.themePosition.name] ?
                                    <HiChevronDown color={'#1D416D'} size={'1.4em'}/>
                                    :
                                    <HiChevronRight color={'#1D416D'} size={'1.4em'}/>
                                }
                                {pos.themePosition.name} ({pos.subPos.length})
                            </span>
                            <div style={{width: otherWidth, paddingTop: "0.75em"}}>
                                <div style={{float: "right"}}>{amountValue}</div>
                            </div>
                            <div style={{width: otherWidth, paddingTop: "0.75em"}}>
                                <div style={{float: "right"}}>{co2Value}</div>
                            </div>
                        </div>
                        {
                            showSubPos[pos.themePosition.name] && pos.subPos.map(sub => {
                                const subTp = sub.themePosition;
                                let amountValue = getAmountValue(subTp, selectedAmountField);
                                const co2Pos = sub.descriptions[0];
                                let co2Value = getCO2Value(co2Pos, selectedAmountField);
                                const pfSelector = getPfSelector(subTp.categories);
                                return <div style={{
                                    display: "flex",
                                    flexWrap: "nowrap",
                                    margin: "5px 0 5px 5px",
                                    borderTop: "1px solid #bbc6d3"
                                }} key={subTp.name}>
                                    <div style={{
                                        width: issuerWidth,
                                        paddingLeft: "40px",
                                        paddingTop: "0.75em"
                                    }}>{subTp.name}</div>
                                    <div style={{width: otherWidth, paddingTop: "0.75em"}}>
                                        <div style={{float: "right"}}>{amountValue}</div>
                                    </div>
                                    <div style={{width: otherWidth, paddingTop: "0.75em"}}>
                                        <div style={{float: "right"}}>{co2Value}</div>
                                    </div>
                                    <div style={{
                                        paddingTop: "0.75em",
                                        marginLeft: '10px',
                                        width: "60%"
                                    }}>{pfSelector}</div>
                                </div>
                            })
                        }
                    </>;
                })}
            </>;
        default:
            return <>
                <div style={{display: "flex", flexWrap: "nowrap", margin: "20px 0 5px 5px"}}>
                    <div style={{width: issuerWidth}}><b>Issuer</b></div>
                    <div style={{width: otherWidth}}><b>{amountSelect}</b></div>
                    <div></div>
                </div>
                {positions.map(pos => {
                    const descriptions = pos.descriptions;
                    let amountValue = getAmountValue(pos.themePosition, selectedAmountField);
                    return <>
                        <div style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            margin: "5px 0 5px 5px",
                            borderTop: "1px solid #bbc6d3"
                        }} key={pos.name}>
                            <span style={{width: issuerWidth, paddingTop: "0.75em"}} className={'label-hover'}
                                  onClick={() => showHideSubPos(pos.themePosition.name)}>
                                {showSubPos[pos.themePosition.name] ?
                                    <HiChevronDown color={'#1D416D'} size={'1.4em'}/>
                                    :
                                    <HiChevronRight color={'#1D416D'} size={'1.4em'}/>
                                }
                                {pos.themePosition.name} ({pos.subPos.length})
                            </span>
                            <div style={{width: otherWidth, paddingTop: "0.75em"}}>
                                <div style={{float: "right"}}>{amountValue}</div>
                            </div>
                            <ThemePosition descriptions={descriptions} subThemeValueFunc={subThemeValueFunc}/>
                        </div>
                        {
                            showSubPos[pos.themePosition.name] && pos.subPos.map(sub => {
                                const subTp = sub.themePosition;
                                let amountValue = getAmountValue(subTp, selectedAmountField);
                                const pfSelector = getPfSelector(subTp.categories);
                                return <div style={{
                                    display: "flex",
                                    flexWrap: "nowrap",
                                    margin: "5px 0 5px 5px",
                                    borderTop: "1px solid #bbc6d3"
                                }} key={subTp.name}>
                                    <div style={{
                                        width: issuerWidth,
                                        paddingLeft: "40px",
                                        paddingTop: "0.75em"
                                    }}>{subTp.name}</div>
                                    <div style={{width: otherWidth, paddingTop: "0.75em"}}>
                                        <div style={{float: "right"}}>{amountValue}</div>
                                    </div>
                                    {descriptions.length > 1 &&
                                        <ThemePosition descriptions={sub.descriptions} subThemeValueFunc={subThemeValueFunc}/>
                                    }
                                    <div style={{padding: "0.75em 0 0 10px", width: "60%"}}>{pfSelector}</div>
                                </div>
                            })
                        }
                    </>;
                })}
            </>;
    }
}
