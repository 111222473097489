import React from "react";

/**
 * Renders a gauge using Bootstrap's ProgressBar
 */
export default function NameTrunc(props) {
    let name = props.name;
    let size = props.size;

    let nameTrunc = name;
    if (name && name.length > size + 2) {
        nameTrunc = <abbr title={name}>{name.substring(0, size) + "..."}</abbr>;
    }
    return <span className={"nowrap"}>{nameTrunc}</span>;
}
