import React from "react";
import methodology from "../../docs/Conser_methodology_2023-12-11.pdf";

export default function Docs(props) {

    return (
        <div className="main-content">
            <span className={'client-title'}>Conser methodology</span>
            <p style={{paddingTop: '20px'}}>
                {/*<a href={methodology}>Methodology</a>*/}
                <iframe src={methodology} height="880rem" width="100%" frameBorder={0} title={'Conser methodology'}/>
            </p>
        </div>
    );
}
