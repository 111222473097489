import client from "./clientSlice";
import user from "./userSlice";
import portfolio from './portfolioSlice';
import directLines from './directLinesSlice';
import funds from './fundsSlice';
import {combineReducers} from "redux";
import realEstate from "./realEstateSlice";
import review from "./reviewSlice";
import settings from "./settingsSlice";
import position from "./positionSlice";
import setup from "./setupSlice";
import benchmark from "./benchmarkSlice";
import date from "./dateSlice";

const appReducer = combineReducers({
    user,
    client,
    portfolio,
    directLines,
    realEstate,
    funds,
    position,
    review,
    settings,
    setup,
    benchmark,
    date
})

/*
* Trick to reset our reducers when a specific action type is sent
* https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
*/
export const rootReducer = (state, action) => {
    if (action.type === 'RESET') {
        return appReducer(undefined, action)
    }

    return appReducer(state, action)
}