import React, {useRef} from "react";
import {OverlayTrigger, ProgressBar, Tooltip} from "react-bootstrap";
import {useSelector} from "react-redux";
import {selectPortfolioState} from "../../reducer/portfolioSlice";
import Table from "react-bootstrap/Table";
import CO2Icon from "./CO2Icon";
import {Plus} from "react-bootstrap-icons";

export default function CO2(props) {

    const {pfData} = useSelector(selectPortfolioState);

    const target = useRef(null);

    const category = props.name;
    const pfRow = props.pfRow;
    const bmRow = props.bmRow;
    const subTheme = props.subTheme;
    const widthClass = props.widthClass;
    const borderLeftClassName = props.borderLeftClassName;

    const pfValue = pfRow[subTheme.valueFields[0]];
    const bmValue = bmRow ? bmRow[subTheme.valueFields[0]] : null;
    const pfCoverage = pfRow[subTheme.valueFields[2]];
    const pfCoverageFmt = pfRow[subTheme.valueFields[3]];
    const bmCoverage = bmRow ? bmRow[subTheme.valueFields[2]] : null;
    const bmCoverageFmt = bmRow ? bmRow[subTheme.valueFields[3]] : null;

    // let name = '';
    // const pfBgClass = 'background-light';
    const bmBgClass = 'background-light';
    const tdClasses = 'form-padding ' + borderLeftClassName;

    if (!pfValue && !bmValue) {
        return <>
            <td className={tdClasses}/>
        </>
    }

    // if (pfRow.co2EmissionsCoverage < 0.5) {
    //     return <>
    //         <td className={tdClasses}/>
    //         <td/>
    //     </>;
    // }

    // Pf vs Bm
    let pfVariant = "co2";
    let bmVariant = "co2-bm";
    let axisColor = "#1D416D";
    let pfBmRatio = null;
    if (pfValue && bmValue && pfCoverage && pfCoverage > 0 && bmCoverage && bmCoverage > 0) {
        pfBmRatio = bmValue === 0 ? 0 : pfValue / bmValue - 1;
    }
    let relToBmIcon = <CO2Icon value={pfBmRatio}/>;
    let pfBmDiff = Math.round((pfBmRatio * 100 + Number.EPSILON) * 10) / 10;

    if (pfCoverage < 0.5) {
        pfVariant += "-transparent";
        bmVariant += "-transparent";
        axisColor = "rgba(0,0,0,0.3)";
    }

    // CO2 progress bars
    let globalMax = pfData.co2Report[subTheme.valueFields[4]];

    let pfBar = <ProgressBar bsPrefix={"progress-canopia"} variant={pfVariant} now={pfValue} max={globalMax}/>;
    // let pfBar = <Gauge value={pfValue} max={globalMax} variant={pfVariant}/>;
    let bmBar = <ProgressBar bsPrefix={"progress-canopia"} variant={bmVariant} now={bmValue} max={globalMax}/>;

    const maxBarWidth = 95;
    pfBar = <div style={{display: "flex", height: '8px'}}>
        <div style={{
            width: maxBarWidth + '%',
            float: 'left'
        }}>{pfBar}</div>
        <div className={'climate-icon-plus'}>{pfValue > globalMax && <Plus/>}</div>
    </div>;

    bmBar = <div style={{display: "flex", height: '8px'}}>
        <div style={{
            width: maxBarWidth + '%',
            float: 'left'
        }}>{bmBar}</div>
        <div className={'climate-icon-plus'}>{bmValue > globalMax && <Plus/>}</div>
    </div>;

    const axisWidth = '1px';
    // const tickWidth = '1px';
    // const axisStep = 100 * maxBarWidth / globalMax;

    // let maxCO2 = 0;
    // if (pfValue != null) {
    //     maxCO2 = pfValue;
    // }
    // if (bmValue != null) {
    //     maxCO2 = bmValue > maxCO2 ? bmValue : maxCO2;
    // }
    // let tick100 = '';
    // let tick200 = '';
    // let tick300 = '';
    // let tick400 = '';
    // if (maxCO2 > 100) {
    //     tick100 = <div style={{float: 'left', position: 'relative', width: tickWidth, height: '26px', backgroundColor: axisColor, left: axisStep + '%', zIndex: -1}}/>
    // }
    // if (maxCO2 > 200) {
    //     tick200 = <div style={{float: 'left', position: 'relative', width: tickWidth, height: '26px', backgroundColor: axisColor, left: 2 * axisStep + '%', zIndex: -1}}/>
    // }
    // if (maxCO2 > 300) {
    //     tick300 = <div style={{float: 'left', position: 'relative', width: tickWidth, height: '26px', backgroundColor: axisColor, left: 3 * axisStep + '%', zIndex: -1}}/>
    // }
    // if (maxCO2 > 400) {
    //     tick400 = <div style={{float: 'left', position: 'relative', width: tickWidth, height: '26px', backgroundColor: axisColor, left: 4 * axisStep + '%', zIndex: -1}}/>
    // }
    let co2 = <div className={widthClass}>
        <div style={{float: 'left', width: axisWidth, height: '26px', backgroundColor: axisColor, zIndex: -1}}/>
        {/*{tick100}*/}
        {/*{tick200}*/}
        {/*{tick300}*/}
        {/*{tick400}*/}
        <OverlayTrigger
            placement='auto'
            delay={{show: 500, hide: 0}}
            trigger={["hover", "focus"]}
            target={target}
            overlay={
                <Tooltip id={'co2Tooltip'}>
                    <h3 className={'canopia2'}>{category}</h3>
                    <p className={'canopia'}><b>{subTheme.label}</b></p>
                    <Table size="sm">
                        <thead>
                        <tr>
                            <th/>
                            <th className={"canopia2"}>Portfolio</th>
                            <th className={"canopia2"} colSpan={2}>Rel. Difference</th>
                            <th className={"canopia2 " + borderLeftClassName}>Benchmark</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{subTheme.units}</td>
                            <td align={"right"}>{pfRow[subTheme.valueFields[1]]}</td>
                            <td align={"right"}>{relToBmIcon}</td>
                            <td align={"right"}>{(pfBmDiff > 0 ? '+' : '') + pfBmDiff}%</td>
                            <td align={"right"} className={bmBgClass + ' ' + borderLeftClassName}>{bmRow ? bmRow[subTheme.valueFields[1]] : ''}</td>
                        </tr>
                        <tr>
                            <td>Coverage</td>
                            <td align={"right"}>{pfCoverageFmt}</td>
                            <td colSpan={2}/>
                            <td align={"right"} className={bmBgClass + ' ' + borderLeftClassName}>{bmCoverageFmt}</td>
                        </tr>
                        </tbody>
                    </Table>
                </Tooltip>
            }
            defaultShow={false}>
            <span style={{width: '100%', zIndex: -2}} ref={target}>
                <div style={{width: '100%', height: '26px', paddingTop: 5, zIndex: -2}}>
                    {pfBar}
                    {bmBar}
                </div>
            </span>

        </OverlayTrigger>
    </div>;

    return <>
        <td align={"left"} className={tdClasses}>
            <div>
                <div style={{float: 'left', width: '28px'}}>
                    {relToBmIcon}
                </div>
                {co2}
            </div>
        </td>
    </>
}
