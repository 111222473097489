import React from "react";
import {AMOUNT_CONTROLS, getAmountValueForFunds, getIconPath, ICON_SIZE} from './CanopiaUtils';
import {useSelector} from "react-redux";
import {selectPortfolioState} from "../../reducer/portfolioSlice";
import ConsensusIndicator from "./ConsensusIndicator";
import NameTrunc from "./NameTrunc";
import {selectClientState} from "../../reducer/clientSlice";

export default function DirectLineRow(props) {

    let pfRow = props.row;

    const {selectedAmountField} = useSelector(selectPortfolioState);
    const {clientConfig} = useSelector(selectClientState);

    const name = pfRow.name;
    const nameTrunc = <NameTrunc name={name} size={25}/>;

    const issuer = pfRow.issuer;
    const issuerTrunc = <NameTrunc name={issuer} size={25}/>;

    // Amount
    // Relative % is not relevant in Funds tab
    const adjAmountField = selectedAmountField.key === 'relPct' ? AMOUNT_CONTROLS[1] : selectedAmountField;
    let amountValue = getAmountValueForFunds(pfRow, adjAmountField);

    const borderLeftClassName = 'canopia-border-left';

    return <tr key={pfRow.isin}>
        <td colSpan={2}>{nameTrunc}</td>
        <td>{pfRow.isin}</td>
        <td>{pfRow.type}</td>
        <td>{issuerTrunc}</td>
        <td>{pfRow.leafCategory}</td>
        <td align="right">{amountValue}</td>
        <td className={borderLeftClassName}>
            <ConsensusIndicator label={pfRow.consensusLabel} color={pfRow.consensusColor}/>
        </td>
        <td className={borderLeftClassName}>
            {pfRow.sectors &&
                <span style={{display: 'inline-block'}}>
                    {pfRow.sectors.map(function (item) {
                        if (item.name !== 'NONE') {
                            let iconPath = getIconPath(item.name, 'bad');
                            return <img key={item.name} alt={item.name} src={iconPath} width={ICON_SIZE} height={ICON_SIZE} style={{marginRight: 4}}/>
                        } else {
                            return '';
                        }
                    })}
                </span>
            }
        </td>
        <td className={borderLeftClassName}>
            {pfRow.norms &&
                <span style={{display: 'inline-block'}}>
                    {pfRow.norms.map(function (item) {
                        if (item.name !== 'NONE') {
                            let iconPath = getIconPath(item.name, 'bad');
                            return <img key={item.name} alt={item.name} src={iconPath} width={ICON_SIZE} height={ICON_SIZE} style={{marginRight: 4}}/>
                        } else {
                            return '';
                        }
                    })}
                </span>
            }
        </td>
        <td className={borderLeftClassName}>
            {pfRow.impacts &&
                <span style={{display: 'inline-block'}}>
                    {pfRow.impacts.map(function (item) {
                        if (item.name !== 'NONE') {
                            let iconPath = getIconPath(item.name);
                            return <img key={item.name} alt={item.name} src={iconPath} width={ICON_SIZE} height={ICON_SIZE} style={{marginRight: 4}}/>
                        } else {
                            return '';
                        }
                    })}
                </span>
            }
        </td>
        <td className={borderLeftClassName}>
            {pfRow.thematicBonds &&
                <span style={{display: 'inline-block'}}>
                    {pfRow.thematicBonds.map(function (item) {
                        if (item.name !== 'NONE') {
                            let iconPath = getIconPath(item.name);
                            return <img key={item.name} alt={item.name} src={iconPath} width={ICON_SIZE} height={ICON_SIZE} style={{marginRight: 4}}/>
                        } else {
                            return '';
                        }
                    })}
                </span>
            }
        </td>
        {clientConfig.showNetZeroData && <td className={borderLeftClassName}>
            {pfRow.netZero &&
                <div className={pfRow.netZero.class + ' nowrap'}>
                    {pfRow.netZero.desc}
                </div>
            }
        </td>}
    </tr>
}
