import React, {useRef} from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

export default function SimpleToolTip(props) {

    const target = useRef(null);

    const content1 = props.content1;
    const content2 = props.content2;

    return <OverlayTrigger
        placement='auto'
        delay={{show: 500, hide: 0}}
        trigger={["hover", "focus"]}
        target={target}
        overlay={
            <Tooltip id="tooltip-bottom">
                {content2}
            </Tooltip>
        }
        defaultShow={false}>
        <span style={{display: 'inline-block'}}>
            <div ref={target}>
                {content1}
            </div>
        </span>
    </OverlayTrigger>;
}
