import React from "react";
import {ChevronDoubleDown, ChevronDoubleUp, ChevronDown, ChevronUp} from "react-bootstrap-icons";

export default function TrendIcon(props) {

    let value = props.value;
    let show = props.show;

    // Grade, trend & coverage
    let trendIcon = "";
    if (show) {

        // Trend
        if (Number.isInteger(value)) {
            let limit = 1;
            let size = "1.4em";
            if (value === 0) {
                trendIcon = <div style={{float: 'left', height: 2, marginRight: '1.7em'}}/>;
            }
            if (value > 0 && value <= limit) {
                trendIcon = <ChevronUp color={'#00b140'} size={size}/>;
            }
            if (value > limit) {
                trendIcon = <ChevronDoubleUp color={'#00b140'} size={size}/>;
            }
            if (value < 0 && value >= -limit) {
                trendIcon = <ChevronDown color={'#fe5000'} size={size}/>;
            }
            if (value < -limit) {
                trendIcon = <ChevronDoubleDown color={'#fe5000'} size={size}/>;
            }
        }
    }

    return <>{trendIcon}</>;
}
