import React from "react";

export default function ConsensusIndicator(props) {

    const label = props.label;
    const color = props.color;

    return <>
        {label && <>
            <span style={{display: 'flex'}} className={"nowrap float-right"}>
                {label}
                <span style={{backgroundColor: color, margin: '2px 5px 0 8px'}} className={"consensus-shape"}></span>
            </span>
        </>}
    </>
}