import React, {useState} from "react";
import {Card} from "react-bootstrap";
import AdminService from "../../services/admin.service";
import store from "../../store/store";
import {RESET_ACTION} from "../canopia/CanopiaUtils";

export default function Reload(props) {

    const [reloadConserChecked, setReloadConserChecked] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const [errorName, setErrorName] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const handleReload = (e) => {
        e.preventDefault();

        setLoading(true);

        AdminService.reload(reloadConserChecked)
            .then(
                response => {
                    setMessage(response.data.message);
                    setLoading(false);
                    setTimeout(() => setMessage(null), 3000);
                    store.dispatch(RESET_ACTION);
                },
                error => {
                    setLoading(false);
                    processError(error);
                }
            );
    }

    const processError = (error) => {
        let response = error.response;

        let errorName;
        let errorMessage;
        if (response &&
            response.data &&
            response.data.error &&
            response.data.message) {
            errorName = response.data.error;
            errorMessage = response.data.message;
        } else {
            errorName = 'Service unavailable';
            errorMessage = 'We apologize for the inconvenience, our team is working on solving the issue. Please try to come back in a few minutes.\nThank you for your patience.';
            if (error.message) {
                console.log(error.message);
            }
        }

        setErrorName(errorName);
        setErrorMessage(errorMessage);
    }

    const reloadConserData = () => {
        setReloadConserChecked(!reloadConserChecked);
    }

    return (
        <div className="main-content">
            <Card style={{width: '30rem', margin: 'auto'}}>
                <Card.Body>
                    <Card.Title className='canopia'>Reload</Card.Title>

                    <p>Click on the below button to reload the Canopia data of all clients. This operation usually takes
                        a few seconds.</p>

                    <form onSubmit={handleReload}>
                        <div className="form-group">
                            <p>
                                <span style={{marginRight: "5px"}}>
                                    <input type="checkbox" name={"conser-cb"}
                                           onChange={reloadConserData}
                                           checked={reloadConserChecked}/>
                                </span>
                                Reload Conser data, used to display the Direct Lines and Real Estate tabs. This
                                operation takes up to 1 minute.
                            </p>
                        </div>
                        <br/>

                        <div className="form-group">
                            <button className="btn btn-primary btn-block" disabled={loading}>
                                {loading && (
                                    <span className="spinner-border spinner-border-sm" style={{marginRight: '5px'}}/>
                                )}
                                <span>Reload</span>
                            </button>
                        </div>

                        {message && (
                            <div className="form-group">
                                <br/>
                                <div className={"alert alert-success"} role="alert">
                                    {message}
                                </div>
                            </div>
                        )}

                        {errorName && (
                            <div className="form-group">
                                <div className={"alert alert-danger"} role="alert">
                                    <h3 className='canopia'>{errorName}</h3>
                                    {errorMessage}
                                </div>
                            </div>
                        )}
                    </form>
                </Card.Body>
            </Card>
        </div>
    );
}
